<template>
  <div class="risk-alert">
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <BaseInfoBox
        v-if="feedbackMessage"
        :text="feedbackMessage"
        :theme="themes.ERROR"
        :icon-options="defaultIcons.HIGH"
      />
      <ProfileHeader
        v-if="hasTableRows"
        :heading="thirdPartyDetails.comp_name_eng || ''"
        :subheading="thirdPartyDetails.country || ''"
      />
      <div class="risk-alert__tab">
        <ThirdPartyRiskAlertsHeader
          v-if="hasTableRows"
          :thirdPartyRiskAlerts="thirdPartyRiskAlerts"
        />
        <DataTable
          v-if="hasTableRows"
          :ag-grid-options="tableData"
          grid-height="calc(100vh - 350px)"
          class="ag-theme-data-table"
          ref="data-table"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import ShapeWithInfo from "@/molecules/ShapeWithInfo/ShapeWithInfo";
import BaseText from "@/atoms/BaseText/BaseText";
import DataTable from "@/organisms/DataTable/DataTable";
import ThirdPartyRiskAlertsHeader from "@/organisms/ThirdPartyRiskAlertsHeader/ThirdPartyRiskAlertsHeader";

import {
  actionName,
  themes,
  defaultIcons,
  riskAlertSummaryTableHeaders,
  typographySize,
  shapes,
  border,
  riskAlertCategoryText
} from "@/constants";
import { mapState, mapActions } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "ThirdPartyRiskAlerts",
  components: {
    ProfileHeader,
    BaseLoader,
    BaseInfoBox,
    DataTable,
    ThirdPartyRiskAlertsHeader
  },
  computed: {
    ...mapState({
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails,
      isThirdPartyRiskAlertsLoading: (state) =>
        state.thirdParty.isThirdPartyRiskAlertsLoading,
      thirdPartyRiskAlerts: (state) => state.thirdParty.thirdPartyRiskAlerts
    }),
    hasTableRows() {
      return !!this.tableData.rowData?.length;
    },
    isLoading() {
      return (
        this.isLoadingThirdPartyDetails || this.isThirdPartyRiskAlertsLoading
      );
    }
  },
  data() {
    return {
      themes,
      defaultIcons,
      typographySize,
      feedbackMessage: "",
      tableData: {
        gridOptions: {
          enableBrowserTooltips: true,
          suppressRowClickSelection: true,
          suppressRowHoverHighlight: true,
          suppressColumnHoverHighlight: true,
          suppressCellFocus: true,
          defaultColDef: {
            resizable: false,
            sortable: false
          }
        },
        rowData: [],
        columnDefs: []
      }
    };
  },
  async created() {
    if (isEmpty(this.thirdPartyDetails)) {
      await this.fetchThirdPartyDetails(this.$route.params.id);
      await this.fetchThirdPartyRiskAlertsWrapper(this.$route.params.id);
      this.makeTableHeaders();
      this.makeTableRows(this.thirdPartyRiskAlerts?.alertCategories);
    }
  },
  methods: {
    ...mapActions({
      fetchThirdPartyDetails: actionName.THIRD_PARTY.FETCH_THIRD_PARTY_DETAILS,
      fetchThirdPartyRiskAlerts:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_RISK_ALERTS
    }),
    async fetchThirdPartyRiskAlertsWrapper(thirdPartyId) {
      try {
        this.feedbackMessage =
          (await this.fetchThirdPartyRiskAlerts(thirdPartyId)) || "";
      } catch ({ message = "" }) {
        this.feedbackMessage = message;
      }
    },
    makeHeaderColumn(key, organisationName) {
      return {
        headerName:
          riskAlertSummaryTableHeaders[key] ===
          riskAlertSummaryTableHeaders.ORGANISATION
            ? organisationName
            : riskAlertSummaryTableHeaders[key],
        field: key,
        cellRenderer: "BaseGridComponentWrapper",
        headerClass:
          riskAlertSummaryTableHeaders[key] !==
          riskAlertSummaryTableHeaders.ALERTS
            ? "ag-cell-center"
            : "",
        cellClass:
          riskAlertSummaryTableHeaders[key] !==
          riskAlertSummaryTableHeaders.ALERTS
            ? "ag-cell-center"
            : ""
      };
    },
    makeTableHeaders() {
      const organisationName =
        this.thirdPartyRiskAlerts?.alertCategories[0]?.organisation?.name;
      this.tableData.columnDefs = Object.keys(riskAlertSummaryTableHeaders).map(
        (key) => this.makeHeaderColumn(key, organisationName)
      );
    },
    makeTableRows(riskData) {
      this.tableData.rowData = riskData?.map((category) => ({
        ALERTS: this.makeAlertsRow(category),
        ORGANISATION: this.makeIconRow(
          category.organisation.alerts,
          category.organisation.remediationStatus
        ),
        ASSOCIATE: this.makeIconRow(
          category.associate.alerts,
          category.associate.remediationStatus
        ),
        ULTIMATE_BENEFICIAL_OWNERS: this.makeIconRow(
          category.ubo.alerts,
          category.ubo.remediationStatus
        )
      }));
    },
    makeAlertsRow(category = {}) {
      return {
        component: BaseText,
        componentOptions: {
          text: `${category.name} (${category.count})`,
          size:
            category.count > 0
              ? typographySize.BODY_TEXT_BOLD
              : typographySize.BODY_TEXT
        }
      };
    },
    makeIconRow(alerts, remediationStatus) {
      const alertOptions = this.getAlertOptions(alerts);

      if (!alertOptions?.length) {
        return {
          component: ShapeWithInfo,
          componentOptions: {
            iconOptions: {
              icon: "eb-icon-check"
            }
          }
        };
      }

      const icons = alertOptions.map((alert = {}) => ({
        component: ShapeWithInfo,
        componentOptions: {
          shape: shapes.CIRCLE,
          borderStyle: alert.borderStyle,
          theme: alert.theme,
          textOptions: {
            text: alert.text,
            theme: alert.theme
          }
        }
      }));

      if (!remediationStatus) {
        icons.push({
          component: ShapeWithInfo,
          componentOptions: {
            iconOptions: {
              icon: "eb-icon-spinner"
            }
          }
        });
      }

      return {
        listOptions: icons
      };
    },
    getAlertOptions(alerts = {}) {
      const options = [];
      for (const [key, value] of Object.entries(alerts)) {
        if (value > 0) {
          switch (key) {
            case riskAlertCategoryText.CRITICAL:
              options.push({
                text: `${value}`,
                borderStyle: border.SOLID,
                theme: themes.ERROR
              });
              break;

            case riskAlertCategoryText.SERIOUS:
              options.push({
                text: `${value}`,
                borderStyle: border.SOLID_OUTLINE,
                theme: themes.ERROR_INVERSE
              });
              break;

            case riskAlertCategoryText.MODERATE:
              options.push({
                text: `${value}`,
                borderStyle: border.DOTTED
              });
              break;

            default:
              return null;
          }
        }
      }

      return options;
    }
  }
};
</script>
<style lang="scss" scoped>
.risk-alert {
  &__tab {
    border-radius: $spacing16;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  :deep(.ag-theme-data-table) {
    --ag-border-color: transparent;
    --ag-row-border-color: #e9e9ea;
    --ag-row-border-style: solid;
    --ag-row-border-width: 1px;
    --ag-border-radius: 0;
    --ag-header-background-color: #e9e9ea;
    .ag-root-wrapper {
      border-bottom-left-radius: $spacing16;
      border-bottom-right-radius: $spacing16;
      border: 0;
    }
  }
}
</style>
