<template>
  <div class="add-tp main-wrapper-modal">
    <!--add/edit modal add/edit third party component-->
    <b-modal
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="
        editModalShow ? 'Configure Third Party' : 'Home / Configure Third Party'
      "
      v-model="isAddThirdPartyModalNewVisible"
      size="xl"
      modal-class="modal-open-styles"
      content-class="modal-content-styles"
      dialog-class="modal-dialog-styles"
      footer-class="modal-footer-styles"
      header-class="modal-header-styles"
      style="height: 100% !important"
      class="add-third-party-modal"
    >
      <b-overlay :show="isLoadingPage" rounded="sm">
        <!--Alert for sufficient credits -->
        <b-alert
          style="margin: 0px 0px 1px 0px"
          v-model="showCreditsAlert"
          variant="warning"
        >
          <b style="color: black">
            You do not have sufficient credits to order the selected Profile
            Tasks. please contact <a href="#">ethixbase support</a> for
            assistance
          </b>
        </b-alert>
        <!--Alert for sufficient credits -->
        <ErrorPageTemplate
          class="third-party-details__body--screen-height"
          v-if="isErrorPresent"
          v-bind="errorPageOptionsForModal"
        />
        <b-row v-else>
          <!-- 8 column left div start -->
          <b-col>
            <!-- card enity Details start-->
            <b-card
              no-body
              header-tag="header"
              style="border: 0px; margin-right: 16px"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div style="align-items: center">
                    <h6 class="mb-0">Entity Details</h6>
                  </div>
                  <div>
                    <div class="ml-auto">
                      <a
                        :href="knowledgeCentreLinks.ENTITY_DETAILS"
                        target="_blank"
                      >
                        <BaseIcon
                          :icon="icons.QUESTION"
                          :shape="shapes.CIRCLE"
                          :size="12"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <b-row v-if="resultsFetchDisable || editModalShow" class="mt-2">
                <b-col cols="3" v-if="entityTypeSelect == 'O'">Company</b-col>
                <b-col cols="3" v-else>Individual</b-col>
                <b-col
                  class="d-flex pl-0"
                  cols="6"
                  v-if="entityTypeSelect == 'O'"
                  ><b style="padding: 5px">{{ form.entityName }}</b>
                  <span style="padding: 5px">in </span>
                  <b style="padding: 5px" v-if="resultsFetchDisable">{{
                    form.country.name || form.country
                  }}</b>
                  <b style="padding: 5px" v-else-if="editModalShow">{{
                    form.country
                  }}</b>
                </b-col>
                <b-col class="d-flex pl-0" cols="6" v-else
                  ><b style="padding: 5px">{{ form.firstName }}</b>
                </b-col>
              </b-row>
              <b-row v-else class="mt-2">
                <b-col cols="3">
                  <b-form-group>
                    <b-form-group>
                      <b-form-select
                        :disabled="
                          resultsFetchDisable ||
                          editModalShow ||
                          showCreditsAlert
                        "
                        v-model="entityTypeSelect"
                        size="sm"
                        :options="optionsTypes"
                        @change="resetEntityDetails"
                        data-test-id="add-third-party-modal__entity-type-input"
                      ></b-form-select>
                    </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex pl-0" cols="4">
                  <b-form-group v-if="entityTypeSelect == 'O'" class="w-100">
                    <b-form-input
                      :disabled="
                        resultsFetchDisable || editModalShow || showCreditsAlert
                      "
                      v-model="v$.form.entityName.$model"
                      :state="
                        v$.form.entityName.$dirty
                          ? !v$.form.entityName.$error
                          : null
                      "
                      id="entityName-live-feedback"
                      placeholder="Entity Name"
                      size="sm"
                      @input="checkEntityName"
                      @change="callOpenCorporateTableApi"
                      @blur="showExistingTable = true"
                      @focus="changeEntityName"
                      data-test-id="add-third-party-modal__entity-name-input"
                    ></b-form-input>
                    <b-form-invalid-feedback id="entityName-live-feedback"
                      >A Required field has no value.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else class="w-100">
                    <b-form-input
                      :disabled="
                        resultsFetchDisable || editModalShow || showCreditsAlert
                      "
                      v-model="v$.form.firstName.$model"
                      :state="
                        v$.form.firstName.$dirty
                          ? !v$.form.firstName.$error
                          : null
                      "
                      placeholder="Name"
                      size="sm"
                      @change="callOpenCorporateTableApi"
                      @blur="showExistingTable = true"
                      @focus="changeEntityName"
                      data-test-id="add-third-party-modal__first-name-input"
                    ></b-form-input>
                    <b-form-invalid-feedback id="firstname-live-feedback"
                      >A Required field has no value.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="5" class="pl-0">
                  <b-form-group
                    v-if="entityTypeSelect == 'O'"
                    data-test-id="add-third-party-modal__registered-in-wrapper"
                  >
                    <v-select
                      :disabled="
                        resultsFetchDisable || editModalShow || showCreditsAlert
                      "
                      placeholder="-- Registered in --"
                      :maxHeight="220"
                      v-model="v$.form.country.$model"
                      :state="
                        v$.form.country.$dirty ? !v$.form.country.$error : null
                      "
                      :class="{
                        'form-control is-invalid p-0': v$.form.country.$error
                      }"
                      label="name"
                      track-by="name"
                      :options="countries"
                      @update:modelValue="
                        callOpenCorporateTableApi($event, 'country')
                      "
                    >
                      <template slot="option" slot-scope="option">
                        <country-flag
                          v-if="option.icon"
                          :country="option.icon"
                          size="small"
                          data-test-id="add-third-party-modal__registered-in-country--flag"
                        />
                        <span
                          data-test-id="add-third-party-modal__registered-in-country--name"
                          >{{ "&nbsp;" + option.name }}</span
                        >
                      </template>
                    </v-select>
                    <b-form-invalid-feedback id="countrySelect"
                      >There are no selected actions.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else class="w-100">
                    <b-form-select
                      :disabled="
                        resultsFetchDisable || editModalShow || showCreditsAlert
                      "
                      v-model="v$.form.searchAssociatedCompaniesValue.$model"
                      size="sm"
                      :state="
                        v$.form.searchAssociatedCompaniesValue.$dirty
                          ? !v$.form.searchAssociatedCompaniesValue.$error
                          : null
                      "
                      data-test-id="add-third-party-modal__search-associate-companies-input"
                    >
                      <b-form-select-option :value="null"
                        >Search Associated Companies</b-form-select-option
                      >
                      <b-form-select-option value="yes"
                        >Yes</b-form-select-option
                      >
                      <b-form-select-option value="no">No</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="firstname-live-feedback"
                      >There are no selected actions.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <b-form-input
                      type="url"
                      placeholder="URL"
                      size="sm"
                      v-model="form.entityThirdPartyUrl"
                      :state="
                        !form.entityThirdPartyUrl
                          ? null
                          : form.entityThirdPartyUrl && invalidUrlDomain
                          ? false
                          : true
                      "
                      @change="is_url"
                      data-test-id="add-third-party-modal__url-input"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="form.entityThirdPartyUrl && invalidUrlDomain"
                    >
                      {{ invalidUrlDomainValue }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <!-- card enity Details end-->

            <b-row
              :class="
                showCreditsAlert
                  ? 'cols-8-with-voucheralert-height-styles'
                  : 'cols-8-height-styles'
              "
            >
              <b-col>
                <!-- card Previously Saved Entites start-->
                <b-card
                  v-if="showExistingTable && !editModalShow"
                  class="mt-2"
                  no-body
                  header-tag="header"
                  :style="{
                    border: '0px',
                    display: existingItems.length ? '' : 'none'
                  }"
                >
                  <template #header>
                    <div class="d-flex">
                      <div style="display: flex; align-items: center">
                        <h6 class="mb-0 ml-2">Previously Saved Entites</h6>
                      </div>
                    </div>
                  </template>
                  <div>
                    <div>
                      <div>
                        <b-row class="mt-3">
                          <b-col cols="12" class="d-flex">
                            <p>
                              The below similar entities are saved to your
                              account, if you would like to use one of these
                              please select below.
                            </p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="table-col">
                            <div v-if="isLoadingPreviouslySavedEntites">
                              <b-skeleton-table
                                :rows="5"
                                :columns="3"
                                :table-props="{ bordered: true, striped: true }"
                              ></b-skeleton-table>
                            </div>
                            <div>
                              <div
                                :class="existingItems.length ? '' : 'd-none'"
                              >
                                <b-table
                                  :aria-disabled="resultsFetchDisable"
                                  thead-class="thead-blue-color"
                                  ref="table"
                                  sticky-header
                                  bordered
                                  id="existing-items-table"
                                  :busy.sync="isLoadingPreviouslySavedEntites"
                                  :fields="previouslySavedEntitesFields"
                                  :items="getPreviouslySavedEntities"
                                  :sort-by="sortBy"
                                  :sort-desc="sortDesc"
                                  responsive="sm"
                                  small
                                  outlined
                                  :per-page="perPageExistingItems"
                                  :current-page="currentPageExistingItems"
                                  :filter="filterExistingItems"
                                >
                                  <template v-slot:cell(select)="data">
                                    <b-form-radio
                                      v-model="selectedThirdParty"
                                      @change="
                                        showConfirmModal(
                                          parseInt(data.item.tpi_id)
                                        )
                                      "
                                      :value="data.index"
                                      class="text-center"
                                      name="some-radios"
                                    ></b-form-radio>
                                  </template>
                                  <template #cell(registered_country)="data">
                                    <div
                                      v-if="
                                        getCountryNames(
                                          data.item.jurisdiction_code
                                        ) == -1
                                      "
                                    >
                                      <p>
                                        <span>{{
                                          "&nbsp;" +
                                          getJurisdicationCountryName(
                                            data.item.jurisdiction_code
                                          ).name
                                        }}</span>
                                      </p>
                                    </div>
                                    <div v-else>
                                      <div>
                                        <p>
                                          <span
                                            >{{
                                              "&nbsp;" +
                                              OCStatesData[
                                                getCountryNames(
                                                  data.item.jurisdiction_code
                                                )
                                              ].state
                                            }}
                                            ({{
                                              getJurisdicationCountryName(
                                                data.item.jurisdiction_code.substring(
                                                  0,
                                                  2
                                                )
                                              ).name
                                            }})</span
                                          >
                                        </p>
                                      </div>
                                    </div>
                                  </template>
                                </b-table>
                                <div
                                  v-if="existingItems.length == 0"
                                  class="text-center"
                                >
                                  <p>No Results Found</p>
                                </div>
                                <b-row v-else>
                                  <b-col cols="2"> </b-col>
                                  <b-col cols="5" v-if="fromRowsExistingItems">
                                    <p class="text-center">
                                      Showing Result From
                                      <b>{{ fromRowsExistingItems }}</b> to
                                      <b>{{ toRowsExistingItems }}</b>
                                    </p>
                                  </b-col>
                                  <b-col>
                                    <b-pagination
                                      class="float-right"
                                      v-model="currentPageExistingItems"
                                      :total-rows="totalRowsExistingItems"
                                      :per-page="perPageExistingItems"
                                      hide-ellipsis
                                      limit="3"
                                      first-text="First"
                                      prev-text="Prev"
                                      next-text="Next"
                                      last-text="Last"
                                      aria-controls="existing-items-table"
                                    ></b-pagination>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-card>
                <!-- card Previously Saved Entites end-->

                <!-- card Entity Verification start-->
                <b-card
                  v-if="
                    (entityTypeSelect == 'O' &&
                      form.entityName != null &&
                      form.country != null &&
                      isShowingOpenCorporateData) ||
                    (entityTypeSelect == 'P' &&
                      form.firstName != null &&
                      form.searchAssociatedCompaniesValue == 'yes' &&
                      isShowingOpenCorporateData)
                  "
                  class="mt-2"
                  no-body
                  header-tag="header"
                  style="border: 0px"
                >
                  <template #header>
                    <div class="d-flex">
                      <div style="display: flex; align-items: center">
                        <font-awesome-icon
                          v-if="collapse.EntityVerification"
                          @click.native="
                            collapse.EntityVerification =
                              !collapse.EntityVerification
                          "
                          icon="minus"
                          data-test-id="add-third-party-modal__entity-verification-collapse"
                        />
                        <font-awesome-icon
                          v-else
                          @click.native="
                            collapse.EntityVerification =
                              !collapse.EntityVerification
                          "
                          icon="plus"
                          data-test-id="add-third-party-modal__entity-verification-expand"
                        />
                        <h6 class="mb-0 ml-2">Entity Verification</h6>
                      </div>
                      <div class="ml-auto">
                        <a
                          :href="knowledgeCentreLinks.ENTITY_VERIFICATION"
                          target="_blank"
                        >
                          <BaseIcon
                            :icon="icons.QUESTION"
                            :shape="shapes.CIRCLE"
                            :size="12"
                            :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                          />
                        </a>
                      </div>
                    </div>
                  </template>
                  <b-collapse class="p-2" v-model="collapse.EntityVerification">
                    <div v-if="entityTypeSelect == 'O'">
                      <div>
                        <div v-if="isLoadingCountryData">
                          <b-skeleton-table
                            :rows="5"
                            :columns="4"
                            :table-props="{ bordered: true, striped: true }"
                          ></b-skeleton-table>
                        </div>
                        <div
                          :style="isLoadingCountryData ? 'display: none;' : ''"
                        >
                          <b-row class="mt-3">
                            <b-col cols="8" class="d-flex">
                              <p>
                                {{ makeNationalMatchesText }}
                              </p>
                            </b-col>
                            <b-col cols="4">
                              <b-btn
                                v-if="totalRows != 0"
                                @click="
                                  verificationTableCollapse =
                                    !verificationTableCollapse
                                "
                                variant="outline-primary"
                                style="float: right"
                                size="sm"
                                :disabled="
                                  form.entityName == null &&
                                  form.country == null
                                    ? true
                                    : false
                                "
                                data-test-id="add-third-party-modal__entity-verification-table-toggle"
                                >{{
                                  verificationTableCollapse ? "Hide" : "Show me"
                                }}</b-btn
                              >
                            </b-col>
                          </b-row>
                          <b-collapse v-model="verificationTableCollapse">
                            <b-row>
                              <b-col cols="12" class="table-col">
                                <div>
                                  <div
                                    :class="
                                      tableWithCountryItems.length
                                        ? ''
                                        : 'd-none'
                                    "
                                  >
                                    <b-table
                                      :aria-disabled="resultsFetchDisable"
                                      thead-class="thead-blue-color"
                                      ref="table"
                                      sticky-header
                                      bordered
                                      id="data-location-table"
                                      :busy.sync="isLoadingCountryData"
                                      :fields="openCorporateTableFields"
                                      :items="
                                        getOpenCorporatesCompanyWithCountry
                                      "
                                      :sort-by="sortBy"
                                      :sort-desc="sortDesc"
                                      responsive="sm"
                                      small
                                      outlined
                                      :per-page="perPage"
                                      :current-page="currentPage"
                                      :filter="filter"
                                    >
                                      <template v-slot:cell(select)="data">
                                        <b-form-radio
                                          :disabled="
                                            resultsFetchDisable ||
                                            editModalShow ||
                                            showCreditsAlert
                                          "
                                          @change="setOpenCorporateData(data)"
                                          v-model="selectedRowWithCountry"
                                          data-test-id="add-third-party-modal__entity-verification-country-search-select"
                                          :value="data.index"
                                          class="text-center"
                                          name="some-radios"
                                        ></b-form-radio>
                                      </template>
                                      <template
                                        #cell(registered_country)="data"
                                      >
                                        <div
                                          v-if="
                                            getCountryNames(
                                              data.item.jurisdiction_code
                                            ) == -1
                                          "
                                        >
                                          <p>
                                            <span>{{
                                              "&nbsp;" +
                                              getJurisdicationCountryName(
                                                data.item.jurisdiction_code
                                              ).name
                                            }}</span>
                                          </p>
                                        </div>
                                        <div v-else>
                                          <div>
                                            <p>
                                              <span
                                                >{{
                                                  "&nbsp;" +
                                                  OCStatesData[
                                                    getCountryNames(
                                                      data.item
                                                        .jurisdiction_code
                                                    )
                                                  ].state
                                                }}
                                                ({{
                                                  getJurisdicationCountryName(
                                                    data.item.jurisdiction_code.substring(
                                                      0,
                                                      2
                                                    )
                                                  ).name
                                                }})</span
                                              >
                                            </p>
                                          </div>
                                        </div>
                                      </template>
                                      <template
                                        #cell(registration_status)="data"
                                      >
                                        {{ data.item.current_status }}
                                      </template>
                                    </b-table>
                                    <div
                                      v-if="tableWithCountryItems.length == 0"
                                      class="text-center"
                                    >
                                      <p>No Results Found</p>
                                    </div>
                                    <b-row v-else>
                                      <b-col cols="2"> </b-col>
                                      <b-col cols="5" v-if="fromRows">
                                        <p class="text-center">
                                          Showing Result From
                                          <b>{{ fromRows }}</b> to
                                          <b>{{ toRows }}</b>
                                        </p>
                                      </b-col>
                                      <b-col>
                                        <b-pagination
                                          :disabled="
                                            resultsFetchDisable ||
                                            editModalShow ||
                                            showCreditsAlert
                                          "
                                          @input="
                                            resetPaginationCountryCorporate
                                          "
                                          class="float-right"
                                          v-model="currentPage"
                                          :total-rows="totalPaginationRows"
                                          :per-page="perPage"
                                          hide-ellipsis
                                          limit="3"
                                          first-text="First"
                                          prev-text="Prev"
                                          next-text="Next"
                                          last-text="Last"
                                          aria-controls="data-location-table"
                                        ></b-pagination>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </b-collapse>
                        </div>
                      </div>
                      <div>
                        <div v-if="isLoadingForOthers">
                          <b-skeleton-table
                            :rows="5"
                            :columns="4"
                            :table-props="{ bordered: true, striped: true }"
                          ></b-skeleton-table>
                        </div>
                        <div
                          :style="isLoadingForOthers ? 'display: none;' : ''"
                        >
                          <b-row>
                            <b-col cols="8" class="d-flex">
                              <p>
                                {{ makeGlobalMatchesText }}
                              </p>
                            </b-col>
                            <b-col cols="4">
                              <b-btn
                                v-if="totalRowsOtherCountries != 0"
                                @click="
                                  verificationTableCollapseTwo =
                                    !verificationTableCollapseTwo
                                "
                                variant="outline-primary"
                                style="float: right"
                                size="sm"
                                :disabled="
                                  form.entityName == null &&
                                  form.country == null
                                    ? true
                                    : false
                                "
                                data-test-id="add-third-party-modal__entity-verification-global-search-table-toggle"
                                >{{
                                  verificationTableCollapseTwo
                                    ? "Hide"
                                    : "Show me"
                                }}</b-btn
                              >
                            </b-col>
                          </b-row>
                          <b-row v-show="hasNoMatches">
                            <BaseInfoBox
                              :icon-options="defaultIcons.INFO"
                              :text="infoMessages.thirdParty.iddInfoMessage"
                            />
                          </b-row>
                          <b-collapse v-model="verificationTableCollapseTwo">
                            <b-row>
                              <b-col cols="12" class="table-col">
                                <div>
                                  <div
                                    :class="
                                      tableWithoutCountryItems.length
                                        ? ''
                                        : 'd-none'
                                    "
                                  >
                                    <b-table
                                      :aria-disabled="resultsFetchDisable"
                                      thead-class="thead-blue-color"
                                      sticky-header
                                      ref="table"
                                      id="data-other-location-table"
                                      :busy.sync="isLoadingForOthers"
                                      :fields="openCorporateTableFields"
                                      :items="getOpenCorporatesCompany"
                                      :sort-by="sortByOtherCountries"
                                      :sort-desc="sortDescOtherCountries"
                                      responsive="sm"
                                      bordered
                                      small
                                      outlined
                                      :per-page="perPageOtherCountries"
                                      :current-page="currentPageOtherCountries"
                                      :filter="filterOtherCountries"
                                    >
                                      <template v-slot:cell(select)="data">
                                        <b-form-radio
                                          :disabled="
                                            resultsFetchDisable ||
                                            editModalShow ||
                                            showCreditsAlert
                                          "
                                          @change="setOpenCorporateData(data)"
                                          v-model="selectedRowWithoutCountry"
                                          data-test-id="add-third-party-modal__entity-verification-global-search-select"
                                          :value="data.index"
                                          class="text-center"
                                          name="some-radios"
                                        ></b-form-radio>
                                      </template>
                                      <template
                                        #cell(registered_country)="data"
                                      >
                                        <div
                                          v-if="
                                            getCountryNames(
                                              data.item.jurisdiction_code
                                            ) == -1
                                          "
                                        >
                                          <p>
                                            <span>{{
                                              "&nbsp;" +
                                              getJurisdicationCountryName(
                                                data.item.jurisdiction_code
                                              ).name
                                            }}</span>
                                          </p>
                                        </div>
                                        <div v-else>
                                          <div>
                                            <p>
                                              <span
                                                >{{
                                                  "&nbsp;" +
                                                  OCStatesData[
                                                    getCountryNames(
                                                      data.item
                                                        .jurisdiction_code
                                                    )
                                                  ].state
                                                }}
                                                ({{
                                                  getJurisdicationCountryName(
                                                    data.item.jurisdiction_code.substring(
                                                      0,
                                                      2
                                                    )
                                                  ).name
                                                }})</span
                                              >
                                            </p>
                                          </div>
                                        </div>
                                      </template>
                                      <template
                                        #cell(registration_status)="data"
                                      >
                                        {{ data.item.current_status }}
                                      </template>
                                    </b-table>
                                    <div
                                      v-if="
                                        tableWithoutCountryItems.length == 0
                                      "
                                      class="text-center"
                                    >
                                      <p>No Results Found</p>
                                    </div>
                                    <b-row v-else>
                                      <b-col cols="2"> </b-col>
                                      <b-col
                                        cols="5"
                                        v-if="fromRowsOtherCountries"
                                      >
                                        <p class="text-center">
                                          Showing Result From
                                          <b>{{ fromRowsOtherCountries }}</b> to
                                          <b>{{ toRowsOtherCountries }}</b>
                                        </p>
                                      </b-col>
                                      <b-col>
                                        <b-pagination
                                          :disabled="
                                            resultsFetchDisable ||
                                            editModalShow ||
                                            showCreditsAlert
                                          "
                                          @input="
                                            resetPaginationGlobalCorporate
                                          "
                                          class="float-right"
                                          v-model="currentPageOtherCountries"
                                          :total-rows="
                                            totalPaginationRowsOtherCountries
                                          "
                                          :per-page="perPageOtherCountries"
                                          hide-ellipsis
                                          limit="3"
                                          first-text="First"
                                          prev-text="Prev"
                                          next-text="Next"
                                          last-text="Last"
                                          aria-controls="data-other-location-table"
                                        ></b-pagination>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </b-collapse>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        form.searchAssociatedCompaniesValue == 'yes' &&
                        entityTypeSelect == 'P'
                      "
                      style="padding-top: 5px"
                    >
                      <div v-if="isLoadingForIndividual">
                        <b-skeleton-table
                          :rows="5"
                          :columns="4"
                          :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table>
                      </div>
                      <div
                        :style="isLoadingForIndividual ? 'display: none;' : ''"
                      >
                        <b-row>
                          <b-col cols="10" class="d-flex">
                            <p>
                              The companies listed below have an associated
                              individual named "{{ form.firstName }}" listed
                              within that companies online registry records.
                            </p>
                          </b-col>
                          <b-col cols="2">
                            <b-btn
                              v-if="totalRowsIndividual != 0"
                              @click="
                                verificationTableCollapseThree =
                                  !verificationTableCollapseThree
                              "
                              variant="outline-primary"
                              style="float: right"
                              size="sm"
                              :disabled="form.firstName == null ? true : false"
                              >{{
                                verificationTableCollapseThree
                                  ? "Hide"
                                  : "Show me"
                              }}</b-btn
                            >
                          </b-col>
                        </b-row>
                        <b-collapse v-model="verificationTableCollapseThree">
                          <b-row>
                            <b-col cols="12" class="table-col">
                              <div>
                                <div
                                  :class="
                                    tableWithIndividualItems.length
                                      ? ''
                                      : 'd-none'
                                  "
                                >
                                  <b-table
                                    :aria-disabled="resultsFetchDisable"
                                    thead-class="thead-blue-color"
                                    sticky-header="calc(100vh - 200px)"
                                    ref="table"
                                    id="data-other-location-table"
                                    :busy.sync="isLoadingForIndividual"
                                    :fields="openCorporateIndividualFields"
                                    :items="getOpenCorporatesCompanyIndividual"
                                    :sort-by="sortByIndividual"
                                    :sort-desc="sortDescIndividual"
                                    responsive="sm"
                                    bordered
                                    small
                                    outlined
                                    :per-page="perPageIndividual"
                                    :current-page="currentPageIndividual"
                                    :filter="filterIndividual"
                                  >
                                    <template v-slot:cell(select)="data">
                                      <b-form-radio
                                        :disabled="
                                          resultsFetchDisable ||
                                          editModalShow ||
                                          showCreditsAlert
                                        "
                                        @change="setOpenCorporateData(data)"
                                        v-model="selectedIndividualRowCountry"
                                        :value="data.index"
                                        class="text-center"
                                        name="some-radios"
                                      ></b-form-radio>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                      {{ data.item.company.name }}
                                    </template>
                                    <template #cell(registered_country)="data">
                                      <div
                                        v-if="
                                          getCountryNames(
                                            data.item.jurisdiction_code
                                          ) == -1
                                        "
                                      >
                                        <p>
                                          <span>{{
                                            "&nbsp;" +
                                            getJurisdicationCountryName(
                                              data.item.jurisdiction_code
                                            ).name
                                          }}</span>
                                        </p>
                                      </div>
                                      <div v-else>
                                        <div>
                                          <p>
                                            <span
                                              >{{
                                                "&nbsp;" +
                                                OCStatesData[
                                                  getCountryNames(
                                                    data.item.jurisdiction_code
                                                  )
                                                ].state
                                              }}
                                              ({{
                                                getJurisdicationCountryName(
                                                  data.item.jurisdiction_code.substring(
                                                    0,
                                                    2
                                                  )
                                                ).name
                                              }})</span
                                            >
                                          </p>
                                        </div>
                                      </div>
                                    </template>
                                    <template #cell(registration_status)="data">
                                      {{ data.item.position }}
                                    </template>
                                  </b-table>
                                  <div
                                    v-if="tableWithIndividualItems.length == 0"
                                    class="text-center"
                                  >
                                    <p>No Results Found</p>
                                  </div>
                                  <b-row v-else>
                                    <b-col cols="2"> </b-col>
                                    <b-col cols="5" v-if="fromRowsIndividual">
                                      <p class="text-center">
                                        Showing Result From
                                        <b>{{ fromRowsIndividual }}</b> to
                                        <b>{{ toRowsIndividual }}</b>
                                      </p>
                                    </b-col>
                                    <b-col>
                                      <b-pagination
                                        :disabled="
                                          resultsFetchDisable ||
                                          editModalShow ||
                                          showCreditsAlert
                                        "
                                        @input="resetPaginationIndividual"
                                        class="float-right"
                                        v-model="currentPageIndividual"
                                        :total-rows="totalPaginationIndividual"
                                        :per-page="perPageIndividual"
                                        hide-ellipsis
                                        limit="3"
                                        first-text="First"
                                        prev-text="Prev"
                                        next-text="Next"
                                        last-text="Last"
                                        aria-controls="data-other-location-table"
                                      ></b-pagination>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-collapse>
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- card Entity Verification end-->

                <!-- Associate screenings card start -->
                <b-card
                  style="border: 0px"
                  v-if="
                    entityTypeSelect == 'O' && form.entityName && form.country
                  "
                  class="mt-2"
                  no-body
                  header-tag="header"
                >
                  <!-- header -->
                  <template #header>
                    <div class="d-flex">
                      <div style="display: flex; align-items: center">
                        <font-awesome-icon
                          v-if="collapse.AssociateEntities"
                          @click.native="
                            collapse.AssociateEntities =
                              !collapse.AssociateEntities
                          "
                          icon="minus"
                          data-test-id="add-third-party-modal__associate-entities-collapse"
                        />
                        <font-awesome-icon
                          v-else
                          @click.native="
                            collapse.AssociateEntities =
                              !collapse.AssociateEntities
                          "
                          icon="plus"
                          data-test-id="add-third-party-modal__associate-entities-expand"
                        />
                        <h6 class="mb-0 ml-2">Associate entities</h6>
                      </div>
                      <div class="ml-auto">
                        <a
                          :href="knowledgeCentreLinks.ASSOCIATE_ENTITIES"
                          target="_blank"
                        >
                          <BaseIcon
                            :icon="icons.QUESTION"
                            :shape="shapes.CIRCLE"
                            :size="12"
                            :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                          />
                        </a>
                      </div>
                    </div>
                  </template>

                  <!-- loop to add delete input fields -->
                  <b-collapse class="p-2" v-model="collapse.AssociateEntities">
                    <div class="mt-3">
                      <p>
                        You may add up to
                        <span style="text-transform: lowercase">{{
                          getNumberWord(headerConfigData.maximumAssociate)
                        }}</span>
                        associated entities below to be searched in conjunction
                        with <b>{{ form.entityName }}</b
                        >.
                      </p>

                      <p v-if="platformSupportEmail">
                        Should you wish to add more associated entities, please
                        contact
                        <a :href="platformSupportEmail">
                          {{ platformSupportEmail.split(":")[1] }}</a
                        >.
                      </p>
                      <b-row
                        v-for="(users, associatesUsersIndex) in associatesUsers"
                        :key="associatesUsersIndex"
                      >
                        <b-col cols="4">
                          <b-form-group>
                            <!-- in disabled remove editModalShow when doing associates funtionality work -->
                            <b-form-select
                              :disabled="showCreditsAlert"
                              v-model="users.entityType"
                              @input="
                                onSelectEntityType($event, associatesUsersIndex)
                              "
                              :state="users.validationStateEntityType"
                              size="sm"
                              data-test-id="add-third-party-modal__associate-entities-type"
                            >
                              <b-form-select-option value="O"
                                >Company</b-form-select-option
                              >
                              <b-form-select-option value="P"
                                >Individual</b-form-select-option
                              >
                            </b-form-select>
                            <p
                              v-if="users.validationStateEntityType == false"
                              class="text-feedback"
                            >
                              {{ "Please Select Entity Type" }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col cols="5" class="p-0">
                          <b-form-group class="ml-1">
                            <b-form-input
                              :disabled="showCreditsAlert"
                              size="sm"
                              v-model="users.assoName"
                              placeholder="Full name"
                              @input="
                                onInputAssosName($event, associatesUsersIndex)
                              "
                              :state="users.validationStateAssosName"
                              data-test-id="add-third-party-modal__associate-entities-input"
                            ></b-form-input>
                            <p
                              v-if="users.validationStateAssosName == false"
                              class="text-feedback"
                            >
                              {{ "Please Enter Associate Name" }}
                            </p>
                          </b-form-group>
                        </b-col>
                        <b-col cols="3" class="pr-0">
                          <b-button
                            :disabled="showCreditsAlert"
                            @click="
                              removeAssociateRow(associatesUsersIndex, users)
                            "
                            variant="outline-danger"
                            size="sm"
                            v-if="associatesUsersIndex > 0"
                            data-test-id="add-third-party-modal__associate-entities-remove"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>

                          <b-button
                            :disabled="showCreditsAlert"
                            @click="
                              removeFirstRowAssociateRow(
                                associatesUsersIndex,
                                users
                              )
                            "
                            variant="outline-danger"
                            size="sm"
                            v-show="
                              associatesUsersIndex == 0
                                ? associatesUsers[0].assoName
                                  ? true
                                  : associatesUsers[0].entityType
                                  ? true
                                  : false
                                : false
                            "
                            data-test-id="add-third-party-modal__associate-entities-remove"
                          >
                            <font-awesome-icon icon="trash" />
                          </b-button>
                          <span
                            v-if="
                              users.assoName &&
                              headerConfigData.maximumAssociate !=
                                associatesUsers.length
                            "
                          >
                            <b-button
                              :disabled="
                                showCreditsAlert ||
                                headerConfigData.maximumAssociate ==
                                  associatesUsers.length
                              "
                              class="ml-2"
                              size="sm"
                              variant="outline-success"
                              @click="addAssociateRow(associatesUsersIndex)"
                              v-show="
                                associatesUsersIndex ==
                                associatesUsers.length - 1
                              "
                              data-test-id="add-third-party-modal__associate-entities-add"
                            >
                              <font-awesome-icon icon="plus" />
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>
                      <b-alert
                        v-model="isChangeInAssociates"
                        v-if="isChangeInAssociates && editModalShow"
                        variant="warning"
                      >
                        Selected actions are now invalidated and should be rerun
                      </b-alert>
                      <b-overlay :show="isAssoLoading">
                        <div v-if="showingAssociatesTable" class="table-col">
                          <div v-if="isAssoLoading">
                            <b-skeleton-table
                              :rows="5"
                              :columns="4"
                              :table-props="{ bordered: true, striped: true }"
                            ></b-skeleton-table>
                          </div>
                          <!-- Verification Data Table -->
                          <div
                            :style="
                              isLoadingAssociatesTable ? 'display: none;' : ''
                            "
                          >
                            <div
                              :class="
                                openCorporatesCompanyOfficersItems.length
                                  ? ''
                                  : 'd-none'
                              "
                            >
                              <b-table
                                :aria-disabled="resultsFetchDisable"
                                ref="table"
                                id="associated-table"
                                :fields="openCorporatesCompanyOfficersFields"
                                :items="openCorporatesCompanyOfficersItems"
                                :sort-by.sync="associatedSortBy"
                                :sort-desc.sync="associatedSortDesc"
                                responsive="sm"
                                small
                                outlined
                                :per-page="50"
                                :current-page="associatedCurrentPage"
                              >
                                <template v-slot:cell(select)="data">
                                  <Checkbox
                                    :disabled="
                                      showCreditsAlert ||
                                      (!selectedCompanyOfficers[data.index] &&
                                        headerConfigData.maximumAssociate ==
                                          associatesUsers.length)
                                    "
                                    @update:modelValue="
                                      selectAssociatedUsersData($event, data)
                                    "
                                    v-model="
                                      selectedCompanyOfficers[data.index]
                                    "
                                    label="company officers"
                                    labelHidden
                                  />
                                </template>
                              </b-table>
                              <div
                                v-if="
                                  openCorporatesCompanyOfficersItems.length == 0
                                "
                                class="text-center"
                              >
                                <p>
                                  Suggested associate data is not available for
                                  the selected entity
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- Associate screenings card end  -->

                <!-- card Additional Details start-->
                <b-card
                  no-body
                  header-tag="header"
                  style="border: 0px"
                  class="mt-2"
                  v-if="
                    (entityTypeSelect == 'O' &&
                      form.entityName != null &&
                      form.country != null) ||
                    (entityTypeSelect == 'P' && form.firstName != null)
                  "
                >
                  <!-- additional details card header -->
                  <template #header>
                    <div class="d-flex">
                      <div style="display: flex; align-items: center">
                        <font-awesome-icon
                          v-if="collapse.AdditionalDetails"
                          @click.native="
                            collapse.AdditionalDetails =
                              !collapse.AdditionalDetails
                          "
                          icon="minus"
                          data-test-id="add-third-party-modal__additional-details-collapse"
                        />
                        <font-awesome-icon
                          v-else
                          @click.native="
                            collapse.AdditionalDetails =
                              !collapse.AdditionalDetails
                          "
                          icon="plus"
                          data-test-id="add-third-party-modal__additional-details-expand"
                        />
                        <h6 class="mb-0 ml-2">Additional Details</h6>
                      </div>
                    </div>
                  </template>
                  <!-- additonal details form fields -->
                  <b-collapse class="p-2" v-model="collapse.AdditionalDetails">
                    <div class="mt-3">
                      <ThirdPartyCustomAttributes
                        v-if="Object.keys(customAttributesValues).length"
                        :attributes="dynamicCustomAttributesList"
                        :values="customAttributesValues"
                      />
                      <!-- if any MSQ is active -->
                      <div
                        v-if="checkIsMsqActive()"
                        ref="add-third-party-modal__msq-wrapper"
                      >
                        <b-form-group label-cols-lg="3">
                          <template #label>
                            <p>Contact Name</p>
                          </template>
                          <b-form-input
                            :disabled="showCreditsAlert"
                            id="contact-person-feedback"
                            required
                            size="sm"
                            placeholder="---Required---"
                            v-model="v$.additionalDetailForm.contactName.$model"
                            :state="
                              v$.additionalDetailForm.contactName.$dirty
                                ? !v$.additionalDetailForm.contactName.$error
                                : null
                            "
                            data-test-id="add-third-party-modal__additional-details-contact-name"
                          ></b-form-input>
                          <b-form-invalid-feedback id="contact-person-feedback"
                            >A Required field has no
                            value.</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group label-cols-lg="3" label="Contact Email">
                          <b-form-input
                            type="email"
                            size="sm"
                            placeholder="---Required---"
                            @input="invalidEmailDomain = false"
                            :disabled="showCreditsAlert"
                            id="contact-email-feedback"
                            v-model="
                              v$.additionalDetailForm.contactEmail.$model
                            "
                            :state="
                              invalidEmailDomain
                                ? false
                                : v$.additionalDetailForm.contactEmail.$dirty
                                ? !v$.additionalDetailForm.contactEmail.$error
                                : null
                            "
                            data-test-id="add-third-party-modal__additional-details-contact-email"
                          ></b-form-input>
                          <b-form-invalid-feedback v-if="invalidEmailDomain">
                            {{ invalidEmailDomainValue }}
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            v-if="v$.additionalDetailForm.contactEmail.$error"
                            id="contact-email-feedback"
                            >Please enter a valid email</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group
                          class="add-third-party-modal__contact-language"
                          description="In order to update the contact language, you'll need to have a contact name and contact email present"
                          label-cols-lg="3"
                        >
                          <template #label>
                            <p>Contact Language</p>
                          </template>
                          <v-select
                            :disabled="showCreditsAlert"
                            placeholder="---Required---"
                            v-model="
                              v$.additionalDetailForm.contactLanguage.$model
                            "
                            :state="
                              v$.additionalDetailForm.contactLanguage.$dirty
                                ? !v$.additionalDetailForm.contactLanguage
                                    .$error
                                : null
                            "
                            :class="{
                              'form-control is-invalid p-0':
                                v$.additionalDetailForm.contactLanguage.$error
                            }"
                            :options="formsLanguagesList"
                            @update:modelValue="setContactLanguage"
                            label="languages"
                            :clearable="false"
                            data-test-id="add-third-party-modal__additional-details-contact-language"
                          >
                          </v-select>
                          <b-form-invalid-feedback
                            id="contact-language-feedback"
                            >A Required field has no
                            value.</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </div>

                      <!-- else -->
                      <div v-else ref="add-third-party-modal__msq-wrapper">
                        <b-form-group label-cols-lg="3">
                          <template #label>
                            <p>Contact Name</p>
                          </template>
                          <b-form-input
                            :placeholder="getPlaceholderText"
                            :disabled="resultsFetchDisable || showCreditsAlert"
                            id="contact-person-feedback"
                            :required="
                              additionalDetailForm.contactEmail &&
                              !additionalDetailForm.contactName
                                ? true
                                : false
                            "
                            size="sm"
                            v-model="additionalDetailForm.contactName"
                            :state="
                              additionalDetailForm.contactEmail &&
                              !additionalDetailForm.contactName
                                ? false
                                : null
                            "
                            data-test-id="add-third-party-modal__additional-details-contact-name"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            v-if="
                              additionalDetailForm.contactEmail &&
                              !additionalDetailForm.contactName
                                ? true
                                : false
                            "
                            id="contact-person-feedback"
                          >
                            A Required field has no value.
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label-cols-lg="3" label="Contact Email">
                          <b-form-input
                            :placeholder="getPlaceholderText"
                            type="email"
                            size="sm"
                            @input="invalidEmailDomain = false"
                            :required="
                              additionalDetailForm.contactName &&
                              !additionalDetailForm.contactEmail
                                ? true
                                : false
                            "
                            :disabled="resultsFetchDisable || showCreditsAlert"
                            id="contact-email-feedback"
                            v-model="
                              v$.additionalDetailForm.contactEmail.$model
                            "
                            :state="
                              additionalDetailForm.contactName &&
                              !additionalDetailForm.contactEmail
                                ? false
                                : v$.additionalDetailForm.contactEmail.$model &&
                                  invalidEmailDomain
                                ? false
                                : v$.additionalDetailForm.contactEmail.$dirty
                                ? !v$.additionalDetailForm.contactEmail.$error
                                : null
                            "
                            data-test-id="add-third-party-modal__additional-details-contact-email"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            v-if="
                              v$.additionalDetailForm.contactEmail.$model &&
                              invalidEmailDomain
                            "
                          >
                            {{ invalidEmailDomainValue }}
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            v-if="v$.additionalDetailForm.contactEmail.$error"
                            id="contact-email-feedback"
                            >Please enter a valid email</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback
                            v-if="
                              additionalDetailForm.contactName &&
                              !additionalDetailForm.contactEmail
                                ? true
                                : false
                            "
                            id="contact-email-feedback"
                            >A Required field has no
                            value.</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group
                          class="add-third-party-modal__contact-language"
                          description="In order to update the contact language, you'll need to have a contact name and contact email present"
                          label-cols-lg="3"
                        >
                          <template #label>
                            <p>Contact Language</p>
                          </template>
                          <v-select
                            :disabled="showCreditsAlert"
                            label="languages"
                            v-model="additionalDetailForm.contactLanguage"
                            :options="formsLanguagesList"
                            @update:modelValue="setContactLanguage"
                            :clearable="false"
                            data-test-id="add-third-party-modal__additional-details-contact-language"
                          >
                          </v-select>
                        </b-form-group>
                      </div>
                      <b-form-group label-cols-lg="3" label="Contact Number">
                        <b-form-input
                          :disabled="showCreditsAlert"
                          type="number"
                          size="sm"
                          v-model="additionalDetailForm.contactPhone"
                          onkeydown="return event.keyCode !== 69"
                          data-test-id="add-third-party-modal__additional-details-contact-number"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="mt-2"
                        label-cols-lg="3"
                        label="Address"
                      >
                        <b-form-textarea
                          :disabled="showCreditsAlert"
                          v-model="additionalDetailForm.address"
                          data-test-id="add-third-party-modal__additional-details-address"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group label-cols-lg="3" label="City">
                        <b-form-input
                          :disabled="showCreditsAlert"
                          size="sm"
                          v-model="additionalDetailForm.city"
                          data-test-id="add-third-party-modal__additional-details-city"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-lg="3" label="State">
                        <b-form-input
                          :disabled="showCreditsAlert"
                          size="sm"
                          v-model="additionalDetailForm.state"
                          data-test-id="add-third-party-modal__additional-details-state"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-lg="3" label="Postal/Zip Code">
                        <b-form-input
                          :disabled="showCreditsAlert"
                          size="sm"
                          v-model="additionalDetailForm.postal_code"
                          onkeydown="return event.keyCode !== 69"
                          data-test-id="add-third-party-modal__additional-details-postal-code"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="getSystemOwnerListOptions.length"
                        label-cols-lg="3"
                      >
                        <template #label>
                          <div
                            class="add-third-party-modal__system-owner-label-wrapper"
                          >
                            <BaseText text="System Owner" tag="span" />
                            <Tooltip
                              class="add-third-party-modal__system-owner-tooltip"
                              text="System Owner is the user who will receive default notifications from the platform"
                            />
                          </div>
                        </template>
                        <v-select
                          id="tp-system-owner"
                          name="tp-system-owner"
                          label="text"
                          full-width
                          v-model="selectedSystemOwner"
                          :options="getSystemOwnerListOptions"
                          @update:modelValue="onChangeSystemOwner"
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-lg="3"
                        label="Registration Number"
                      >
                        <b-form-input
                          :disabled="showCreditsAlert"
                          size="sm"
                          v-model="form.registrationNumber"
                          data-test-id="add-third-party-modal__additional-details-registration-number"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- card Additional Details end-->

                <!-- card Results start -->
                <b-card
                  style="border: 0px"
                  v-if="showingReviewResult"
                  class="mt-2"
                  no-body
                  header-tag="header"
                >
                  <!-- result card  -->
                  <template #header>
                    <div class="d-flex">
                      <div style="display: flex; align-items: center">
                        <h6 class="mb-0">Results</h6>
                      </div>
                    </div>
                  </template>
                  <div v-if="showingReviewResult">
                    <b-overlay :show="isLoadingResultData" rounded="sm">
                      <b-row class="mt-1" v-if="!isLoadingResultData">
                        <b-col class="mt-2" cols="12">
                          <h6 v-if="hideSubmitButton" class="text-center">
                            Your request(s) have been executed.
                          </h6>
                        </b-col>
                        <b-col
                          class="mt-2"
                          cols="12"
                          v-if="
                            searchReportData &&
                            searchReportData.shield_vendor_infos &&
                            searchReportData.shield_vendor_infos.risk_level &&
                            searchReportData.shield_vendor_infos.risk_level != 0
                          "
                        >
                          <!-- result component -->
                          <ReviewResult
                            :OCStatesData="OCStatesData"
                            :searchReportData="searchReportData"
                            :tpDnbData="tpDnbData"
                            :tpDnbSelectedAltaresTableItemData="
                              tpDnbSelectedAltaresTableItemData
                            "
                            :beneficialOwnershipRegistrationData="
                              beneficialOwnershipRegistrationData
                            "
                            :showNoteText="showNoteText"
                          />
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </div>
                </b-card>
                <!-- card Results end -->
              </b-col>
            </b-row>
          </b-col>
          <!-- 8 column div end -->

          <!-- 4 column div start -->

          <!-- List view -->
          <b-col
            :class="
              showCreditsAlert
                ? 'cols-4-with-voucheralert-height-styles'
                : 'cols-4-height-styles'
            "
            v-if="changeListView"
            cols="4"
          >
            <!-- Card My Risk Rating start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <!-- Card My Risk Rating header-->
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>My Risk Rating</h6>
                  </div>
                  <div style="margin-right: 21px">
                    <a
                      :href="knowledgeCentreLinks.MY_RISK_RATING"
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                </div>
              </template>

              <!-- Card My Risk Rating content-->
              <div>
                <div
                  class="p-2"
                  :style="{
                    backgroundColor:
                      isThirdPartyArchived || isLoadingResultReview
                        ? '#e9ecef'
                        : 'white'
                  }"
                  data-test-id="add-third-party-modal__risk-rating"
                >
                  <b-dropdown
                    :disabled="isLoadingResultReview || isThirdPartyArchived"
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    menu-class="risk-rating-drop-down"
                    data-test-id="add-third-party-modal__risk-rating-dropdown"
                  >
                    <template #button-content>
                      <div class="add-third-party-modal__risk-rating">
                        <div>
                          <BaseIcon v-bind="selectedRiskRatingIcon" />
                        </div>
                        <div style="margin-left: 20px">
                          <b class="d-flex">
                            <font-awesome-icon
                              class="text-muted"
                              icon="pen"
                            />&nbsp;Risk Rating -
                            <p>&nbsp;{{ selectedRiskRatingText }}</p>
                          </b>
                        </div>
                      </div>
                    </template>
                    <b-dropdown-item style="width: 400px">
                      <div class="d-flex">
                        <div
                          class="mx-1"
                          v-for="(item, index) in riskRatingJsonData
                            .myRiskRatingData.status"
                          :key="index"
                          @click="selectRiskRatingOption(item)"
                          style="cursor: pointer"
                        >
                          <BaseIcon
                            :icon="item.icon"
                            :shape="item.shape"
                            :theme="item.theme"
                            :size="item.size"
                          />
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div style="margin-left: 90px">
                    <b-form-group>
                      <b-form-textarea
                        v-if="!onChangeRatingStatus"
                        :disabled="isLoadingResultReview"
                        :state="validateRiskRatingComment"
                        placeholder="Please include reasons for change"
                        @input="inputRiskRatingComment"
                        v-model="riskRatingComment"
                        data-test-id="add-third-party-modal__risk-rating-comment"
                      ></b-form-textarea>

                      <b-form-invalid-feedback id="contact-person-feedback"
                        >This is a Required field and Notes can be of maximum
                        250 characters</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-card>
            <!-- Card My Risk Rating end  -->

            <!-- Card Previously Performed Actions start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <!-- Card Previously Performed Actions header-->
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Previously Performed Actions</h6>
                  </div>
                  <!-- to change cards grid layout start -->
                  <div class="d-flex" style="margin-right: 21px">
                    <div style="margin-right: 10px; margin-top: 3px">
                      <font-awesome-icon
                        v-if="changeListView"
                        @click.native="changeListView = !changeListView"
                        icon="th-large"
                      />
                      <font-awesome-icon
                        v-else
                        @click.native="changeListView = !changeListView"
                        icon="list"
                      />
                    </div>
                    <div class="ml-auto">
                      <a
                        :href="
                          knowledgeCentreLinks.PREVIOUSLY_PERFORMED_ACTIONS
                        "
                        target="_blank"
                      >
                        <BaseIcon
                          :icon="icons.QUESTION"
                          :shape="shapes.CIRCLE"
                          :size="12"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                        />
                      </a>
                    </div>
                  </div>
                  <!-- to change cards grid layout end -->
                </div>
              </template>

              <!-- Card Previously Performed Actions content-->
              <div v-if="!editModalShow && !showingReviewResult">
                <p class="p-2" style="color: lightgray; font-size: 15px">
                  No actions have occurred on this Entity
                </p>
              </div>
              <div v-if="showingReviewResult || editModalShow">
                <b-overlay :show="isLoadingResultData" rounded="sm">
                  <b-list-group v-if="!isLoadingResultData">
                    <b-list-group-item
                      :disabled="showingReviewResult || editModalShow"
                      v-for="(action, index) in completedActions"
                      :key="index"
                      size="sm"
                      :style="{
                        backgroundColor:
                          action.imgName == 'ok' ||
                          action.imgName == 'RemediatedOK'
                            ? '#d0f2d2'
                            : action.imgName == 'HighAlert' ||
                              action.imgName == 'remediatedHighAlert'
                            ? '#f9d1ce'
                            : action.imgName == 'inprogress'
                            ? '#e3f4fa'
                            : action.imgName == 'WarningAlert'
                            ? '#fcf2d4'
                            : 'white',
                        color: 'black',
                        width: '100%',
                        border: '0px'
                      }"
                      :data-test-id="
                        getActionDataTestId(
                          action.btnText,
                          'previously-performed-action'
                        )
                      "
                    >
                      <div class="add-tp__previously-performed-actions">
                        <div>
                          <div
                            v-if="isActionTypeTCertOrEsgm(action)"
                            class="add-tp__previously-performed-actions-completed"
                          >
                            Completed
                          </div>
                          <BaseIcon
                            v-else
                            v-bind="getIcon(action.imgName, iconSizes.LARGE)"
                          />
                        </div>
                        <div style="margin-left: 20px">
                          <b class="d-flex">
                            {{ action.btnText }}
                          </b>
                          <p
                            v-if="action.requestedDate"
                            style="
                              font-style: italic;
                              color: gray;
                              font-size: 14px;
                            "
                          >
                            Days since last ordered:
                            {{ datediff(action.requestedDate) }}
                          </p>
                        </div>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      :disabled="showingReviewResult || editModalShow"
                      v-for="(action, index) in removedActions"
                      :key="'A' + index"
                      size="sm"
                      :style="{
                        backgroundColor:
                          action.imgName == 'ok' ||
                          action.imgName == 'RemediatedOK'
                            ? '#d0f2d2'
                            : action.imgName == 'HighAlert' ||
                              action.imgName == 'remediatedHighAlert'
                            ? '#f9d1ce'
                            : action.imgName == 'inprogress'
                            ? '#e3f4fa'
                            : action.imgName == 'WarningAlert'
                            ? '#fcf2d4'
                            : 'white',
                        color: 'black',
                        width: '100%',
                        border: '0px'
                      }"
                      :data-test-id="
                        getActionDataTestId(
                          action.btnText,
                          'previously-performed-action'
                        )
                      "
                    >
                      <div style="display: flex; align-items: baseline">
                        <div>
                          <BaseIcon
                            v-bind="getIcon(action.imgName, iconSizes.LARGE)"
                          />
                        </div>
                        <div style="margin-left: 20px">
                          <b class="d-flex">
                            {{ action.btnText }}
                          </b>
                          <p
                            v-if="action.requestedDate"
                            style="
                              font-style: italic;
                              color: gray;
                              font-size: 14px;
                            "
                          >
                            Days since last ordered:
                            {{ datediff(action.requestedDate) }}
                          </p>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-overlay>
              </div>
            </b-card>
            <!-- Card Previously Performed Actions end  -->

            <!-- Card Default Actions start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <!-- Card Default Actions header-->
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Default Actions</h6>
                  </div>
                  <div class="d-flex">
                    <div style="margin-right: 21px">
                      <a
                        :href="knowledgeCentreLinks.DEFAULT_ACTIONS"
                        target="_blank"
                      >
                        <BaseIcon
                          :icon="icons.QUESTION"
                          :shape="shapes.CIRCLE"
                          :size="12"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Card Default Actions content-->
              <div>
                <div v-if="editModalShow">
                  <p class="p-2" style="color: lightgray; font-size: 15px">
                    No actions
                  </p>
                </div>
                <div v-else>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(action, index) in actionsOccurBtns"
                      :key="index"
                      style="background-color: #d0f2d2"
                      :data-test-id="
                        getActionDataTestId(action.btnText, 'default-action')
                      "
                    >
                      <b-row class="add-third-party-modal__actions">
                        <b-col cols="4">
                          <BaseIcon
                            :icon="icons.PLUS"
                            :shape="shapes.SQUARE"
                            :theme="themes.SUCCESS_WHITE"
                            :size="25"
                          />
                        </b-col>
                        <b-col cols="8">
                          <div v-if="action.actionType == 'edd'">
                            <p>
                              <b class="d-flex">{{ action.btnText }}</b>
                            </p>
                            <b-form-group v-if="checkIsEddActive()">
                              <v-select
                                :disabled="
                                  isThirdPartyArchived || hideSubmitButton
                                "
                                placeholder="-- EDD Order Type --"
                                :maxHeight="100"
                                v-model="v$.selecteditem.$model"
                                :state="
                                  v$.selecteditem.$dirty
                                    ? !v$.selecteditem.$error
                                    : null
                                "
                                :class="{
                                  'form-control is-invalid p-0':
                                    v$.selecteditem.$error
                                }"
                                label="name"
                                track-by="name"
                                :options="listOfEDD"
                                @update:modelValue="setSelectedItem($event)"
                                :clearable="false"
                              >
                              </v-select>
                              <b-form-invalid-feedback id="ordertype"
                                >There are no selected
                                actions.</b-form-invalid-feedback
                              >
                            </b-form-group>
                            <b-form-group v-else class="w-100">
                              <v-select
                                :disabled="
                                  isThirdPartyArchived || hideSubmitButton
                                "
                                placeholder="-- EDD Order Type --"
                                :maxHeight="100"
                                v-model="selecteditem"
                                label="name"
                                track-by="name"
                                :options="listOfEDD"
                                @update:modelValue="setSelectedItem($event)"
                                :clearable="false"
                              >
                                <template slot="option" slot-scope="option">
                                  <span>{{ "&nbsp;" + option.name }}</span>
                                </template>
                              </v-select>
                            </b-form-group>
                            <b-overlay
                              :show="isLoadingElements || isLoadingPackages"
                            >
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="element in allElements"
                                  :key="element.id"
                                  style="
                                    display: flex;
                                    padding: 0.35rem 1rem !important;
                                  "
                                >
                                  <font-awesome-icon
                                    class="m-2"
                                    :icon="
                                      element.package_products_id > 0
                                        ? 'check'
                                        : 'times'
                                    "
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? '#28a745'
                                          : '#CBCFD1'
                                    }"
                                  />
                                  <p
                                    class="m-0"
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? ''
                                          : '#CBCFD1'
                                    }"
                                  >
                                    <span style="font-size: 14px !important">{{
                                      element.product_name
                                    }}</span>
                                  </p>
                                </b-list-group-item>
                              </b-list-group>

                              <div
                                class="add-third-party-modal__actions mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                              >
                                <div
                                  style="display: flex; flex-direction: column"
                                >
                                  <p v-if="isMaximumLimitOvercomedByAssociates">
                                    {{ associatesUsers.length }} associates are
                                    indicated on the third party record.
                                    Additional fees may apply
                                  </p>
                                </div>
                              </div>
                              <div
                                class="add-third-party-modal__actions mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                              >
                                <font-awesome-icon class="m-1" icon="clock" />
                                <p
                                  v-if="
                                    selectedItemArray.client_packages_tat_max >
                                    0
                                  "
                                >
                                  {{ selectedItemArray.client_packages_tat }} -
                                  {{
                                    selectedItemArray.client_packages_tat_max
                                  }}
                                  Days Turn Around Time
                                </p>
                                <p v-else>
                                  {{ selectedItemArray.client_packages_tat }}
                                  Days Turn Around Time
                                </p>
                              </div>
                            </b-overlay>
                            <a
                              href="/edd-report-types"
                              target="_blank"
                              style="text-decoration: underline"
                              >What is in each report type?</a
                            >
                          </div>
                          <div
                            v-else-if="
                              action.actionType ===
                              thirdPartyActionType.T_CERTIFICATION
                            "
                          >
                            <BaseText
                              :size="typographySize.BODY_TEXT_BOLD"
                              :text="action.btnText"
                            />
                            <Select
                              v-if="!isLoadingOptions"
                              id="t-certification"
                              name="t-certification"
                              :isRequired="action?.isActive"
                              :options="tCertificationOptions"
                              @change="onChangeTCertItems"
                            />
                            <BaseText
                              v-if="isTCertActive(action)"
                              text="Please select any item from list"
                              theme="error-inverse"
                              :size="typographySize.BODY_TEXT_SMALL"
                            />
                          </div>
                          <div
                            v-else-if="
                              action.actionType ===
                              thirdPartyActionType.ESG_METRIC
                            "
                          >
                            <BaseText
                              :size="typographySize.BODY_TEXT_BOLD"
                              :text="action.btnText"
                            />
                            <Select
                              v-if="!isLoadingOptions"
                              id="t-certification"
                              name="t-certification"
                              :isRequired="action?.isActive"
                              :options="ESGMetricsOptions"
                              @change="onChangeEsgmItems"
                            />
                            <BaseText
                              v-if="isEsgmActive(action)"
                              text="Please select any item from list"
                              theme="error-inverse"
                              :size="typographySize.BODY_TEXT_SMALL"
                            />
                          </div>
                          <p v-else>
                            <b class="d-flex">{{ action.btnText }}</b>
                          </p>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
            </b-card>
            <!-- Card Default Actions end  -->

            <!-- Card Available Actions start  -->
            <b-card
              style="border: 0px !important"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <!-- Card Available Actions header-->
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Available Actions</h6>
                  </div>
                  <div style="margin-right: 21px">
                    <a
                      :href="knowledgeCentreLinks.AVAILABLE_ACTIONS"
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                </div>
              </template>

              <!-- Card Available Actions content-->
              <div>
                <b-list-group>
                  <b-list-group-item
                    :disabled="
                      (showingReviewResult && !editModalShow) ||
                      isThirdPartyArchived
                    "
                    @click="onSelectExtraActionOccur(action)"
                    v-for="(action, index) in extraActionsBtn"
                    :key="index"
                    size="sm"
                    :style="{
                      backgroundColor: action.isActive ? '#d0f2d2' : 'white',
                      color: 'black',
                      width: '100%',
                      border: '0px'
                    }"
                    :data-test-id="
                      getActionDataTestId(action.btnText, 'available-action')
                    "
                  >
                    <b-row class="add-third-party-modal__actions">
                      <b-col cols="4">
                        <BaseIcon v-bind="getSelectedIcon(action.isActive)" />
                      </b-col>

                      <b-col cols="8">
                        <div v-if="action.actionType == 'edd'">
                          <div @click="onSelectExtraActionOccurEdd(action)">
                            <b
                              class="d-flex"
                              :style="{
                                color: isThirdPartyArchived ? 'lightgray' : ''
                              }"
                            >
                              {{ action.btnText }}
                            </b>
                          </div>
                          <b-form-group v-if="checkIsEddActive()">
                            <v-select
                              class="input-select"
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Types --"
                              :maxHeight="100"
                              v-model="v$.selecteditem.$model"
                              :state="
                                v$.selecteditem.$dirty
                                  ? !v$.selecteditem.$error
                                  : null
                              "
                              :class="{
                                'form-control is-invalid p-0':
                                  v$.selecteditem.$error
                              }"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                            </v-select>
                            <b-form-invalid-feedback id="ordertype"
                              >There are no selected
                              actions.</b-form-invalid-feedback
                            >
                          </b-form-group>
                          <b-form-group v-else class="w-100">
                            <v-select
                              class="input-select"
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Types --"
                              :maxHeight="100"
                              v-model="selecteditem"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                              <template slot="option" slot-scope="option">
                                <span>{{ "&nbsp;" + option.name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>
                          <b-overlay
                            :show="isLoadingElements || isLoadingPackages"
                          >
                            <b-list-group flush>
                              <b-list-group-item
                                v-for="element in allElements"
                                :key="element.id"
                                style="
                                  display: flex;
                                  padding: 0.35rem 1rem !important;
                                "
                              >
                                <font-awesome-icon
                                  class="m-2"
                                  :icon="
                                    element.package_products_id > 0
                                      ? 'check'
                                      : 'times'
                                  "
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? '#28a745'
                                        : '#CBCFD1'
                                  }"
                                />
                                <p
                                  class="m-0"
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? ''
                                        : '#CBCFD1'
                                  }"
                                >
                                  <span style="font-size: 14px !important">{{
                                    element.product_name
                                  }}</span>
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                            <div
                              class="add-third-party-modal__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <div
                                style="display: flex; flex-direction: column"
                              >
                                <p v-if="isMaximumLimitOvercomedByAssociates">
                                  {{ associatesUsers.length }} associates are
                                  indicated on the third party record.
                                  Additional fees may apply
                                </p>
                              </div>
                            </div>
                            <div
                              class="add-third-party-modal__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <font-awesome-icon class="m-1" icon="clock" />
                              <p
                                v-if="
                                  selectedItemArray.client_packages_tat_max > 0
                                "
                              >
                                {{ selectedItemArray.client_packages_tat }} -
                                {{ selectedItemArray.client_packages_tat_max }}
                                Days Turn Around Time
                              </p>
                              <p v-else>
                                {{ selectedItemArray.client_packages_tat }} Days
                                Turn Around Time
                              </p>
                            </div>
                          </b-overlay>
                          <a
                            href="/edd-report-types"
                            target="_blank"
                            style="text-decoration: underline"
                            >What is in each report type?</a
                          >
                        </div>
                        <div
                          v-else-if="
                            action.actionType ===
                            thirdPartyActionType.T_CERTIFICATION
                          "
                        >
                          <div @click="getActionType(action)">
                            <p
                              :class="
                                isThirdPartyArchived
                                  ? 'add-tp__tcertification-text--archived'
                                  : 'add-tp__tcertification-text--bold'
                              "
                            >
                              {{ action.btnText }}
                            </p>
                          </div>

                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="action?.isActive"
                            :options="tCertificationOptions"
                            @change="onChangeTCertItems"
                          />
                          <BaseText
                            v-if="isTCertActive(action)"
                            text="Please select any item from list"
                            theme="error-inverse"
                            :size="typographySize.BODY_TEXT_SMALL"
                          />
                        </div>
                        <div
                          v-else-if="
                            action.actionType ===
                            thirdPartyActionType.ESG_METRIC
                          "
                        >
                          <div @click="getActionType(action)">
                            <p
                              :class="
                                isThirdPartyArchived
                                  ? 'add-tp__tcertification-text--archived'
                                  : 'add-tp__tcertification-text--bold'
                              "
                            >
                              {{ action.btnText }}
                            </p>
                          </div>

                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="action?.isActive"
                            :options="ESGMetricsOptions"
                            @change="onChangeEsgmItems"
                          />
                          <BaseText
                            v-if="isEsgmActive(action)"
                            text="Please select any item from list"
                            theme="error-inverse"
                            :size="typographySize.BODY_TEXT_SMALL"
                          />
                        </div>
                        <p v-else>
                          <b
                            class="d-flex"
                            :style="{
                              color: isThirdPartyArchived ? 'lightgray' : ''
                            }"
                          >
                            {{ action.btnText }}
                          </b>
                        </p>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-card>
            <!-- Card Available Actions end  -->

            <!-- Card Other actions available (but not subscribed) start  -->
            <b-card
              style="border: 0px"
              class="mb-3"
              no-body
              header-tag="header"
            >
              <!-- Card Other actions available header-->
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Other actions available (but not subscribed)</h6>
                  </div>
                  <div style="margin-right: 21px">
                    <a
                      :href="
                        knowledgeCentreLinks.OTHER_ACTIONS_AVAILABLE_NOT_SUBSCRIBED
                      "
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                </div>
              </template>

              <!-- Card Other actions available content-->
              <div>
                <b-list-group>
                  <b-list-group-item
                    v-for="(action, index) in actionsNotAvailable"
                    :key="index"
                    :data-test-id="
                      getActionDataTestId(action.btnText, 'unavailable-action')
                    "
                  >
                    <div class="add-third-party-modal__actions">
                      <div>
                        <BaseIcon
                          :shape="shapes.SQUARE"
                          :border="border.DASHED"
                          :size="25"
                        />
                      </div>
                      <div style="margin-left: 20px">
                        <b class="d-flex" style="color: lightgray"
                          >{{ action.btnText }}
                        </b>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-card>
            <!-- Card Other actions available (but not subscribed) end  -->
          </b-col>

          <!-- Grid view -->
          <b-col
            :class="
              showCreditsAlert
                ? 'cols-4-with-voucheralert-height-styles'
                : 'cols-4-height-styles'
            "
            v-if="!changeListView"
            cols="4"
          >
            <!-- Card My Risk Rating start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>My Risk Rating</h6>
                  </div>
                  <div style="margin-right: 21px">
                    <a
                      :href="knowledgeCentreLinks.MY_RISK_RATING"
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                  <!-- to change cards grid layout start -->

                  <!-- to change cards grid layout end -->
                </div>
              </template>
              <div>
                <div
                  class="p-2"
                  :style="{
                    backgroundColor:
                      isThirdPartyArchived || isLoadingResultReview
                        ? '#e9ecef'
                        : 'white'
                  }"
                  data-test-id="add-third-party-modal__risk-rating"
                >
                  <b-dropdown
                    :disabled="isLoadingResultReview || isThirdPartyArchived"
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    data-test-id="add-third-party-modal__risk-rating-dropdown"
                  >
                    <template #button-content>
                      <div class="add-third-party-modal__risk-rating">
                        <div>
                          <BaseIcon v-bind="selectedRiskRatingIcon" />
                        </div>
                        <div style="margin-left: 20px">
                          <b class="d-flex">
                            <font-awesome-icon
                              class="text-muted"
                              icon="pen"
                            />&nbsp;Risk Rating -
                            <p>&nbsp;{{ selectedRiskRatingText }}</p>
                          </b>
                        </div>
                      </div>
                    </template>
                    <b-dropdown-item style="width: 400px">
                      <div class="d-flex">
                        <div
                          class="mx-1"
                          v-for="(item, index) in riskRatingJsonData
                            .myRiskRatingData.status"
                          :key="index"
                          @click="selectRiskRatingOption(item)"
                          style="cursor: pointer"
                        >
                          <BaseIcon
                            :icon="item.icon"
                            :shape="item.shape"
                            :theme="item.theme"
                            :size="item.size"
                          />
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div style="margin-left: 90px">
                    <b-form-group>
                      <b-form-textarea
                        v-if="!onChangeRatingStatus"
                        :disabled="isLoadingResultReview"
                        :state="validateRiskRatingComment"
                        placeholder="Please include reasons for changes"
                        @input="inputRiskRatingComment"
                        v-model="riskRatingComment"
                        data-test-id="add-third-party-modal__risk-rating-comment"
                      ></b-form-textarea>
                      <b-form-invalid-feedback id="contact-person-feedback"
                        >This is a Required field and Notes can be of maximum
                        250 characters
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-card>
            <!-- Card My Risk Rating end  -->

            <!-- Card Previously Performed Actions start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Previously Performed Actions</h6>
                  </div>
                  <!-- to change cards grid layout start -->
                  <div class="d-flex" style="margin-right: 21px">
                    <div style="margin-right: 10px; margin-top: 3px">
                      <font-awesome-icon
                        v-if="changeListView"
                        @click.native="changeListView = !changeListView"
                        icon="th-large"
                      />
                      <font-awesome-icon
                        v-else
                        @click.native="changeListView = !changeListView"
                        icon="list"
                      />
                    </div>
                    <div class="ml-auto">
                      <a
                        :href="
                          knowledgeCentreLinks.PREVIOUSLY_PERFORMED_ACTIONS
                        "
                        target="_blank"
                      >
                        <BaseIcon
                          :icon="icons.QUESTION"
                          :shape="shapes.CIRCLE"
                          :size="12"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                        />
                      </a>
                    </div>
                  </div>
                  <!-- to change cards grid layout end -->
                </div>
              </template>
              <div v-if="!editModalShow && !showingReviewResult">
                <p class="p-2" style="color: lightgray; font-size: 15px">
                  No actions have occurred on this Entity
                </p>
              </div>
              <div v-if="showingReviewResult || editModalShow">
                <b-overlay :show="isLoadingResultData" rounded="sm">
                  <b-row
                    v-if="!isLoadingResultData"
                    style="margin-left: 1px; flex-wrap: wrap"
                  >
                    <div
                      style="width: 30%"
                      v-for="(action, index) in completedActions"
                      :key="index"
                    >
                      <b-btn
                        size="sm"
                        :disabled="showingReviewResult || editModalShow"
                        :style="{
                          backgroundColor:
                            action.imgName == 'ok' ||
                            action.imgName == 'RemediatedOK'
                              ? '#d0f2d2'
                              : action.imgName == 'HighAlert' ||
                                action.imgName == 'remediatedHighAlert'
                              ? '#f9d1ce'
                              : action.imgName == 'inprogress'
                              ? '#e3f4fa'
                              : action.imgName == 'WarningAlert'
                              ? '#fcf2d4'
                              : 'white',
                          width: '100%',
                          height: '100%',
                          borderColor: '#eeeeee'
                        }"
                        :data-test-id="
                          getActionDataTestId(
                            action.btnText,
                            'previously-performed-action'
                          )
                        "
                      >
                        <div class="add-third-party-modal__available-actions">
                          <div
                            v-if="isActionTypeTCertOrEsgm(action)"
                            class="add-tp__previously-performed-actions-completed"
                          >
                            Completed
                          </div>
                          <BaseIcon
                            v-else
                            v-bind="getIcon(action.imgName, iconSizes.LARGE)"
                          />
                          <br />
                          <b class="m-1" style="color: black">{{
                            action.gridBtntext
                          }}</b>
                        </div>
                      </b-btn>
                    </div>
                    <div
                      style="width: 30%"
                      v-for="(action, index) in removedActions"
                      :key="'A' + index"
                    >
                      <b-btn
                        size="sm"
                        :disabled="showingReviewResult || editModalShow"
                        :style="{
                          backgroundColor:
                            action.imgName == 'ok' ||
                            action.imgName == 'RemediatedOK'
                              ? '#d0f2d2'
                              : action.imgName == 'HighAlert' ||
                                action.imgName == 'remediatedHighAlert'
                              ? '#f9d1ce'
                              : action.imgName == 'inprogress'
                              ? '#e3f4fa'
                              : action.imgName == 'WarningAlert'
                              ? '#fcf2d4'
                              : 'white',
                          width: '100%',
                          height: '100%',
                          borderColor: '#eeeeee'
                        }"
                        :data-test-id="
                          getActionDataTestId(
                            action.btnText,
                            'previously-performed-action'
                          )
                        "
                      >
                        <div class="add-third-party-modal__available-actions">
                          <BaseIcon
                            v-bind="getIcon(action.imgName, iconSizes.LARGE)"
                          />
                          <br />
                          <b class="m-1" style="color: black">{{
                            action.gridBtntext
                          }}</b>
                        </div>
                      </b-btn>
                    </div>
                  </b-row>
                </b-overlay>
              </div>
            </b-card>
            <!-- Card Previously Performed Actions end  -->

            <!-- Card Default Actions start  -->
            <b-card
              style="border: 0px"
              no-body
              header-tag="header"
              class="mb-3"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Default Actions</h6>
                  </div>
                  <div class="d-flex">
                    <div style="margin-right: 21px">
                      <a
                        :href="knowledgeCentreLinks.DEFAULT_ACTIONS"
                        target="_blank"
                      >
                        <BaseIcon
                          :icon="icons.QUESTION"
                          :shape="shapes.CIRCLE"
                          :size="12"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </template>
              <div>
                <div v-if="editModalShow">
                  <p class="p-2" style="color: lightgray; font-size: 15px">
                    No actions
                  </p>
                </div>
                <div v-else>
                  <b-row style="margin-left: 1px; flex-wrap: wrap">
                    <div
                      v-for="(action, index) in actionsOccurBtns"
                      :key="index"
                      style="width: 30%"
                    >
                      <b-btn
                        @click="getActionType(action)"
                        size="sm"
                        style="
                          width: 100%;
                          background-color: #d0f2d2;
                          height: 100%;
                          border-color: #eeeeee;
                        "
                        :data-test-id="
                          getActionDataTestId(action.btnText, 'default-action')
                        "
                      >
                        <div class="add-third-party-modal__available-actions">
                          <BaseIcon
                            :icon="icons.PLUS"
                            :shape="shapes.SQUARE"
                            :theme="themes.SUCCESS_WHITE"
                            :size="25"
                          />
                          <br />
                          <b
                            :id="getPopoverType(action)"
                            class="m-1"
                            style="color: black"
                            >{{ action.gridBtntext }}</b
                          >
                          <b
                            class="m-1"
                            style="color: black"
                            v-if="action.actionType == 'edd' && selecteditem"
                            >[{{ selecteditem }}]</b
                          >
                          <BaseText
                            v-else-if="isTCertItemSelected(action)"
                            :text="`[${selectedTCertItem.text}]`"
                            :size="typographySize.BODY_TEXT_BOLD"
                          />
                          <BaseText
                            v-else-if="isEsgmItemSelected(action)"
                            :text="`[${selectedEsgmItem.text}]`"
                            :size="typographySize.BODY_TEXT_BOLD"
                          />
                        </div>
                      </b-btn>
                    </div>
                  </b-row>
                </div>
              </div>
            </b-card>
            <!-- Card Default Actions end  -->

            <!-- Card Available Actions start  -->
            <b-card
              style="border: 0px !important"
              class="mb-3"
              no-body
              header-tag="header"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Available Actions</h6>
                  </div>
                  <div class="d-flex" style="margin-right: 21px">
                    <a
                      :href="knowledgeCentreLinks.AVAILABLE_ACTIONS"
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                </div>
              </template>
              <div>
                <b-row style="margin-left: 1px; flex-wrap: wrap">
                  <div
                    style="width: 30%"
                    v-for="(action, index) in extraActionsBtn"
                    :key="index"
                  >
                    <b-btn
                      @click="getActionToBePerformed(action)"
                      size="sm"
                      :disabled="
                        (showingReviewResult && !editModalShow) ||
                        isThirdPartyArchived
                      "
                      :style="{
                        width: '100%',
                        backgroundColor: action.isActive ? '#d0f2d2' : 'white',
                        height: '100%',
                        borderColor: '#eeeeee'
                      }"
                      :data-test-id="
                        getActionDataTestId(action.btnText, 'available-action')
                      "
                    >
                      <div class="add-third-party-modal__available-actions">
                        <BaseIcon v-bind="getSelectedIcon(action.isActive)" />
                        <br />
                        <b-popover
                          :show="showDropDownModal"
                          target="popover-target-edd"
                          triggers="click"
                          placement="auto"
                        >
                          <template #title>
                            <b-button
                              @click="showDropDownModal = false"
                              class="close"
                              aria-label="Close"
                            >
                              <font-awesome-icon
                                class="d-inline-block"
                                aria-hidden="true"
                                icon="times-circle"
                              />
                            </b-button>
                            Set Report Type
                          </template>
                          <b-form-group v-if="checkIsEddActive()">
                            <v-select
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Type --"
                              :maxHeight="100"
                              v-model="v$.selecteditem.$model"
                              :state="
                                v$.selecteditem.$dirty
                                  ? !v$.selecteditem.$error
                                  : null
                              "
                              :class="{
                                'form-control is-invalid p-0':
                                  v$.selecteditem.$error
                              }"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                            </v-select>
                            <b-form-invalid-feedback id="ordertype"
                              >There are no selected
                              actions.</b-form-invalid-feedback
                            >
                          </b-form-group>
                          <b-form-group v-else class="w-100">
                            <v-select
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Type --"
                              :maxHeight="100"
                              v-model="selecteditem"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                              <template slot="option" slot-scope="option">
                                <span>{{ "&nbsp;" + option.name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>

                          <b-overlay
                            :show="isLoadingElements || isLoadingPackages"
                          >
                            <b-list-group
                              style="height: 368px; overflow: auto"
                              flush
                            >
                              <b-list-group-item
                                v-for="element in allElements"
                                :key="element.id"
                                style="
                                  display: flex;
                                  padding: 0.35rem 1rem !important;
                                "
                              >
                                <font-awesome-icon
                                  class="m-2"
                                  :icon="
                                    element.package_products_id > 0
                                      ? 'check'
                                      : 'times'
                                  "
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? '#28a745'
                                        : '#CBCFD1'
                                  }"
                                />
                                <p
                                  class="m-0"
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? ''
                                        : '#CBCFD1'
                                  }"
                                >
                                  <span style="font-size: 14px !important">{{
                                    element.product_name
                                  }}</span>
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                            <div
                              class="add-third-party-modal__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <div
                                style="display: flex; flex-direction: column"
                              >
                                <p v-if="isMaximumLimitOvercomedByAssociates">
                                  {{ associatesUsers.length }} associates are
                                  indicated on the third party record.
                                  Additional fees may apply
                                </p>
                              </div>
                            </div>
                            <div
                              class="add-third-party-modal__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <font-awesome-icon class="m-1" icon="clock" />
                              <p
                                v-if="
                                  selectedItemArray.client_packages_tat_max > 0
                                "
                              >
                                {{ selectedItemArray.client_packages_tat }} -
                                {{ selectedItemArray.client_packages_tat_max }}
                                Days Turn Around Time
                              </p>
                              <p v-else>
                                {{ selectedItemArray.client_packages_tat }} Days
                                Turn Around Time
                              </p>
                            </div>
                          </b-overlay>
                          <a
                            href="/edd-report-types"
                            target="_blank"
                            style="text-decoration: underline"
                            >What is in each report type?</a
                          >
                        </b-popover>
                        <b-popover
                          :show="showDropDownModalForTCert"
                          target="popover-target-tcert"
                          triggers="click"
                          placement="auto"
                        >
                          <template #title>
                            <b-button
                              @click="showDropDownModalForTCert = false"
                              class="close"
                              aria-label="Close"
                            >
                              <font-awesome-icon
                                class="d-inline-block"
                                aria-hidden="true"
                                icon="times-circle"
                              />
                            </b-button>
                          </template>
                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="
                              isThirdPartyActionActive(
                                thirdPartyActionType.T_CERTIFICATION
                              )
                            "
                            :options="tCertificationOptions"
                            @change="onChangeTCertItems"
                          />
                        </b-popover>
                        <b-popover
                          :show="showDropDownModalForEsgm"
                          target="popover-target-esgm"
                          triggers="click"
                          placement="auto"
                        >
                          <template #title>
                            <b-button
                              @click="showDropDownModalForEsgm = false"
                              class="close"
                              aria-label="Close"
                            >
                              <font-awesome-icon
                                class="d-inline-block"
                                aria-hidden="true"
                                icon="times-circle"
                              />
                            </b-button>
                          </template>
                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="
                              isThirdPartyActionActive(
                                thirdPartyActionType.ESG_METRIC
                              )
                            "
                            :options="ESGMetricsOptions"
                            @change="onChangeEsgmItems"
                          />
                        </b-popover>
                        <b
                          class="m-1"
                          :id="getPopoverType(action)"
                          :style="{
                            color: isThirdPartyArchived ? 'lightgray' : 'black'
                          }"
                        >
                          {{ action.gridBtntext }}</b
                        >
                        <b
                          class="m-1"
                          style="color: black"
                          v-if="action.actionType == 'edd' && selecteditem"
                          >[{{ selecteditem }}]</b
                        >
                        <BaseText
                          v-else-if="isTCertItemSelected(action)"
                          :text="`[${selectedTCertItem.text}]`"
                          :size="typographySize.BODY_TEXT_BOLD"
                        />
                        <BaseText
                          v-else-if="isEsgmItemSelected(action)"
                          :text="`[${selectedEsgmItem.text}]`"
                          :size="typographySize.BODY_TEXT_BOLD"
                        />
                      </div>
                    </b-btn>
                  </div>
                </b-row>
              </div>
            </b-card>
            <!-- Card Available Actions end  -->

            <!-- Card Other actions available (but not subscribed) start  -->
            <b-card
              style="border: 0px"
              class="mb-3"
              no-body
              header-tag="header"
            >
              <template #header>
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <h6>Other actions available (but not subscribed)</h6>
                  </div>
                  <div style="margin-right: 21px">
                    <a
                      :href="
                        knowledgeCentreLinks.OTHER_ACTIONS_AVAILABLE_NOT_SUBSCRIBED
                      "
                      target="_blank"
                    >
                      <BaseIcon
                        :icon="icons.QUESTION"
                        :shape="shapes.CIRCLE"
                        :size="12"
                        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                      />
                    </a>
                  </div>
                </div>
              </template>
              <b-row>
                <b-col
                  v-for="(action, index) in actionsNotAvailable"
                  :key="index"
                  cols="4"
                >
                  <b-btn
                    size="sm"
                    variant="light"
                    class="bg-white"
                    style="width: 100%; height: 100%"
                    :data-test-id="
                      getActionDataTestId(action.btnText, 'unavailable-action')
                    "
                  >
                    <div class="add-third-party-modal__available-actions">
                      <BaseIcon
                        :shape="shapes.SQUARE"
                        :border="border.DASHED"
                        :size="25"
                      />
                      <br />
                      <b class="m-1" style="color: lightgray">{{
                        action.gridBtntext
                      }}</b>
                    </div>
                  </b-btn>
                </b-col>
              </b-row>
            </b-card>
            <!-- Card Other actions available (but not subscribed) end  -->
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          @click="closeModal"
          class="mx-1"
          size="md"
          variant="outline-danger"
          :disabled="isLoadingResultReview"
          data-test-id="add-third-party-modal__action-button--cancel"
          >Close</b-button
        >
        <b-button
          class="mx-1"
          @click="checkEmailExists"
          size="md"
          variant="outline-success"
          :disabled="isSubmitButtonDisabled"
          :data-test-id="getActionButtonDataTestId()"
        >
          {{ editModalShow ? "Update" : "Submit" }}
        </b-button>
      </template>
    </b-modal>
    <!--add/edit modal add/edit third party component-->

    <!--switch third party modal-->
    <b-modal
      ref="modal-switch-tp"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="navigateToThirdPartyProfile()"
      @cancel="hideSwitchModal"
    >
      <p class="my-4">Are you sure you want to switch to this Third Party?</p>
    </b-modal>
    <b-modal
      ref="modal-rerun-screening"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-title>
        <h6>Updates require following actions to be re-run</h6>
      </template>
      <b-overlay :show="isLoadingResultReview" rounded="sm">
        <b-list-group>
          <h6>Please unselect the actions that you don't want to re-run</h6>
          <b-list-group-item
            @click="onSelectReScreeningActionOccur(index, action)"
            v-for="(action, index) in reScreeningActions"
            :key="index"
            size="sm"
            :style="{
              backgroundColor: action.isActive ? '#d0f2d2' : 'white',
              color: 'black',
              width: '100%',
              border: '0px'
            }"
          >
            <b-row style="display: flex; align-items: baseline">
              <b-col cols="3">
                <BaseIcon
                  v-if="isThirdPartyArchived"
                  :shape="shapes.SQUARE"
                  :border="border.DASHED"
                  :size="25"
                />
                <CallToAction
                  v-else
                  type="button"
                  :icon="icons.PLUS"
                  :icon-size="35"
                  :icon-space="space.NONE"
                  :theme="selectedActionTheme(action.isActive)"
                  :has-icon-with-text="true"
                  @click="
                    action.actionType == 'edd'
                      ? onSelectReScreeningActionOccurEdd(index, action)
                      : null
                  "
                />
              </b-col>

              <b-col cols="7">
                <div v-if="action.actionType == 'edd'">
                  <p @click="onSelectReScreeningActionOccurEdd(index, action)">
                    <b
                      class="d-flex"
                      :style="{
                        color: isThirdPartyArchived ? 'lightgray' : ''
                      }"
                    >
                      {{ action.btnText }}
                    </b>
                  </p>
                  <b-form-group>
                    <v-select
                      class="input-select"
                      :disabled="isThirdPartyArchived"
                      placeholder="-- EDD Order Types --"
                      :maxHeight="100"
                      v-model="selecteditem"
                      label="name"
                      track-by="name"
                      :options="listOfEDD"
                      @update:modelValue="setSelectedItem($event)"
                      :clearable="false"
                    >
                      <template slot="option" slot-scope="option">
                        <span>{{ "&nbsp;" + option.name }}</span>
                      </template>
                    </v-select>
                    <div class="errorClass" v-if="checkRerunEddActive()">
                      There are no selected actions.
                    </div>
                  </b-form-group>
                  <b-overlay :show="isLoadingElements || isLoadingPackages">
                    <b-list-group flush v-if="selecteditem">
                      <b-list-group-item
                        v-for="element in allElements"
                        :key="element.id"
                        style="display: flex; padding: 0.35rem 1rem !important"
                      >
                        <font-awesome-icon
                          class="m-2"
                          :icon="
                            element.package_products_id > 0 ? 'check' : 'times'
                          "
                          :style="{
                            color:
                              element.package_products_id > 0
                                ? '#28a745'
                                : '#CBCFD1'
                          }"
                        />
                        <p
                          class="m-0"
                          :style="{
                            color:
                              element.package_products_id > 0 ? '' : '#CBCFD1'
                          }"
                        >
                          <span style="font-size: 14px !important">{{
                            element.product_name
                          }}</span>
                        </p>
                      </b-list-group-item>
                    </b-list-group>
                    <div
                      class="add-third-party-modal__actions mb-1"
                      v-if="
                        parseInt(selectedItemArray.client_packages_tat) > 0 &&
                        selecteditem
                      "
                    >
                      <div style="display: flex; flex-direction: column">
                        <p v-if="isMaximumLimitOvercomedByAssociates">
                          {{ associatesUsers.length }} associates are indicated
                          on the third party record. Additional fees may apply
                        </p>
                      </div>
                    </div>
                    <div
                      class="add-third-party-modal__actions mb-1"
                      v-if="
                        parseInt(selectedItemArray.client_packages_tat) > 0 &&
                        selecteditem
                      "
                    >
                      <font-awesome-icon class="m-1" icon="clock" />
                      <p v-if="selectedItemArray.client_packages_tat_max > 0">
                        {{ selectedItemArray.client_packages_tat }} -
                        {{ selectedItemArray.client_packages_tat_max }} Days
                        Turn Around Time
                      </p>
                      <p v-else>
                        {{ selectedItemArray.client_packages_tat }} Days Turn
                        Around Time
                      </p>
                    </div>
                  </b-overlay>
                  <a
                    href="/edd-report-types"
                    target="_blank"
                    style="text-decoration: underline"
                    v-if="selecteditem"
                    >What is in each report type?</a
                  >
                </div>
                <p v-else>
                  <b
                    class="d-flex"
                    :style="{
                      color: isThirdPartyArchived ? 'lightgray' : ''
                    }"
                  >
                    {{ action.btnText }}
                  </b>
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          class="mx-1"
          @click="updateThirdParty"
          size="md"
          variant="outline-success"
          >Submit</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import uid from "uid";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  getIcon,
  getRiskRatingIcon,
  HTTP,
  makeAuthorizationHeader
} from "@/utils";
import {
  attributeType,
  border,
  colors,
  eddContracts,
  emptyOption,
  getterName,
  icons,
  iconSizes,
  defaultIcons,
  knowledgeCentreLinks,
  mutationName,
  orbiaSpecificConstants,
  permissionTypes,
  posthogEvents,
  shapes,
  space,
  themes,
  thirdPartyActionImageType,
  thirdPartyActionProductStatus,
  thirdPartyActionType,
  thirdPartyFormLanguage,
  infoMessages,
  thirdPartyType,
  typographySize,
  countriesWithPrefix,
  urls,
  userMember
} from "@/constants";
import defaultActionJson from "@/utils/defaultAction.json";
import iconJson from "@/utils/icon.json";
import { EventBus } from "@/event-bus.js";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import ReviewResult from "./reviewResult.vue";
import ThirdPartyCustomAttributes from "@/organisms/ThirdPartyCustomAttributes/ThirdPartyCustomAttributes";
import Select from "@/molecules/Select/Select";
import BaseText from "@/atoms/BaseText/BaseText";
import permissionsMixin from "@/mixins/permissions/permissions.mixin";
import thirdPartyMethodsMixin from "@/mixins/thirdParty/thirdPartyMethods.mixin";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import { Helper } from "@/helpers";
import { makeMultiSelectCountriesCustomAttributeAPIDTO } from "@/organisms/CustomAttribute/CustomAttributes.dto";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "AddThirdPartyModalNew",
  mixins: [permissionsMixin, thirdPartyMethodsMixin],
  inject: ["$posthog"],
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Tooltip,
    ReviewResult,
    ThirdPartyCustomAttributes,
    Select,
    BaseText,
    BaseIcon,
    BaseInfoBox,
    CallToAction,
    Checkbox,
    ErrorPageTemplate
  },
  data() {
    return {
      knowledgeCentreLinks,
      themes,
      icons,
      defaultIcons,
      shapes,
      border,
      space,
      iconSizes,
      infoMessages,
      userTyping: false,
      riskRatingJsonData: iconJson,
      riskRatingComment: null,
      validateRiskRatingComment: null,
      riskRatingNotes: null,
      selectedRiskRatingText: null,
      selectedRiskRatingIcon: {
        icon: "",
        shape: shapes.SQUARE,
        theme: themes.NONE,
        size: 25
      },
      riskRatingValue: 0,
      onEditRiskRatingValue: 0,
      onChangeRatingStatus: true,
      isFocusUrl: false,
      showCreditsAlert: false,
      showingReviewResult: false,
      isLoadingResultReview: false,
      resultsFetchDisable: false,
      reportId: null,
      searchId: null,
      encryptedProductId: null,
      associatedCurrentPage: 1,
      associatedSortDesc: false,
      associatedSortBy: "id",
      openCorporatesCompanyOfficersFields: [
        { key: "name", label: "Entity Name" },
        { key: "position", label: "Role" },
        { key: "select", label: "Select" }
      ],
      openCorporatesCompanyOfficersItems: [],
      showingAssociatesTable: false,
      isLoadingAssociatesTable: false,
      isAssoLoading: false,
      selectedCompanyOfficers: [],
      associatesUsers: [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ],
      isOpenCorporatesDataFound: false,
      isOpenCorporatesDataSelected: false,
      datasetThirdPartyName: null,
      ocCompanySelectedCountry: null,
      searchKey: null,
      icompany: {},
      thirdpartyid: null,
      submiting: null,
      isLoading: false,
      responseSuccess: false,
      selectedIndividualRowCountry: null,
      selectedOpenCorporateCompany: null,
      isShowingOpenCorporateData: false,
      fromRowsOtherCountries: 0,
      toRowsOtherCountries: 0,
      totalRowsOtherCountries: 0,
      totalPaginationRowsOtherCountries: 0,
      filterOtherCountries: null,
      perPageOtherCountries: 5,
      currentPageOtherCountries: 1,
      sortByOtherCountries: "id",
      sortDescOtherCountries: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      fromRows: 0,
      toRows: 0,
      totalRows: 0,
      totalPaginationRows: 0,
      filter: null,
      perPage: 5,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      tableWithoutCountryItems: [],
      isLoadingForOthers: false,
      tableWithCountryItems: [],
      isLoadingCountryData: false,
      openCorporateTableFields: [
        { key: "name", label: "Registered Company Name" },
        { key: "registered_country", label: "Registered Country" },
        { key: "registration_status", label: "Registration Status" },
        { key: "select", label: "Select" }
      ],
      openCorporateIndividualFields: [
        { key: "name", label: "Company" },
        { key: "registered_country", label: "Registered In" },
        { key: "registration_status", label: "Postion" },
        { key: "select", label: "Select" }
      ],
      selectedRowWithoutCountry: null,
      selectedRowWithCountry: null,
      encryptedProdId: null,
      encryptedSearchId: null,
      entityTypeSelect: "O",
      changeListView: true,
      verificationTableCollapse: false,
      verificationTableCollapseTwo: false,
      showModal: false,
      editModalShow: false,
      item: [
        {
          name: "Coperate Fiction",
          country: "Romania",
          status: "Active"
        },
        {
          name: "Coperate",
          country: "Uk",
          status: "In-Active"
        }
      ],
      actionsOccurBtns: [],
      extraActionsBtn: [],
      actionsNotAvailable: [],
      completedActions: [],
      actionsTaken: [],
      selected: [],
      optionsTypes: [
        { value: "O", text: "Company" },
        { value: "P", text: "Individual" }
      ],
      hideSubmitButton: false,
      form: {
        country: null,
        entityName: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null
      },
      additionalDetailForm: {
        address: null,
        city: null,
        state: null,
        postal_code: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        contactLanguage: thirdPartyFormLanguage.ENGLISH,
        contactLanguageCode: null
      },
      systemOwnerId: null,
      selectedSystemOwner: { value: null, text: null },
      isLoadingResultData: false,
      tpDnbData: {},
      searchReportData: {},
      tpDnbSelectedAltaresTableItemData: null,
      beneficialOwnershipRegistrationData: {},
      showNoteText: false,
      actionTakenData: [],
      searchTpiId: 0,
      isLoadingForIndividual: false,
      fromRowsIndividual: 0,
      toRowsIndividual: 0,
      totalRowsIndividual: 0,
      verificationTableCollapseThree: false,
      sortByIndividual: "id",
      sortDescIndividual: true,
      perPageIndividual: 5,
      currentPageIndividual: 1,
      filterIndividual: null,
      tableWithIndividualItems: [],
      invalidEmailDomain: false,
      invalidEmailDomainValue: null,
      selectedCountryName: null,
      isLoadingData: false,
      invalidUrlDomain: false,
      invalidUrlDomainValue: null,
      allElements: [],
      isLoadingElements: false,
      isLoadingPackages: false,
      selecteditem: null,
      selectedItemArray: [],
      showDropDownModal: false,
      selectedId: null,
      companyDetail: {},
      collapse: {
        EntityVerification: true,
        AssociateEntities: true,
        AdditionalDetails: true,
        PreviouslySavedEntites: true,
        CustomAttributes: true
      },
      disableUpdateBtn: true,
      isLoadingPreviouslySavedEntites: false,
      previouslySavedEntitesFields: [
        { key: "comp_name_eng", label: "Registered Company Name" },
        { key: "country", label: "Registered Country" },
        { key: "select", label: "Select" }
      ],
      existingItems: [],
      fromRowsExistingItems: 0,
      toRowsExistingItems: 0,
      totalRowsExistingItems: 0,
      perPageExistingItems: 5,
      currentPageExistingItems: 1,
      filterExistingItems: null,
      showExistingTable: false,
      thirdPartyID: null,
      selectedThirdParty: null,
      totalPaginationIndividual: 0,
      customAttributesValues: {},
      customAttributesValuesData: {},
      reScreeningActions: [],
      isChangeInAssociates: false,
      reScreeningCount: 0,
      isChangeAssociateUpdate: false,
      editTpName: null,
      removedActions: [],
      orignalAssociatesUsers: [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ],
      tCertificationOptions: [],
      showDropDownModalForTCert: false,
      selectedTCertItem: this.makeOptionObj(),
      thirdPartyActionType,
      isLoadingOptions: false,
      typographySize,
      showErrorMessage: false,
      ESGMetricsOptions: [],
      selectedEsgmItem: this.makeOptionObj(),
      showDropDownModalForEsgm: false,
      showErrorMessageForEsgm: false,
      getIcon,
      getRiskRatingIcon,
      dynamicCustomAttributesList: [],
      orbiaSelectionAttributeIndex: null,
      orbiaSelectionAttributeId: null,
      orbiaAffectedAttributeIds: [],
      orbiaOptionsValues: [
        orbiaSpecificConstants.OPTIONS_VALUES.CUSTOMER_END_USER,
        orbiaSpecificConstants.OPTIONS_VALUES.EXCEPTIONS
      ]
    };
  },
  computed: {
    ...mapState({
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      thirdPartySelectedId: (state) => state.thirdParty.thirdPartySelectedId
    }),
    ...mapGetters({
      isOpenCorporateDataEnabled:
        getterName.COMPANY.IS_OPEN_CORPORATE_DATA_ENABLED,
      isOrbiaOnboardingFormEnabled:
        getterName.COMPANY.IS_ORBIA_ONBOARDING_FORM_ENABLED
    }),
    isSubmitButtonDisabled() {
      return (
        this.isLoadingData ||
        this.isThirdPartyArchived ||
        this.isLoadingResultReview ||
        this.isErrorPresent
      );
    },
    errorPageOptionsForModal() {
      return {
        ...this.errorPageOptions,
        CTAOptions: {
          onClick: () => this.closeModal()
        }
      };
    },
    makeNationalMatchesText() {
      const isOrAre = this.totalRows === 1 ? "is" : "are";
      const matchOrMatches = this.totalRows === 1 ? "match" : "matches";
      return `There ${isOrAre} ${this.totalRows} ${matchOrMatches} against this entity name in ${this.makeCountryWithPrefix}`;
    },
    makeGlobalMatchesText() {
      const isOrAre = this.totalRowsOtherCountries === 1 ? "is" : "are";
      const matchOrMatches =
        this.totalRowsOtherCountries === 1 ? "match" : "matches";
      return `There ${isOrAre} ${this.totalRowsOtherCountries} ${matchOrMatches} against this entity name globally`;
    },
    hasNoMatches() {
      return (
        this.totalRows === 0 ||
        (this.totalRows === 0 && this.totalRowsOtherCountries === 0)
      );
    },
    makeCountryWithPrefix() {
      const countryName = this.form?.country?.name || "";
      return countriesWithPrefix.includes(countryName)
        ? `The ${countryName}`
        : countryName;
    },
    hasOrbiaCustomFunctionality() {
      return (
        this.isOpenCorporateDataEnabled && this.isOrbiaOnboardingFormEnabled
      );
    },
    isMaximumLimitOvercomedByAssociates() {
      return (
        this.associatesUsers.length > this.headerConfigData?.maximumAssociate
      );
    },
    getSystemOwnerListOptions() {
      // Filter out any super admins except if system owner
      const filteredPlatformUsers = this.platformUserList.filter((user) => {
        if (
          parseInt(user?.memberGroupId) === userMember.SUPER_ADMIN &&
          user?.memberId !== this.systemOwnerId
        ) {
          return false;
        }
        return true;
      });
      // Map values and append (Ethixbase) if system owner is ethixbase super admin
      const mappedFilteredPlatformUsers = filteredPlatformUsers.map(
        ({ memberGroupId, memberId, name }) => ({
          text:
            parseInt(memberGroupId) === userMember.SUPER_ADMIN
              ? `${name} (Ethixbase)`
              : name,
          value: memberId
        })
      );

      return [emptyOption, ...mappedFilteredPlatformUsers];
    }
  },
  validations() {
    let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
    let occurActions = this.actionsOccurBtns;
    let apriFilter = [];
    apriFilter.push(...actionsExtra);
    apriFilter.push(...occurActions);
    let esgFilterIndex = apriFilter.findIndex((btn) => btn.actionType == "esg");
    let msqFilterIndex = apriFilter.findIndex(
      (btn) =>
        btn.actionType === thirdPartyActionType.MSQ ||
        btn.actionType === thirdPartyActionType.T_CERTIFICATION ||
        btn.actionType === thirdPartyActionType.ESG_METRIC
    );
    let eddFilterIndex = apriFilter.findIndex((btn) => btn.actionType == "edd");

    if (this.entityTypeSelect == "O") {
      if (this.isContactNameAndEmailRequired) {
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      }
      // if entity type is comapany
      else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq, esg and edd is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq is active and ESG & edd is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq and edd is not active and esg is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & ESG is not active and edd is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & ESG is active and edd is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & edd is active and ESG is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq is not active and ESG and EDD is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else {
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      }
    } else if (this.entityTypeSelect == "P") {
      if (this.isContactNameAndEmailRequired) {
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      }
      // if entity type is individual
      else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq, ESG and edd is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq is active and ESG & edd is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & edd is not active and ESG is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & ESG is not active and edd is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & ESG is active and edd is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & EDD is active and ESG is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if EDD & ESG is active and any msq is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else {
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      }
    }
  },
  async created() {
    await this.initializeData(this.thirdPartySelectedId);

    const orbiaSelectionAttributeValue = this.findSelectedOptionValue(
      this.customAttributesValues?.[this.orbiaSelectionAttributeId]?.value
    );

    if (
      this.hasOrbiaCustomFunctionality &&
      orbiaSelectionAttributeValue &&
      !this.orbiaOptionsValues.includes(orbiaSelectionAttributeValue)
    ) {
      this.applyOrbiaSpecificRequiredValidation();
    }
    EventBus.on("saveValue", (data) => {
      let { value, id, error, type } = data;

      if (
        this.hasOrbiaCustomFunctionality &&
        id === this.orbiaSelectionAttributeId
      ) {
        const selectedOptionValue = this.findSelectedOptionValue(value);

        if (!this.orbiaOptionsValues.includes(selectedOptionValue)) {
          this.applyOrbiaSpecificRequiredValidation();
          this.setOrbiaCustomAttributeValidation(true);
        } else {
          this.setDefaultCustomAttributes();
          this.setOrbiaCustomAttributeValidation(false);
        }
      }

      if (type === attributeType.MULTISELECT_COUNTRIES) {
        const selectedCountries = this.countries.filter((obj) =>
          value.split(",").map(Number).includes(obj.id)
        );

        value = JSON.stringify(
          makeMultiSelectCountriesCustomAttributeAPIDTO(selectedCountries)
        );
      }
      this.customAttributesValues[id] = {
        value,
        id,
        error,
        type
      };
      this.customAttributesValuesData[`customatt_${id}`] = value;
      if (error) {
        this.disableUpdateBtn = false;
      } else {
        this.disableUpdateBtn = true;
      }
    });

    this.$posthog.capture(posthogEvents.PAGEVIEW_MODAL, {
      page: "add-third-party"
    });
  },
  methods: {
    ...mapMutations({
      setIsThirdPartyNewModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_NEW_MODAL_VISIBLE,
      setIsAddThirdPartyModalNewEditable:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_NEW_MODAL_EDITABLE,
      setThirdPartySelectedId:
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_SELECTED_ID,
      setThirdPartyDetailsMessage:
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS_MESSAGE,
      resetThirdPartyDetails:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_DETAILS,
      setIsThirdPartyAddModalVisible:
        mutationName.THIRD_PARTY.SET_IS_THIRD_PARTY_ADD_MODAL_VISIBLE
    }),
    setOrbiaCustomAttributeValidation(checkForValidation = false) {
      this.orbiaAffectedAttributeIds.forEach((id) => {
        this.customAttributesValues[id].error = checkForValidation
          ? !this.customAttributesValues[id].value
          : false;
      });
    },
    findSelectedOptionValue(value) {
      return this.dynamicCustomAttributesList?.[
        this.orbiaSelectionAttributeIndex
      ]?.tp_customatt_values?.find(
        (attributeValue) => attributeValue?.id === parseInt(value)
      )?.value;
    },
    applyOrbiaSpecificRequiredValidation() {
      this.dynamicCustomAttributesList = this.dynamicCustomAttributesList?.map(
        (attribute) => {
          if (this.orbiaAffectedAttributeIds?.includes(attribute?.id)) {
            return {
              ...attribute,
              tp_is_required: "1"
            };
          }
          return attribute;
        }
      );
    },
    onChangeSystemOwner(value) {
      this.selectedSystemOwner = value;
    },
    setSelectedSystemOwner(systemOwnerId) {
      const systemOwner = this.getSystemOwnerListOptions.find(
        ({ value }) => Number.parseInt(value) === Number.parseInt(systemOwnerId)
      ) || { text: null, value: null };
      this.selectedSystemOwner = systemOwner;
    },

    isActionTypeTCertOrEsgm(action) {
      return (
        (action.actionType === thirdPartyActionType.T_CERTIFICATION ||
          action.actionType === thirdPartyActionType.ESG_METRIC) &&
        !action.imgName
      );
    },
    getActionType(action) {
      if (action.actionType === thirdPartyActionType.EDD) {
        this.onSelectExtraActionOccurEdd(action);
      } else if (action.actionType === thirdPartyActionType.T_CERTIFICATION) {
        this.onSelectExtraActionOccurTCert(action);
      } else if (action.actionType === thirdPartyActionType.ESG_METRIC) {
        this.onSelectExtraActionOccurEsgm(action);
      }
    },
    getActionToBePerformed(action) {
      if (action.actionType === thirdPartyActionType.EDD) {
        this.onSelectExtraActionOccurEdd(action);
      } else if (action.actionType === thirdPartyActionType.T_CERTIFICATION) {
        this.onSelectExtraActionOccurTCert(action);
      } else if (action.actionType === thirdPartyActionType.ESG_METRIC) {
        this.onSelectExtraActionOccurEsgm(action);
      } else {
        this.onSelectExtraActionOccur(action);
      }
    },
    isTCertItemSelected(action) {
      return (
        action.actionType === thirdPartyActionType.T_CERTIFICATION &&
        this.selectedTCertItem?.value
      );
    },
    isEsgmItemSelected(action) {
      return (
        action.actionType === thirdPartyActionType.ESG_METRIC &&
        this.selectedEsgmItem?.value
      );
    },
    isTCertActive(action) {
      return (
        action.isActive &&
        !this.selectedTCertItem?.value &&
        this.showErrorMessage
      );
    },
    isEsgmActive(action) {
      return (
        action.isActive &&
        !this.selectedEsgmItem?.value &&
        this.showErrorMessageForEsgm
      );
    },
    onChangeTCertItems(val) {
      this.tCertificationOptions.forEach((tCertificationOption) => {
        if (parseInt(tCertificationOption.value) === parseInt(val)) {
          tCertificationOption.selected = true;
          this.selectedTCertItem = tCertificationOption;
        } else {
          delete tCertificationOption.selected;
        }
      });

      const selectedValueIndex = this.tCertificationOptions.findIndex(
        ({ value }) => parseInt(value) === parseInt(val)
      );
      if (selectedValueIndex === -1) {
        this.selectedTCertItem = this.makeOptionObj();
      }
    },
    onChangeEsgmItems(val) {
      this.ESGMetricsOptions.forEach((esgMetricsOption) => {
        if (parseInt(esgMetricsOption.value) === parseInt(val)) {
          esgMetricsOption.selected = true;
          this.selectedEsgmItem = esgMetricsOption;
        } else {
          delete esgMetricsOption.selected;
        }
      });

      const selectedValueIndex = this.ESGMetricsOptions.findIndex(
        ({ value }) => parseInt(value) === parseInt(val)
      );
      if (selectedValueIndex === -1) {
        this.selectedEsgmItem = this.makeOptionObj();
      }
    },
    getActionDataTestId(actionName, type) {
      return `add-third-party-modal__add-${type}--${actionName
        .replace(/ /g, "-")
        .toLowerCase()}`;
    },
    getActionButtonDataTestId() {
      return `add-third-party-modal__action-button--${
        this.editModalShow ? "update" : "submit"
      }`;
    },
    changeEntityName() {
      this.showExistingTable = false;
      this.existingItems = [];
      this.currentPageExistingItems = 1;
      this.perPageExistingItems = 5;
      this.fromRowsExistingItems = 0;
      this.toRowsExistingItems = 0;
      this.totalRowsExistingItems = 0;
    },
    inputRiskRatingComment(value) {
      if (value && value.trim() != "") {
        if (value.length <= 250) {
          this.validateRiskRatingComment = null;
        } else {
          this.validateRiskRatingComment = false;
        }
      } else {
        this.validateRiskRatingComment = false;
      }
    },
    selectRiskRatingOption(item) {
      if (item) {
        this.selectedRiskRatingText = item.text;
        this.selectedRiskRatingIcon = {
          icon: item?.icon,
          shape: item?.shape,
          theme: item?.theme,
          size: item?.size
        };
        this.riskRatingValue = item.value;
        this.onChangeRatingStatus = false;
        if (item.value == 0 || item.value == "0") {
          this.onChangeRatingStatus = true;
          this.riskRatingComment = null;
          this.validateRiskRatingComment = null;
        }
      }
      if (this.editModalShow) {
        if (this.riskRatingValue == this.onEditRiskRatingValue) {
          this.onChangeRatingStatus = true;
        } else {
          this.onChangeRatingStatus = false;
        }
      }
      if (!this.onChangeRatingStatus && this.editModalShow) {
        this.disableUpdateBtn = false;
      } else {
        this.disableUpdateBtn = true;
      }
    },
    is_url() {
      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (
        !regexp.test(this.form.entityThirdPartyUrl) &&
        this.form.entityThirdPartyUrl
      ) {
        this.invalidUrlDomain = true;
        this.invalidUrlDomainValue = "Please enter a valid URL";
      } else {
        this.invalidUrlDomain = false;
      }
    },
    setContactLanguage(value) {
      this.additionalDetailForm.contactLanguage = value
        ? value.languages
        : "English";
      this.additionalDetailForm.contactLanguageCode = value
        ? value.languageCode
        : "en";
    },
    checkEntityName(Value) {
      if (!Value) {
        this.form.entityName = null;
      }
    },
    async contactExistEmail() {
      let formData = {
        email: this.additionalDetailForm.contactEmail
      };
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/contact/exist-email",
          formData,
          makeAuthorizationHeader()
        );
        this.isLoadingData = false;
        if (
          result.data.httpStatus == 200 &&
          result.data.success == 1 &&
          result.data.data > 0
        ) {
          this.invalidEmailDomain = true;
          this.invalidEmailDomainValue =
            "This Email already exist, Please try another Email.";
        } else {
          this.invalidEmailDomain = false;
          this.invalidEmailDomainValue = null;
        }
      } catch (err) {
        this.isLoadingData = false;
        return [err];
      }
    },
    callOpenCorporateTableApi(value, type = null) {
      if (type == "country") {
        this.selectedCountryName = value.name;
        this.checkValue();
      }
      this.isShowingOpenCorporateData = false;
      this.clearOpenCorporateSectionData();
      setTimeout(() => {
        if (
          (this.entityTypeSelect == "O" &&
            this.form.entityName != null &&
            this.form.country != null) ||
          (this.entityTypeSelect == "P" && this.form.firstName != null)
        ) {
          if (this.entityTypeSelect == "P" && this.form.firstName != null) {
            this.currentPageIndividual = 1;
            this.isShowingOpenCorporateData = true;
          }
          this.isShowingOpenCorporateData = true;
          this.currentPage = 1;
          this.currentPageOtherCountries = 1;
        } else {
          this.isShowingOpenCorporateData = false;
        }
      }, 500);
    },
    checkEmailExists() {
      if (this.additionalDetailForm.contactEmail) {
        this.contactExistEmail().then(this.checkValidation);
      } else {
        this.checkValidation();
      }
    },
    checkValidation() {
      let customAttributesError = [];
      const filteredCustomAttributeValues = Object.keys(
        this.customAttributesValues
      ).filter((customAttributeId) =>
        this.hasPermission(
          permissionTypes.EDIT_CUSTOM_ATTRIBUTE_VALUE,
          customAttributeId
        )
      );

      filteredCustomAttributeValues.forEach((key) => {
        if (this.customAttributesValues[key].error) {
          customAttributesError.push(true);
        } else {
          customAttributesError.push(false);
        }
      });
      let checker = customAttributesError.every((v) => v == false);
      if (!this.onChangeRatingStatus) {
        if (
          this.riskRatingComment &&
          this.riskRatingComment.trim() != "" &&
          this.riskRatingComment.length <= 250
        ) {
          this.validateRiskRatingComment = null;
        } else {
          this.validateRiskRatingComment = false;
        }
      }
      this.v$.form?.$touch();

      if (!this.v$.form.$errors?.length) {
        this.v$.additionalDetailForm?.$touch();
        if (this.v$.additionalDetailForm.$errors?.length) {
          const element = this.$refs["add-third-party-modal__msq-wrapper"];
          element.scrollIntoView({ behavior: "smooth" });
        } else {
          if (!this.invalidEmailDomain && !this.invalidUrlDomain) {
            if (this.checkIsEddActive()) {
              this.v$.selecteditem.$touch();
            } else {
              if (
                this.isThirdPartyActionActive(
                  thirdPartyActionType.T_CERTIFICATION
                ) &&
                !this.selectedTCertItem.value
              ) {
                this.showErrorMessage = true;
              } else {
                this.showErrorMessage = false;
                if (
                  this.isThirdPartyActionActive(
                    thirdPartyActionType.ESG_METRIC
                  ) &&
                  !this.selectedEsgmItem.value
                ) {
                  this.showErrorMessageForEsgm = true;
                } else {
                  this.showErrorMessageForEsgm = false;
                  if (this.validateRiskRatingComment !== false) {
                    if (
                      (this.additionalDetailForm.contactName &&
                        this.additionalDetailForm.contactEmail) ||
                      (!this.additionalDetailForm.contactEmail &&
                        !this.additionalDetailForm.contactName)
                    ) {
                      if (!checker) {
                        for (const key of Object.keys(
                          this.customAttributesValues
                        )) {
                          if (this.customAttributesValues[key].error) {
                            if (
                              this.customAttributesValues[key].error &&
                              this.customAttributesValues[key].type ===
                                attributeType.TEXTAREA
                            ) {
                              EventBus.emit(
                                "textAreaValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              (this.customAttributesValues[key].type ===
                                attributeType.MULTISELECT ||
                                this.customAttributesValues[key].type ===
                                  attributeType.MULTISELECT_COUNTRIES)
                            ) {
                              EventBus.emit(
                                "multiselectValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              this.customAttributesValues[key].type ==
                                attributeType.COUNTRY_LIST
                            ) {
                              EventBus.emit(
                                "countryListValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              this.customAttributesValues[key].type ===
                                attributeType.HTML_STRING
                            ) {
                              EventBus.emit(
                                "htmlStringValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              this.customAttributesValues[key].type ===
                                attributeType.USER
                            ) {
                              EventBus.emit(
                                "UserListValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              this.customAttributesValues[key].type ===
                                attributeType.BOOLEAN
                            ) {
                              EventBus.emit(
                                "CheckBoxValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (
                              this.customAttributesValues[key].error &&
                              [
                                attributeType.DROPDOWN,
                                attributeType.RATING
                              ].includes(this.customAttributesValues[key].type)
                            ) {
                              EventBus.emit(
                                "DropDownValidationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            } else if (this.customAttributesValues[key].error) {
                              EventBus.emit(
                                "validationError",
                                this.customAttributesValues[key]
                              );
                              break;
                            }
                          }
                        }
                      } else {
                        let myActionTaken = this.completedActions.filter(
                          (x) =>
                            x.actionType == "idd" ||
                            x.actionType == "idd_plus" ||
                            x.actionType == "edd"
                        );
                        let actionsExtra = this.extraActionsBtn.filter(
                          (btn) => btn.isActive
                        );
                        let eddFilterIndex = actionsExtra.findIndex(
                          (btn) => btn.actionType == "edd"
                        );
                        myActionTaken.map((action, index) => {
                          if (
                            action.actionType == "edd" &&
                            action.imgName == "inprogress"
                          ) {
                            myActionTaken.splice(index, 1);
                          } else if (eddFilterIndex != -1) {
                            action.isActive = true;
                          } else if (action.actionType == "edd") {
                            action.isActive = false;
                          }
                        });

                        if (this.selectedSystemOwner.value) {
                          this.systemOwnerId = this.selectedSystemOwner.value;
                        }

                        if (
                          this.editModalShow &&
                          this.isChangeInAssociates &&
                          myActionTaken.length
                        ) {
                          this.reScreeningActions = myActionTaken;
                          this.showModal = false;
                          if (eddFilterIndex == -1) {
                            this.selecteditem = null;
                          }
                          this.$refs["modal-rerun-screening"].show();
                        } else if (this.editModalShow) {
                          this.updateThirdParty();
                        } else {
                          this.addThirdParty();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    makeActionTakenAllIsActive(actionsTaken) {
      actionsTaken.map((action, index) => {
        if (action.actionType == "idd" || action.actionType == "idd_plus") {
          action.isActive = true;
          actionsTaken[index] = action;
        }
      });
      return actionsTaken;
    },
    async updateThirdParty() {
      let actionsTaken = [];
      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actionsTaken.push(actions);
      });
      let eddRerunFilterIndex = this.reScreeningActions.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let eddExtraFilterIndex = this.extraActionsBtn.findIndex(
        (btn) => btn.actionType == "edd"
      );
      if (eddRerunFilterIndex != -1 && eddExtraFilterIndex != -1) {
        this.extraActionsBtn[eddExtraFilterIndex].isActive = false;
      }
      let filteredExtraActions = this.extraActionsBtn.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...filteredExtraActions);

      let reScreeningActiveActions = this.reScreeningActions.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...reScreeningActiveActions);

      let actionNotActive = [];
      let reScreeningInactiveActions = this.reScreeningActions.filter(
        (btn) => !btn.isActive
      );
      actionNotActive.push(...reScreeningInactiveActions);

      let IDDTypeIndex = actionsTaken.findIndex((x) => x.actionType == "idd");
      let IDDCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "idd"
      );
      if (IDDTypeIndex != -1) {
        actionsTaken[IDDTypeIndex].requestedDate = new Date();

        if (IDDCompletedIndex != -1) {
          actionsTaken[IDDTypeIndex].count =
            this.completedActions[IDDCompletedIndex].count + 1;
        } else {
          let IddRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "idd"
          );
          if (IddRemovedIndex != -1) {
            actionsTaken[IDDTypeIndex].count =
              this.removedActions[IddRemovedIndex].count + 1;
          }
        }
      }

      let due_diligence_type = "report_type_idd_free";
      let dueDiligenceTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      let IddPlusCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      if (dueDiligenceTypeIndex != -1) {
        actionsTaken[dueDiligenceTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[dueDiligenceTypeIndex].imgName = "ok";
        actionsTaken[dueDiligenceTypeIndex].requestedDate = new Date();
        due_diligence_type = "report_type_idd_plus";

        if (IddPlusCompletedIndex != -1) {
          actionsTaken[dueDiligenceTypeIndex].count =
            this.completedActions[IddPlusCompletedIndex].count + 1;
        } else {
          let IddPlusRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "idd_plus"
          );
          if (IddPlusRemovedIndex != -1) {
            actionsTaken[dueDiligenceTypeIndex].count =
              this.removedActions[IddPlusRemovedIndex].count + 1;
          }
        }
      }

      let isMSQActive = 0;
      actionsTaken.map((actions) => {
        let MsqCompletedIndex = this.completedActions.findIndex(
          (x) => x.actionType == "msq" && x.id == actions.id
        );
        if (actions.actionType == "msq") {
          actions.requestedDate = new Date();
          isMSQActive = 1;

          if (MsqCompletedIndex != -1) {
            actions.count = this.completedActions[MsqCompletedIndex].count + 1;
          }
        }
      });

      let isAPRIActive = 0;
      actionsTaken.map((actions) => {
        let ApriCompletedIndex = this.completedActions.findIndex(
          (x) => x.actionType == "apri" && x.id == actions.id
        );
        if (actions.actionType == "apri") {
          actions.backgroundColor = "#e3f4fa";
          actions.imgName = "inprogress";
          actions.requestedDate = new Date();
          isAPRIActive = 1;

          if (ApriCompletedIndex != -1) {
            actions.count = this.completedActions[ApriCompletedIndex].count + 1;
          }
        }
      });

      let standardTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "pri"
      );
      let PriCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "pri"
      );
      if (standardTypeIndex != -1) {
        actionsTaken[standardTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[standardTypeIndex].imgName = "ok";
        actionsTaken[standardTypeIndex].requestedDate = new Date();

        if (PriCompletedIndex != -1) {
          actionsTaken[standardTypeIndex].count =
            this.completedActions[PriCompletedIndex].count + 1;
        }
      }
      let eddTypeIndex = actionsTaken.findIndex((x) => x.actionType == "edd");
      let EddCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "edd"
      );
      let productPriceId = 0;
      if (eddTypeIndex != -1) {
        actionsTaken[eddTypeIndex].backgroundColor = "#e3f4fa";
        actionsTaken[eddTypeIndex].imgName = "inprogress";
        actionsTaken[eddTypeIndex].requestedDate = new Date();
        productPriceId = this.selectedItemArray.product_price_id;

        if (EddCompletedIndex != -1) {
          actionsTaken[eddTypeIndex].count =
            this.completedActions[EddCompletedIndex].count + 1;
        } else {
          let EddRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "edd"
          );
          if (EddRemovedIndex != -1) {
            actionsTaken[eddTypeIndex].count =
              this.removedActions[EddRemovedIndex].count + 1;
          }
        }
      }

      const tCertTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.T_CERTIFICATION
      );
      this.makeTComplianceActionObject(
        actionsTaken,
        thirdPartyActionType.T_CERTIFICATION
      );

      const esgMTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.ESG_METRIC
      );
      this.makeTComplianceActionObject(
        actionsTaken,
        thirdPartyActionType.ESG_METRIC
      );
      this.completedActions = actionsTaken;

      let productsArr = this.allElements.filter(
        (element) => element.package_products_id > 0
      );
      let productsName = [];
      productsArr.map((product) => {
        productsName.push(product.product_name);
      });

      let formData = {
        url: this.form.entityThirdPartyUrl,
        thirdPartyId: this.thirdpartyid,
        companyId: this.companyId,
        userId: this.userId,
        isApri: isAPRIActive,
        isDdq: isMSQActive,
        due_diligence_type,
        srchTpiId: this.searchTpiId,
        riskRatingNotes: this.riskRatingComment,
        riskRating: this.riskRatingValue,
        action_taken: this.completedActions,
        associates: this.associatesUsers,
        isChangeAssociate: this.isChangeAssociateUpdate ? 1 : 0,
        ...this.additionalDetailForm,
        comp_name: this.companyName,
        tp_name: this.editTpName,
        remove_actions: actionNotActive,
        custom_attributes: this.customAttributesValuesData,
        registrationNumber: this.form.registrationNumber,
        memberId: this.systemOwnerId || null
      };
      if (eddTypeIndex != -1) {
        formData.edd = {
          bill_name: this.companyDetails.billName,
          bill_email: this.companyDetails.billEmail,
          cost: this.selectedItemArray.client_packages_cost,
          tat: this.selectedItemArray.client_packages_tat,
          tat_max: this.selectedItemArray.client_packages_tat_max,
          package_id: this.selectedItemArray.packagesId,
          package_name: this.selectedItemArray.name || "",
          region_id: this.selectedItemArray.region_id,
          bill_country: this.companyDetails.billCountry,
          billing_add: this.companyDetails.billingAdd,
          eddScope: this.selecteditem,
          product_price_id: productPriceId,
          products: productsName
        };
      }
      formData.products = [];
      if (tCertTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[tCertTypeIndex].product,
          product_id: actionsTaken[tCertTypeIndex].id.split("_")[1],
          order_type: this.selectedTCertItem.text,
          order_type_id: this.selectedTCertItem.value
        });
      }
      if (esgMTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[esgMTypeIndex].product,
          product_id: actionsTaken[esgMTypeIndex].id.split("_")[1],
          order_type: this.selectedEsgmItem.text,
          order_type_id: this.selectedEsgmItem.value
        });
      }
      if (
        this.editModalShow &&
        this.isChangeInAssociates &&
        this.reScreeningActions.length
      ) {
        if (reScreeningActiveActions.length == 0) {
          formData.reRunScreening = 2;
        } else {
          formData.reRunScreening = 1;
        }
      }
      this.hideSubmitButton = true;
      this.$refs["modal-rerun-screening"].hide();
      if (!this.checkRerunEddActive()) {
        try {
          this.submiting = true;
          this.isLoading = true;
          this.isLoadingResultReview = true;
          this.orignalAssociatesUsers = [];
          let result = await HTTP(
            "post",
            "thirdparty/update/thirdparty/with/idd",
            formData,
            makeAuthorizationHeader()
          );
          if (result.data.message) {
            this.showCreditsAlert = true;
            this.isLoadingResultReview = false;
          }
          if (result.data && result.data.third_party_id) {
            this.thirdpartyid = result.data.third_party_id;
            localStorage.setItem("editThirdPartyId", this.thirdpartyid);
            this.encryptedProdId = result.data.encryptedProductId;
            this.encryptedSearchId = result.data.encryptedSrchId;
            this.completedActions = this.getUnique(result.data.action_taken);
            if (isAPRIActive == 0) {
              this.completedActions.map((data) => {
                if (data.actionType == "apri") {
                  data.imgName = "";
                }
              });
            }
            this.removedActions = result.data.remove_actions
              ? result.data.remove_actions
              : [];
            this.showingReviewResult = true;
            this.resultsFetchDisable = true;
            this.userTyping = true;
            if (
              productPriceId > 0 &&
              this.companyDetails.eddContract === eddContracts.ORDER
            ) {
              this.$bvToast.toast(
                "Thank you for your Enhanced Due Diligence Order. This order has submitted to our team for action. You will be notified once the report is available on the platform",
                {
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: false,
                  noCloseButton: true
                }
              );
            } else if (
              productPriceId > 0 &&
              this.companyDetails.eddContract === eddContracts.ENQUIRY
            ) {
              this.$bvToast.toast(
                "Thank you for your Enhanced Due Diligence enquiry. This order has submitted to our team for action. You will be contacted shortly",
                {
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: false,
                  noCloseButton: true
                }
              );
            }
            this.orignalAssociatesUsers = cloneDeep(this.associatesUsers);
            this.collapse = {
              EntityVerification: false,
              AssociateEntities: false,
              AdditionalDetails: false,
              CustomAttributes: false
            };
            this.extraActionsBtn.map((actions) => {
              actions.isActive = false;
              actions.imgName = "";
              actions.backgroundColor = "";
            });
            this.disableUpdateBtn = true;
            this.getResultPreviewData();
            if (this.isChangeInAssociates && this.reScreeningActions.length) {
              this.closeModal();
            }
          }

          this.reScreeningActions = [];
          this.reScreeningCount = 0;
          this.isChangeAssociateUpdate = false;
          this.isChangeInAssociates = false;
          this.onChangeRatingStatus = true;
          this.riskRatingComment = null;
          this.isLoadingResultReview = false;
          this.submiting = false;
          this.isLoading = false;
          this.responseSuccess = true;
        } catch (error) {
          this.hideSubmitButton = false;
          this.isLoadingResultReview = false;
          this.isLoading = false;
          this.submiting = false;
        }
      }
    },
    makeTComplianceActionObject(actionsTaken, thirdPartyActionType) {
      const tComplianceActionIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType
      );
      const tComplianceCompleteIndex = this.completedActions.findIndex(
        (x) => x.actionType === thirdPartyActionType
      );
      if (tComplianceActionIndex !== -1) {
        actionsTaken[tComplianceActionIndex].backgroundColor = colors.bubbles;
        actionsTaken[tComplianceActionIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[tComplianceActionIndex].requestedDate = new Date();

        if (tComplianceCompleteIndex !== -1) {
          actionsTaken[tComplianceActionIndex].count =
            this.completedActions[tComplianceCompleteIndex].count + 1;
        } else {
          const EsgMRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType === thirdPartyActionType
          );
          if (EsgMRemovedIndex !== -1) {
            actionsTaken[tComplianceActionIndex].count =
              this.removedActions[EsgMRemovedIndex].count + 1;
          }
        }
      }
      return actionsTaken;
    },
    getUnique(array) {
      const filteredArr = array.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filteredArr;
    },
    checkIsMsqActive() {
      let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      let occurActions = this.actionsOccurBtns;
      let actionsData = [];
      actionsData.push(...actionsExtra);
      actionsData.push(...occurActions);
      let isMsqActive = false;

      actionsData.forEach(({ actionType }) => {
        if (
          actionType === thirdPartyActionType.MSQ ||
          actionType === thirdPartyActionType.T_CERTIFICATION ||
          actionType === thirdPartyActionType.ESG_METRIC
        ) {
          isMsqActive = true;
        }
      });
      return isMsqActive;
    },
    checkIsEddActive() {
      let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      let occurActions = this.actionsOccurBtns;
      let actionsData = [];
      actionsData.push(...actionsExtra);
      actionsData.push(...occurActions);
      let eddFilterIndex = actionsData.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let isEDDActive = false;
      if (eddFilterIndex != -1 && !this.selecteditem) {
        isEDDActive = true;
      }
      return isEDDActive;
    },
    isThirdPartyActionActive(tpActionType) {
      const actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      const isThirdPartyActionTypePresent = !![
        ...actionsExtra,
        ...this.actionsOccurBtns
      ].find(({ actionType }) => actionType === tpActionType);
      if (tpActionType === thirdPartyActionType.T_CERTIFICATION) {
        return isThirdPartyActionTypePresent && !this.selectedTCertItem.value;
      } else if (tpActionType === thirdPartyActionType.ESG_METRIC) {
        return isThirdPartyActionTypePresent && !this.selectedEsgmItem.value;
      }
    },
    checkRerunEddActive() {
      let actionsExtra = this.reScreeningActions.filter((btn) => btn.isActive);
      let eddFilterIndex = actionsExtra.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let isEDDActive = false;
      if (eddFilterIndex != -1 && !this.selecteditem) {
        isEDDActive = true;
      }
      return isEDDActive;
    },
    async addThirdParty() {
      let actionsTaken = [];
      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actionsTaken.push(actions);
      });
      let filteredExtraActions = this.extraActionsBtn.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...filteredExtraActions);

      let IDDTypeIndex = actionsTaken.findIndex((x) => x.actionType == "idd");
      if (IDDTypeIndex != -1) {
        actionsTaken[IDDTypeIndex].requestedDate = new Date();
      }

      let due_diligence_type = "report_type_idd_free";
      let dueDiligenceTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      if (dueDiligenceTypeIndex != -1) {
        actionsTaken[dueDiligenceTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[dueDiligenceTypeIndex].imgName = "ok";
        actionsTaken[dueDiligenceTypeIndex].requestedDate = new Date();
        due_diligence_type = "report_type_idd_plus";
      }

      let isMSQActive = 0;
      actionsTaken.map((actions) => {
        if (actions.actionType == "msq") {
          actions.requestedDate = new Date();
          isMSQActive = 1;
        }
      });

      let isAPRIActive = 0;
      actionsTaken.map((actions) => {
        if (actions.actionType == "apri") {
          actions.backgroundColor = "#e3f4fa";
          actions.imgName = "inprogress";
          actions.requestedDate = new Date();
          isAPRIActive = 1;
        }
      });
      let standardTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "pri"
      );
      if (standardTypeIndex != -1) {
        actionsTaken[standardTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[standardTypeIndex].imgName = "ok";
        actionsTaken[standardTypeIndex].requestedDate = new Date();
      }
      let eddTypeIndex = actionsTaken.findIndex((x) => x.actionType == "edd");
      let productPriceId = 0;
      if (eddTypeIndex != -1) {
        actionsTaken[eddTypeIndex].backgroundColor = "#e3f4fa";
        actionsTaken[eddTypeIndex].imgName = "inprogress";
        actionsTaken[eddTypeIndex].requestedDate = new Date();
        productPriceId = this.selectedItemArray.product_price_id;
      }

      const tCertTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.T_CERTIFICATION
      );
      if (tCertTypeIndex !== -1) {
        actionsTaken[tCertTypeIndex].backgroundColor = colors.bubbles;
        actionsTaken[tCertTypeIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[tCertTypeIndex].requestedDate = new Date();
      }

      const esgMTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.ESG_METRIC
      );
      if (esgMTypeIndex !== -1) {
        actionsTaken[esgMTypeIndex].backgroundColor = colors.bubbles;
        actionsTaken[esgMTypeIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[esgMTypeIndex].requestedDate = new Date();
      }

      let productsArr = this.allElements.filter(
        (element) => element.package_products_id > 0
      );
      let productsName = [];
      productsArr.map((product) => {
        productsName.push(product.product_name);
      });

      let formData = {
        url: this.form.entityThirdPartyUrl,
        companyId: this.companyId,
        userId: this.userId,
        thirdPartyName:
          this.entityTypeSelect == "O"
            ? this.form.entityName
            : this.form.firstName,
        associates: this.associatesUsers,
        tpiType: this.entityTypeSelect,
        country: this.form.country ? this.form.country.name : null,
        due_diligence_type,
        icompany: this.icompany,
        searchKey: this.searchKey,
        registrationNumber: this.form.registrationNumber,
        dunsNumber: null,
        isApri: isAPRIActive,
        isDdq: isMSQActive,
        dnb_data: {
          isAlatresDataFound: this.isOpenCorporatesDataFound,
          isAlatresDataSelected: this.isOpenCorporatesDataSelected,
          datasetThirdPartyName: this.datasetThirdPartyName,
          ocCompanySelectedCountry: this.ocCompanySelectedCountry,
          searchedCompany:
            this.entityTypeSelect == "O"
              ? this.form.entityName
              : this.form.firstName,
          selectedReviewCompany: this.selectedOpenCorporateCompany,
          clientCustonAttributeData: []
        },
        ...this.additionalDetailForm,
        action_taken: actionsTaken,
        riskRating: this.riskRatingValue,
        riskRatingNotes: this.riskRatingComment,
        custom_attributes: this.customAttributesValuesData,
        memberId: this.systemOwnerId || null
      };
      if (eddTypeIndex != -1) {
        formData.edd = {
          bill_name: this.companyDetails.billName,
          bill_email: this.companyDetails.billEmail,
          cost: this.selectedItemArray.client_packages_cost,
          tat: this.selectedItemArray.client_packages_tat,
          tat_max: this.selectedItemArray.client_packages_tat_max,
          package_id: this.selectedItemArray.packagesId,
          package_name: this.selectedItemArray.name || "",
          region_id: this.selectedItemArray.region_id,
          bill_country: this.companyDetails.billCountry,
          billing_add: this.companyDetails.billingAdd,
          eddScope: this.selecteditem,
          product_price_id: productPriceId,
          products: productsName
        };
      }
      formData.products = [];
      if (tCertTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[tCertTypeIndex].product,
          product_id: actionsTaken[tCertTypeIndex].id.split("_")[1],
          order_type: this.selectedTCertItem.text,
          order_type_id: this.selectedTCertItem.value
        });
      }
      if (esgMTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[esgMTypeIndex].product,
          product_id: actionsTaken[esgMTypeIndex].id.split("_")[1],
          order_type: this.selectedEsgmItem.text,
          order_type_id: this.selectedEsgmItem.value
        });
      }
      this.hideSubmitButton = true;
      try {
        this.submiting = true;
        this.isLoading = true;
        this.isLoadingResultReview = true;
        let result = await HTTP(
          "post",
          "thirdparty/create/thirdparty/with/idd",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.message) {
          this.showCreditsAlert = true;
          this.isLoadingResultReview = false;
        }
        if (result.data && result.data.third_party_id) {
          this.thirdpartyid = result.data.third_party_id;
          localStorage.setItem("editThirdPartyId", this.thirdpartyid);

          this.encryptedProdId = result.data.encryptedProductId;
          this.encryptedSearchId = result.data.encryptedSrchId;
          this.searchTpiId = result.data.srchId;
          this.showingReviewResult = true;
          this.isLoadingResultReview = false;
          this.resultsFetchDisable = true;
          this.userTyping = true;
          this.completedActions = result.data.action_taken;
          this.extraActionsBtn.push(...this.actionsOccurBtns);
          this.actionsOccurBtns = [];
          this.collapse = {
            EntityVerification: false,
            AssociateEntities: false,
            AdditionalDetails: false,
            CustomAttributes: false
          };
          this.extraActionsBtn.map((actions) => {
            actions.isActive = false;
            actions.imgName = "";
            actions.backgroundColor = "";
          });
          this.disableUpdateBtn = true;
          this.getResultPreviewData();
          if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ORDER
          ) {
            this.$bvToast.toast(
              "Thank you for your Enhanced Due Diligence Order. This order has submitted to our team for action. You will be notified once the report is available on the platform",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true
              }
            );
          } else if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ENQUIRY
          ) {
            this.$bvToast.toast(
              "Thank you for your Enhanced Due Diligence enquiry. This order has submitted to our team for action. You will be contacted shortly",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true
              }
            );
          }
        }
        this.onChangeRatingStatus = true;
        this.riskRatingComment = null;
        this.editModalShow = true;
        this.isChangeInAssociates = false;
        this.isChangeAssociateUpdate = false;
        this.isLoadingResultReview = false;
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        this.$store.state.isTpEditModal = true;
      } catch (error) {
        this.hideSubmitButton = false;
        this.isLoadingResultReview = false;
        this.isLoading = false;
        this.submiting = false;
      }
    },
    async getResultPreviewData(editData = null) {
      let srchId = editData ? editData.srch_tpi_id_enc : this.encryptedSearchId;
      let prodId = editData
        ? editData.encryptedProductId
        : this.encryptedProdId;
      try {
        this.isLoadingResultData = true;
        this.isLoadingAssociatesTable = true;
        this.isAssoLoading = true;
        let result = await HTTP(
          "get",
          `thirdparty/result-preview-msq/${srchId}/${this.userId}/${prodId}/${this.companyId}`,
          null,
          makeAuthorizationHeader(),
          null,
          true
        );
        if (
          result.data.data &&
          result.data.status == 1 &&
          result.status == 200
        ) {
          if (result.data.data.shield_vendor_infos) {
            this.selectedEntityType = result.data.data.shield_vendor_infos
              .tpi_type
              ? result.data.data.shield_vendor_infos.tpi_type
              : null;
          }
          this.editTpName = result.data.data.thirdPartyName;
          this.searchReportData = result.data.data;
          if (this.completedActions.length != 0) {
            let iddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd"
            );
            if (
              iddTypeIndex != -1 &&
              this.searchReportData &&
              this.searchReportData.shield_vendor_infos &&
              this.searchReportData.shield_vendor_infos.risk_level
            ) {
              this.completedActions[iddTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.risk_level == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.risk_level == 3
                  ? "HighAlert"
                  : this.searchReportData.shield_vendor_infos.risk_level == 5
                  ? "RemediatedOK"
                  : this.searchReportData.shield_vendor_infos.risk_level == 6
                  ? "remediatedHighAlert"
                  : "ok";
            }
            let dueDiligenceTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd_plus"
            );
            if (dueDiligenceTypeIndex != -1) {
              this.completedActions[dueDiligenceTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.idd_color == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.idd_color == 3
                  ? "HighAlert"
                  : this.searchReportData.shield_vendor_infos.idd_color == 5
                  ? "RemediatedOK"
                  : this.searchReportData.shield_vendor_infos.idd_color == 6
                  ? "remediatedHighAlert"
                  : "";
            }
            let priTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "pri"
            );
            if (priTypeIndex != -1) {
              this.completedActions[priTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.ethix_risk_rating == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos
                      .ethix_risk_rating == 2
                  ? "WarningAlert"
                  : this.searchReportData.shield_vendor_infos
                      .ethix_risk_rating == 3
                  ? "HighAlert"
                  : "ok";
            }
            let eddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "edd"
            );
            if (eddTypeIndex != -1) {
              this.completedActions[eddTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.edd_color == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.edd_color == 3
                  ? "HighAlert"
                  : "inprogress";

              let eddIndex = this.extraActionsBtn.findIndex(
                (x) => x.actionType == "edd"
              );
              if (
                eddIndex != -1 &&
                this.completedActions[eddTypeIndex].imgName == "inprogress"
              ) {
                this.extraActionsBtn.splice(eddIndex, 1);
              }
            }
            iddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd"
            );
            if (iddTypeIndex != -1) {
              if (
                parseInt(this.headerConfigData.specialFeature.autoremidd) &&
                this.completedActions[iddTypeIndex].imgName == "HighAlert"
              ) {
                this.completedActions.push({
                  id: 9,
                  btnText: "Remediate IDD",
                  gridBtntext: "R IDD",
                  isActive: false,
                  actionType: "remediate_idd",
                  isHidden: false,
                  backgroundColor: "#e3f4fa",
                  imgName: "inprogress"
                });
              } else if (
                this.completedActions[iddTypeIndex].imgName ==
                "remediatedHighAlert"
              ) {
                let remediateIddTypeIndex = this.completedActions.findIndex(
                  (x) => x.actionType == "remediate_idd"
                );
                if (remediateIddTypeIndex != -1) {
                  this.completedActions.splice(remediateIddTypeIndex, 1);
                }
              }
            }
            dueDiligenceTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd_plus"
            );
            if (dueDiligenceTypeIndex != -1) {
              if (
                parseInt(this.headerConfigData.specialFeature.autoremiddplus) &&
                this.completedActions[dueDiligenceTypeIndex].imgName ==
                  "HighAlert"
              ) {
                this.completedActions.push({
                  id: 10,
                  btnText: "Remediate IDD+",
                  gridBtntext: "R IDD+",
                  isActive: false,
                  actionType: "remediate_idd_plus",
                  isHidden: false,
                  backgroundColor: "#e3f4fa",
                  imgName: "inprogress"
                });
              } else if (
                this.completedActions[dueDiligenceTypeIndex].imgName ==
                "remediatedHighAlert"
              ) {
                let remediateIddPlusTypeIndex = this.completedActions.findIndex(
                  (x) => x.actionType == "remediate_idd_plus"
                );
                if (remediateIddPlusTypeIndex != -1) {
                  this.completedActions.splice(remediateIddPlusTypeIndex, 1);
                }
              }
            }
            const tCertTypeCompletedIndex = this.completedActions.findIndex(
              (action) =>
                action.actionType === thirdPartyActionType.T_CERTIFICATION
            );
            if (tCertTypeCompletedIndex !== -1) {
              const tCertTypeProductIndex =
                this.searchReportData.product_orders.findIndex(
                  (product) =>
                    product.product_id ===
                    this.completedActions[tCertTypeCompletedIndex].id.split(
                      "_"
                    )[1]
                );
              if (tCertTypeProductIndex !== -1) {
                this.completedActions[tCertTypeCompletedIndex].imgName =
                  this.searchReportData.product_orders[tCertTypeProductIndex]
                    .status === thirdPartyActionProductStatus.IN_PROGRESS
                    ? thirdPartyActionImageType.IN_PROGRESS
                    : "";
              }
              const tCertIndex = this.extraActionsBtn.findIndex(
                (action) =>
                  action.actionType === thirdPartyActionType.T_CERTIFICATION
              );
              if (
                tCertIndex !== -1 &&
                this.completedActions[tCertTypeCompletedIndex].imgName ===
                  thirdPartyActionImageType.IN_PROGRESS
              ) {
                this.extraActionsBtn.splice(tCertIndex, 1);
              }
            }
            const esgmTypeCompletedIndex = this.completedActions.findIndex(
              (action) => action.actionType === thirdPartyActionType.ESG_METRIC
            );
            if (esgmTypeCompletedIndex !== -1) {
              const esgmTypeProductIndex =
                this.searchReportData.product_orders.findIndex(
                  (product) =>
                    product.product_id ===
                    this.completedActions[esgmTypeCompletedIndex].id.split(
                      "_"
                    )[1]
                );
              if (esgmTypeProductIndex !== -1) {
                this.completedActions[esgmTypeCompletedIndex].imgName =
                  this.searchReportData.product_orders[esgmTypeProductIndex]
                    .status === thirdPartyActionProductStatus.IN_PROGRESS
                    ? thirdPartyActionImageType.IN_PROGRESS
                    : "";
              }
              const esgmIndex = this.extraActionsBtn.findIndex(
                (action) =>
                  action.actionType === thirdPartyActionType.ESG_METRIC
              );
              if (
                esgmIndex !== -1 &&
                this.completedActions[esgmTypeCompletedIndex].imgName ===
                  thirdPartyActionImageType.IN_PROGRESS
              ) {
                this.extraActionsBtn.splice(esgmIndex, 1);
              }
            }
          }
          let countryForCopratesOfficer = result.data.data.oc_orginfos_srch20[0]
            ? result.data.data.oc_orginfos_srch20[0].jurisdiction_code
            : null;
          let registrationNuberCorporatesOfficers = result.data.data
            .oc_orginfos_srch20[0]
            ? result.data.data.oc_orginfos_srch20[0].company_number
            : null;
          if (
            countryForCopratesOfficer &&
            registrationNuberCorporatesOfficers
          ) {
            this.getOpenCorporatesCompanyOfficers(
              countryForCopratesOfficer,
              registrationNuberCorporatesOfficers
            );
          } else {
            this.isLoadingAssociatesTable = false;
            this.isAssoLoading = false;
          }

          this.removePerformedActions();
          let dnb_data = result.data.data.dnb_data
            ? JSON.parse(result.data.data.dnb_data)
            : null;
          this.tpDnbData = dnb_data;
          this.tpDnbSelectedAltaresTableItemData =
            dnb_data.selectedAltaresTableItem
              ? dnb_data.selectedAltaresTableItem.organization
                ? dnb_data.selectedAltaresTableItem.organization
                : null
              : null;
          this.beneficialOwnershipRegistrationData =
            dnb_data.beneficialOwnershipRegistrationData
              ? dnb_data.beneficialOwnershipRegistrationData
              : {};
          this.isLoadingAssociatesTable = false;
          this.showNoteTextCall(result);
        } else {
          this.isLoadingAssociatesTable = false;
          this.isLoadingResultData = false;
        }
      } catch {
        this.isLoadingAssociatesTable = false;
        this.isLoadingResultData = false;
      }
    },
    showNoteTextCall(result) {
      if (result.data.data.productType == 2) {
        if (result.data.data.shield_vendor_infos) {
          if (result.data.data.shield_vendor_infos.is_idd == "1") {
            if (result.data.data.shield_vendo_infos.idd_color == "3") {
              if (result.data.data.shield_vedo_infos.idd_color == "1") {
                if (result.data.data.srch_tpi_rdc_indivi_alrt.length) {
                  this.showNoteText = true;
                }
              } else if (result.data.data.shield_vedo_infos.idd_color == "3") {
                if (result.data.data.srch_tpi_rdc_indivi_alrt.length) {
                  this.showNoteText = true;
                }
              } else {
                this.showNoteText = false;
              }
            } else {
              this.showNoteText = false;
            }
          } else {
            this.showNoteText = false;
          }
        } else {
          this.showNoteText = false;
        }
      } else {
        this.showNoteText = false;
      }
      this.isLoading = false;
    },
    onSelectEntityType(value, associatesUsersIndex) {
      this.isLoadingAssociatesValidation = true;
      this.checkAssociateChange();
      if (value) {
        this.associatesUsers[associatesUsersIndex].entityType = value;
        this.associatesUsers[associatesUsersIndex].validationStateEntityType =
          null;
      } else {
        this.associatesUsers[associatesUsersIndex].entityType = null;
      }
      this.isLoadingAssociatesValidation = false;
    },
    onInputAssosName(value, associatesUsersIndex) {
      this.isLoadingAssociatesValidation = true;
      this.checkAssociateChange();
      if (value) {
        this.associatesUsers[associatesUsersIndex].validationStateAssosName =
          null;
      } else {
        this.associatesUsers[associatesUsersIndex].assoName = null;
      }
      this.isLoadingAssociatesValidation = false;
    },
    selectAssociatedUsersData(value, data) {
      if (value) {
        if (this.associatesUsers[0].assoName == null) {
          this.associatesUsers = [];
          this.associatesUsers.push({
            firstName: null,
            assoName: data.item.name,
            entityType: "P",
            id: data.item.id,
            position: data.item.position,
            meta_data: data.item,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        } else {
          this.associatesUsers.push({
            firstName: null,
            assoName: data.item.name,
            entityType: "P",
            id: data.item.id,
            position: data.item.position,
            meta_data: data.item,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        }
      } else {
        let associatesUsersIndex = this.associatesUsers.findIndex(
          (x) => x.id == data.item.id
        );
        this.associatesUsers.splice(associatesUsersIndex, 1);
        if (this.associatesUsers.length == 0) {
          this.associatesUsers.push({
            firstName: null,
            assoName: null,
            entityType: "P",
            id: uid(),
            position: null,
            meta_data: null,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        }
      }
      this.checkAssociateChange();
    },
    async getOpenCorporatesCompanyOfficers(country, countryRegistrationNumber) {
      this.openCorporatesCompanyOfficersItems = [];
      let formData = {
        country:
          country ||
          (this.selectedOpenCorporateCompany
            ? this.selectedOpenCorporateCompany.jurisdiction_code
            : null),
        openCorporateCompanyRegistrationNumber:
          countryRegistrationNumber ||
          (this.selectedOpenCorporateCompany
            ? this.selectedOpenCorporateCompany.company_number
            : null)
      };
      this.isLoadingAssociatesTable = true;
      this.isAssoLoading = true;
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatecompanyofficers",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.openCorporatesCompanyOfficersItems = response.data.response.data
            ? response.data.response.data
            : [];
          this.openCorporatesCompanyOfficersItems.map(
            (companyOfficers, index) => {
              this.associatesUsers.map((users) => {
                if (users.id == companyOfficers.id) {
                  this.selectedCompanyOfficers[index] = true;
                }
              });
            }
          );
          this.isLoadingAssociatesTable = false;
          this.showingAssociatesTable = true;
          this.isAssoLoading = false;
        })
        .catch((error) => {
          this.isLoadingAssociatesTable = false;
          this.isAssoLoading = false;
          return [error];
        });
    },
    clearOpenCorporateSectionData() {
      this.selectedRowWithCountry = null;
      this.selectedRowWithoutCountry = null;
      this.selectedIndividualRowCountry = null;
      this.selectedCompanyOfficers = [];

      this.isShowingOpenCorporateData = false;
      this.verificationTableCollapse = false;
      this.verificationTableCollapseTwo = false;
      this.verificationTableCollapseThree = false;
      this.tableWithoutCountryItems = [];
      this.isLoadingForOthers = false;
      this.tableWithCountryItems = [];
      this.tableWithIndividualItems = [];
      this.clearAssociatesSectionData();
    },
    clearAssociatesSectionData() {
      localStorage.removeItem("encryptedSrchId");
      localStorage.removeItem("encryptedProductId");
      localStorage.removeItem("encryptedReportId");

      this.openCorporatesCompanyOfficersItems = [];
      this.showingAssociatesTable = false;
      this.ocCompanySelectedCountry = null;
      this.isOpenCorporatesDataSelected = false;
      this.datasetThirdPartyName = null;
      ``;
      this.selectedOpenCorporateCompany = null;
      this.selectedIndividualRowCountry = null;
      this.associatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.orignalAssociatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.reportId = null;
      this.searchId = null;
      this.encryptedProductId = null;
      this.icompany = {};
      this.searchKey = null;
      this.isOpenCorporatesDataFound = false;
    },
    setOpenCorporateData(data) {
      this.clearAssociatesSectionData();
      if (this.entityTypeSelect == "O") {
        this.form.entityName = data.item.name;
        this.datasetThirdPartyName = data.item.name;
      } else {
        this.selectedIndividualRowCountry = data.index;
        this.datasetThirdPartyName = data.item.company.name;
      }
      this.isOpenCorporatesDataSelected = true;
      this.isOpenCorporatesDataFound = true;

      this.selectedOpenCorporateCompany = null;
      this.selectedOpenCorporateCompany = data.item;
      if (this.entityTypeSelect == "O") {
        let icompany = {};
        this.searchKey = data.item.company_number;
        icompany[
          data.item.company_number
        ] = `${data.item.jurisdiction_code}|${data.item.company_number}`;

        this.icompany = {};
        this.icompany = icompany;
        this.ocCompanySelectedCountry = data.item.jurisdiction_code;
        let countryCodeIndex = this.countries.findIndex(
          ({ isoCode1 }) =>
            isoCode1.toLowerCase() === data.item.jurisdiction_code
        );
        if (countryCodeIndex !== -1) {
          this.form.country = this.countries[countryCodeIndex];
        }
        this.getOpenCorporatesCompanyOfficers();
        this.showingAssociatesTable = true;
      } else {
        let icompany = {};
        this.searchKey = data.item.company.company_number;
        icompany[
          data.item.company.company_number
        ] = `${data.item.company.jurisdiction_code}|${data.item.id}`;

        this.icompany = {};
        this.icompany = icompany;
      }
    },
    getJurisdicationCountryName(jurisdiction_code) {
      let countryCodeIndex = this.countries.findIndex(
        ({ isoCode1 }) => isoCode1.toLowerCase() === jurisdiction_code
      );

      return this.countries[countryCodeIndex];
    },
    getCountryNames(jurisdiction_code) {
      let OCStatesIndex = this.OCStatesData.findIndex(
        (x) => x.code == jurisdiction_code
      );
      return OCStatesIndex;
    },
    async getPreviouslySavedEntities() {
      const formData = {
        comp_name_eng:
          this.entityTypeSelect == "O"
            ? this.form.entityName
            : this.form.firstName,
        company_id: this.companyId,
        page: this.currentPageExistingItems,
        per_page: this.perPageExistingItems
      };
      this.isLoadingPreviouslySavedEntites = true;
      const result = HTTP(
        "post",
        "thirdparty/existing",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.existingItems = [];
          this.fromRowsExistingItems =
            response.data.data.length == 0 ? 0 : response.data.from;
          this.toRowsExistingItems =
            response.data.data.length == 0 ? 0 : response.data.to;
          this.totalRowsExistingItems = response.data.total;
          this.isLoadingPreviouslySavedEntites = false;
          this.existingItems = response.data.data.length
            ? response.data.data
            : [];
          this.showExistingTable = !!response.data.data.length;
          return response.data.data;
        })
        .catch((error) => {
          this.isLoadingPreviouslySavedEntites = false;
          return [error];
        });
    },
    async getOpenCorporatesCompanyWithCountry() {
      let formData = {
        country: this.form.country.isoCode1.toLowerCase(),
        page: this.currentPage,
        per_page: this.perPage,
        search: this.form.entityName
      };
      this.isLoadingCountryData = true;

      let result = HTTP(
        "post",
        "thirdparty/getopencorporatescompanywithcountry",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.tableWithCountryItems = [];
          this.recordsFoundInCountry =
            response.data.response.records_in_country == 0
              ? 0
              : response.data.response.records_in_country;
          this.fromRows =
            response.data.response.data.length == 0
              ? 0
              : response.data.response.from;
          this.toRows =
            response.data.response.data.length == 0
              ? 0
              : response.data.response.to;
          if (
            response.data.response.total_pages &&
            response.data.response.total_pages <= 2000
          ) {
            this.totalPaginationRows = response.data.response.total;
          } else {
            this.totalPaginationRows = 10000;
          }
          this.totalRows = response.data.response.total;
          this.isLoadingCountryData = false;
          this.tableWithCountryItems = response.data.response.data.length
            ? response.data.response.data
            : [];
          this.isOpenCorporatesDataFound = !!response.data.response.data.length;
          return response.data.response.data;
        })
        .catch((error) => {
          this.isLoadingCountryData = false;
          return [error];
        });
    },
    async getOpenCorporatesCompanyIndividual() {
      this.isLoadingForIndividual = true;
      let formData = {
        page: this.currentPageIndividual,
        per_page: this.perPageIndividual,
        search: this.form.firstName
      };
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatesindiviual",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          let returnTableItems = [];

          response.data.response.data.map((resultData) => {
            returnTableItems.push(resultData);
          });
          this.tableWithIndividualItems = [];
          this.fromRowsIndividual =
            returnTableItems.length == 0 ? 0 : response.data.response.from;
          this.toRowsIndividual =
            returnTableItems.length == 0 ? 0 : response.data.response.to;
          this.totalRowsIndividual = response.data.response.total;
          if (
            response.data.response.total_pages &&
            response.data.response.total_pages <= 2000
          ) {
            this.totalPaginationIndividual = response.data.response.total;
          } else {
            this.totalPaginationIndividual = 10000;
          }

          this.tableWithIndividualItems = returnTableItems.length
            ? returnTableItems
            : [];

          this.isOpenCorporatesDataFound = !!response.data.response.data.length;
          this.isLoadingForIndividual = false;
          return returnTableItems;
        })
        .catch((error) => {
          this.isLoadingForIndividual = false;
          return [error];
        });
    },
    async getOpenCorporatesCompany() {
      let formData = {
        page: this.currentPageOtherCountries,
        per_page: this.perPageOtherCountries,
        search: this.form.entityName
      };
      this.isLoadingForOthers = true;
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatescompany",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          let returnTableItems = [];

          response.data.response.data.map((resultData) => {
            returnTableItems.push(resultData);
          });
          this.tableWithoutCountryItems = [];
          this.fromRowsOtherCountries =
            returnTableItems.length == 0 ? 0 : response.data.response.from;
          if (this.recordsFoundInCountry == 0) {
            this.recordsFoundInCountry =
              response.data.response.records_in_country == 0
                ? 0
                : response.data.response.records_in_country;
          }
          this.toRowsOtherCountries =
            returnTableItems.length == 0 ? 0 : response.data.response.to;
          this.totalRowsOtherCountries = response.data.response.total;
          if (
            response.data.response.total_pages &&
            response.data.response.total_pages <= 2000
          ) {
            this.totalPaginationRowsOtherCountries =
              response.data.response.total;
          } else {
            this.totalPaginationRowsOtherCountries = 10000;
          }
          this.isLoadingForOthers = false;

          this.tableWithoutCountryItems = returnTableItems.length
            ? returnTableItems
            : [];

          this.isOpenCorporatesDataFound = !!response.data.response.data.length;
          return returnTableItems;
        })
        .catch((error) => {
          this.isLoadingForOthers = false;
          return [error];
        });
    },
    modalShow(data) {
      this.selectedRiskRatingText = "Unclassified";
      this.riskRatingValue = 0;
      this.riskRatingComment = null;

      if (data) {
        const extraActions = this.getAvailableExtraActions(data);

        if (data.actionsOccur || extraActions || data.actionsNotAvailable) {
          this.actionsOccurBtns = data.actionsOccur ? data.actionsOccur : [];
          if (extraActions) {
            extraActions.map((items) => {
              if (!items.isHidden) {
                this.extraActionsBtn.push(items);
              }
            });
          }
          if (data.actionsNotAvailable) {
            data.actionsNotAvailable.map((items) => {
              if (!items.isHidden) {
                this.actionsNotAvailable.push(items);
              }
            });
          }
        } else {
          const defaultExtraActions =
            this.getAvailableExtraActions(defaultActionJson);

          this.actionsOccurBtns = defaultActionJson.actionsOccur;
          defaultExtraActions.map((items) => {
            if (!items.isHidden) {
              this.extraActionsBtn = defaultExtraActions;
            }
          });
          defaultActionJson.actionsNotAvailable.map((items) => {
            if (!items.isHidden) {
              this.actionsNotAvailable = defaultActionJson.actionsNotAvailable;
            }
          });
          this.getQuestionnariesData();
        }
      } else {
        const defaultExtraActions =
          this.getAvailableExtraActions(defaultActionJson);

        this.actionsOccurBtns = defaultActionJson.actionsOccur;
        defaultExtraActions.map((items) => {
          if (!items.isHidden) {
            this.extraActionsBtn = defaultExtraActions;
          }
        });
        defaultActionJson.actionsNotAvailable.map((items) => {
          if (!items.isHidden) {
            this.actionsNotAvailable = defaultActionJson.actionsNotAvailable;
          }
        });
        this.getQuestionnariesData();
      }
      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actions.imgName = "";
        actions.backgroundColor = "";
      });
      this.extraActionsBtn.map((actions) => {
        actions.imgName = "";
        actions.backgroundColor = "";
      });
      this.showModal = true;
    },
    async getQuestionnariesData() {
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.extraActionsBtn.push(...result.data.questionnaires);
          this.extraActionsBtn.push(...result.data.products);
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    getAvailableExtraActions({ extraActions = [], erafActions = [] } = {}) {
      return [...extraActions, ...erafActions] || [];
    },
    handleEditModeDataDisplay(data, configuredActionData, value) {
      if (configuredActionData != null) {
        const extraActions =
          this.getAvailableExtraActions(configuredActionData);

        if (
          configuredActionData.actionsOccur ||
          extraActions ||
          configuredActionData.actionsNotAvailable
        ) {
          if (extraActions) {
            extraActions.map((items) => {
              if (!items.isHidden) {
                this.extraActionsBtn.push(items);
              }
            });
          }
          if (configuredActionData.actionsOccur) {
            this.extraActionsBtn.push(...configuredActionData.actionsOccur);
          }
          if (configuredActionData.actionsNotAvailable) {
            configuredActionData.actionsNotAvailable.map((items) => {
              if (!items.isHidden) {
                this.actionsNotAvailable.push(items);
              }
            });
          }
        } else {
          const defaultExtraActions =
            this.getAvailableExtraActions(configuredActionData);

          defaultExtraActions.map((items) => {
            if (!items.isHidden) {
              this.extraActionsBtn = defaultExtraActions;
            }
          });
          defaultActionJson.actionsNotAvailable.map((items) => {
            if (!items.isHidden) {
              this.actionsNotAvailable = defaultActionJson.actionsNotAvailable;
            }
          });
          this.getQuestionnariesData();
        }
      } else {
        const defaultExtraActions =
          this.getAvailableExtraActions(defaultActionJson);

        defaultExtraActions.map((items) => {
          if (!items.isHidden) {
            this.extraActionsBtn = defaultExtraActions;
          }
        });
        defaultActionJson.actionsNotAvailable.map((items) => {
          if (!items.isHidden) {
            this.actionsNotAvailable = defaultActionJson.actionsNotAvailable;
          }
        });
        this.getQuestionnariesData();
      }
      this.extraActionsBtn.map((actions) => {
        actions.imgName = "";
        actions.backgroundColor = "";
      });
      if (value != null && value != "") {
        if (typeof value == "string") {
          this.extraActionsBtn.map((actions) => {
            if (actions.actionType == value || actions.gridBtntext == value) {
              actions.isActive = true;
              actions.backgroundColor = "#d0f2d2";
            }
          });
        } else {
          this.extraActionsBtn.map((actions) => {
            value.map((data) => {
              if (actions.actionType == data || actions.gridBtntext == data) {
                actions.isActive = true;
                actions.backgroundColor = "#d0f2d2";
              }
            });
          });
        }
        this.disableUpdateBtn = false;
      }
      this.associatesUsers = [];
      this.orignalAssociatesUsers = [];
      // srch_id_enc
      if (data) {
        let actionData = data.meta_json ? JSON.parse(data.meta_json) : {};
        this.actionTakenData = actionData.action_taken
          ? actionData.action_taken
          : [];
        let removedActionsArray = actionData.remove_actions
          ? actionData.remove_actions
          : [];

        if (
          (data.negativeMediaKeywordTotalScoring &&
            data.negativeMediaKeywordTotalScoring != null) ||
          data.negativeMediaKeywordTotalScoring != "null" ||
          (data.futureRiskKeywordTotalScoring &&
            data.futureRiskKeywordTotalScoring != null) ||
          data.futureRiskKeywordTotalScoring != "null"
        ) {
          this.actionTakenData.map((action) => {
            if (action.actionType == "apri") {
              action.imgName = "";
            }
          });
        }
        if (data.risk_rating) {
          this.selectedRiskRatingText =
            this.riskRatingJsonData.myRiskRatingData.status[
              data.risk_rating
            ].text;
          this.selectedRiskRatingIcon = this.getRiskRatingIcon(
            data.risk_rating,
            iconSizes.LARGE
          );
        }

        this.thirdpartyid = data.thirdpartyid;
        localStorage.setItem("editThirdPartyId", this.thirdpartyid);
        this.associatesUsers = [];
        this.form.entityThirdPartyUrl = data.website;
        this.form.entityName = data.comp_name_eng;
        this.form.firstName = data.comp_name_eng;
        this.entityTypeSelect = data.tpi_type;
        this.form.country = data.country;
        this.selectedCountryName = data.country;
        let languageIndex = -1;
        if (
          data.third_party_primary_contact &&
          data.third_party_primary_contact.language
        ) {
          languageIndex = this.formsLanguagesList.findIndex(
            (x) => x.languageCode == data.third_party_primary_contact.language
          );
        }
        this.additionalDetailForm = {
          address: data.address,
          city: data.city,
          state: data.state,
          postal_code: data.postal_code,
          contactName: data.inf_contact_name,
          contactEmail: data.third_party_primary_contact
            ? data.third_party_primary_contact.email_address
              ? data.third_party_primary_contact.email_address
              : data.lg_email
            : data.lg_email,
          contactPhone: data.tel_no,
          contactLanguage:
            languageIndex != -1 && data.third_party_primary_contact
              ? data.third_party_primary_contact.language
                ? this.formsLanguagesList[languageIndex].languages
                : this.additionalDetailForm.contactLanguage
              : this.additionalDetailForm.contactLanguage,
          contactLanguageCode: data.third_party_primary_contact
            ? data.third_party_primary_contact.language
              ? data.third_party_primary_contact.language
              : this.additionalDetailForm.contactLanguageCode
            : this.additionalDetailForm.contactLanguageCode
        };
        this.systemOwnerId = parseInt(data.user_id) || null;
        this.setSelectedSystemOwner(data.user_id);
        this.userTyping = !!(
          this.additionalDetailForm.contactEmail != null &&
          this.additionalDetailForm.contactEmail
        );
        this.form.registrationNumber = data.registration_number;
        this.riskRatingValue = data.risk_rating;

        this.onEditRiskRatingValue = data.risk_rating;
        this.completedActions = this.makeActionTakenAllIsActive(
          this.actionTakenData
        );
        this.removedActions = removedActionsArray;
        this.removePerformedActions();

        if (this.entityTypeSelect == "P") {
          let checkCompanyIncludes = data.comp_name_eng.includes("(");
          if (checkCompanyIncludes) {
            this.form.searchAssociatedCompaniesValue = "yes";
          } else {
            this.form.searchAssociatedCompaniesValue = "no";
          }
        }

        this.searchTpiId = data.srch_tpis_latest.id;
        data.srch_tpi_assos.forEach((element) => {
          if (element.asso_name) {
            this.associatesUsers.push({
              firstName: null,
              assoName: element.asso_name,
              entityType: element.srch_type,
              id: element.meta_data ? JSON.parse(element.meta_data).id : null,
              position: element.asso_posi,
              meta_data: element.meta_data
                ? JSON.parse(element.meta_data)
                : null,
              validationStateEntityType: null,
              validationStateAssosName: null
            });
            this.orignalAssociatesUsers.push({
              firstName: null,
              assoName: element.asso_name,
              entityType: element.srch_type,
              id: element.meta_data ? JSON.parse(element.meta_data).id : null,
              position: element.asso_posi,
              meta_data: element.meta_data
                ? JSON.parse(element.meta_data)
                : null,
              validationStateEntityType: null,
              validationStateAssosName: null
            });
          } else {
            this.associatesUsers = [
              {
                firstName: null,
                assoName: null,
                entityType: "P",
                id: null,
                position: null,
                meta_data: null,
                validationStateEntityType: null,
                validationStateAssosName: null
              }
            ];
            this.orignalAssociatesUsers = [
              {
                firstName: null,
                assoName: null,
                entityType: "P",
                id: null,
                position: null,
                meta_data: null,
                validationStateEntityType: null,
                validationStateAssosName: null
              }
            ];
          }
        });
        if (data.srch_tpi_assos.length == 0) {
          this.associatesUsers = [
            {
              firstName: null,
              assoName: null,
              entityType: "P",
              id: null,
              position: null,
              meta_data: null,
              validationStateEntityType: null,
              validationStateAssosName: null
            }
          ];
          this.orignalAssociatesUsers = [
            {
              firstName: null,
              assoName: null,
              entityType: "P",
              id: null,
              position: null,
              meta_data: null,
              validationStateEntityType: null,
              validationStateAssosName: null
            }
          ];
        }
        if (
          data.tp_custom_att_searches &&
          data.tp_custom_att_searches.length > 0
        ) {
          data.tp_custom_att_searches.map((items) => {
            if (items.tp_type === attributeType.MULTISELECT_COUNTRIES) {
              items.tp_customatt_values = `${
                JSON.parse(items.tp_customatt_values).id
              }`;
            }
            if (
              this.customAttributesValues &&
              this.customAttributesValues[items.tp_customatt_id] &&
              this.customAttributesValues[items.tp_customatt_id].value
            ) {
              let value1 =
                this.customAttributesValues[items.tp_customatt_id].value;
              this.customAttributesValues[items.tp_customatt_id].value =
                value1.concat(", ", items.tp_customatt_values);
            } else {
              this.customAttributesValues[items.tp_customatt_id] =
                this.makeCustomAttributesValueObj(items);
            }
          });
        }
        this.getResultPreviewData(data);
      }
      this.showingReviewResult = true;
      this.showModal = true;
      this.editModalShow = true;
      this.$store.state.isTpEditModal = true;
    },
    removePerformedActions() {
      this.completedActions.map((takkenActions) => {
        if (takkenActions.actionType == "idd") {
          let iddTypeIndex = this.extraActionsBtn.findIndex(
            (x) => x.actionType == "idd"
          );
          if (iddTypeIndex != -1) {
            this.extraActionsBtn.splice(iddTypeIndex, 1);
          }
        }
        if (takkenActions.actionType == "idd_plus") {
          let iddPlusTypeIndex = this.extraActionsBtn.findIndex(
            (x) => x.actionType == "idd_plus"
          );
          if (iddPlusTypeIndex != -1) {
            this.extraActionsBtn.splice(iddPlusTypeIndex, 1);
          }
        }
      });
    },
    onSelectReScreeningActionOccur(index, action) {
      if (action.actionType != "edd") {
        this.reScreeningActions.find((btn) => {
          if (btn.id == action.id) {
            btn.isActive = !btn.isActive;
          }
        });
      }
    },
    onSelectReScreeningActionOccurEdd(index, action) {
      this.reScreeningActions.find((btn) => {
        if (btn.id == action.id) {
          btn.isActive = !btn.isActive;
        }
      });
    },
    onSelectExtraActionOccur(action) {
      if (action.actionType != "edd") {
        this.extraActionsBtn.find((btn) => {
          if (btn.id == action.id) {
            btn.isActive = !btn.isActive;
          }
        });
        let activeActions = this.extraActionsBtn.filter((btn) => btn.isActive);
        if (activeActions.length && this.editModalShow) {
          this.disableUpdateBtn = false;
        } else {
          this.disableUpdateBtn = true;
        }
      }
    },
    onSelectExtraActionOccurEdd(action) {
      this.extraActionsBtn.find((btn) => {
        if (btn.id == action.id) {
          btn.isActive = !btn.isActive;
        }
        if (!this.changeListView && btn.isActive) {
          this.showDropDownModal = true;
        }
      });
      let activeActions = this.extraActionsBtn.filter((btn) => btn.isActive);
      if (activeActions.length && this.editModalShow) {
        this.disableUpdateBtn = false;
      } else {
        this.disableUpdateBtn = true;
      }
    },
    onSelectExtraActionOccurTCert(action) {
      action.isActive = !action.isActive;
      this.showDropDownModalForTCert = !this.changeListView && action.isActive;
    },
    onSelectExtraActionOccurEsgm(action) {
      action.isActive = !action.isActive;
      this.showDropDownModalForEsgm = !this.changeListView && action.isActive;
    },
    resetEntityDetails() {
      this.form = {
        country: null,
        entityName: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null
      };
      this.showExistingTable = false;
      this.existingItems = [];
      this.currentPageExistingItems = 1;
      this.perPageExistingItems = 5;
      this.fromRowsExistingItems = 0;
      this.toRowsExistingItems = 0;
      this.totalRowsExistingItems = 0;
      this.selectedThirdParty = null;
      this.v$.$reset();
    },
    resetForm() {
      this.clearOpenCorporateSectionData();
      this.isChangeInAssociates = false;
      this.invalidEmailDomain = false;
      this.invalidEmailDomainValue = null;
      this.invalidUrlDomain = false;
      this.invalidUrlDomainValue = null;
      this.resultsFetchDisable = false;
      this.userTyping = false;
      this.editTpName = null;
      this.associatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.orignalAssociatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: "P",
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.actionTakenData = [];
      this.form = {
        country: null,
        entityName: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null
      };
      this.riskRatingComment = null;
      this.riskRatingValue = 0;
      this.onChangeRatingStatus = true;
      this.validateRiskRatingComment = null;
      this.riskRatingNotes = null;
      this.onEditRiskRatingValue = null;
      this.additionalDetailForm = {
        address: null,
        city: null,
        state: null,
        postal_code: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        contactLanguage: this.additionalDetailForm.contactLanguage,
        contactLanguageCode: this.additionalDetailForm.contactLanguageCode
      };
      this.actionsOccurBtns = [];
      this.extraActionsBtn = [];
      this.actionsNotAvailable = [];
      this.completedActions = [];
      this.showingReviewResult = false;
      this.entityTypeSelect = "O";
      this.isLoadingResultReview = false;
      this.hideSubmitButton = false;
      this.showCreditsAlert = false;
      this.editModalShow = false;
      this.$store.state.isTpEditModal = false;
      this.selecteditem = null;
      this.selectedItemArray = [];
      this.allElements = [];
      this.showDropDownModal = false;
      this.collapse = {
        EntityVerification: true,
        AssociateEntities: true,
        AdditionalDetails: true,
        CustomAttributes: true
      };
      this.showExistingTable = false;
      this.existingItems = [];
      this.currentPageExistingItems = 1;
      this.perPageExistingItems = 5;
      this.fromRowsExistingItems = 0;
      this.toRowsExistingItems = 0;
      this.totalRowsExistingItems = 0;
      this.selectedThirdParty = null;
      this.disableUpdateBtn = true;
      this.setCustomAttributesValues();
      this.customAttributesValuesData = {};
      this.selectedTCertItem = this.makeOptionObj();
      this.selectedEsgmItem = this.makeOptionObj();
      this.makeTCertificationOptions();
      this.makeESGMetricsOptions();
      this.v$.$reset();
    },
    closeModal() {
      this.showModal = false;
      this.resetThirdPartyDetails();
      this.setThirdPartyDetailsMessage("");
      this.setIsThirdPartyAddModalVisible(false);
      this.setIsThirdPartyNewModalVisible(false);
      this.setIsAddThirdPartyModalNewEditable(false);
      this.setThirdPartySelectedId("");
      this.resetForm();
    },
    addAssociateRow() {
      this.disableUpdateBtn = false;

      this.associatesUsers.push({
        firstName: null,
        assoName: null,
        entityType: "P",
        id: null,
        position: null,
        meta_data: null,
        validationStateEntityType: null,
        validationStateAssosName: null
      });
      this.checkAssociateChange();
    },
    removeFirstRowAssociateRow(index, users) {
      this.disableUpdateBtn = false;

      if (this.associatesUsers.length > 1) {
        this.removeAssociateRow(index, users);
      } else {
        let tableAltaresSuggestedAssociatesIndex = 0;
        tableAltaresSuggestedAssociatesIndex =
          this.openCorporatesCompanyOfficersItems.findIndex(
            (x) => x.id == users.id
          );
        this.selectedCompanyOfficers[
          tableAltaresSuggestedAssociatesIndex
        ] = false;
        this.associatesUsers = [
          {
            assoName: null,
            entityType: "P",
            id: uid(),
            position: null,
            meta_data: null,
            validationStateEntityType: null,
            validationStateAssosName: null
          }
        ];
        this.checkAssociateChange();
      }
    },
    removeAssociateRow(index, users) {
      this.disableUpdateBtn = false;

      let tableAltaresSuggestedAssociatesIndex = 0;
      tableAltaresSuggestedAssociatesIndex =
        this.openCorporatesCompanyOfficersItems.findIndex(
          (x) => x.id == users.id
        );

      this.selectedCompanyOfficers[
        tableAltaresSuggestedAssociatesIndex
      ] = false;
      this.associatesUsers.splice(index, 1);
      this.checkAssociateChange();
    },
    resetPaginationCountryCorporate() {
      this.selectedRowWithCountry = null;
    },
    resetPaginationGlobalCorporate() {
      this.selectedRowWithoutCountry = null;
    },
    resetPaginationIndividual() {
      this.selectedIndividualRowCountry = null;
    },
    async getTatCost() {
      this.isLoadingPackages = true;
      let formData = {
        country: this.selectedCountryName,
        client_id: this.companyId,
        edd_default_region: this.companyDetails.eddDefaultRegion
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/all/packages",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          result.data.data.map((data) => {
            if (data.packagesId == this.selectedId) {
              this.selectedItemArray = data;
            }
          });
          this.extraActionsBtn.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = true;
            }
          });
          this.isLoadingPackages = false;
        } else if (result.data.status == 0) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.extraActionsBtn.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = false;
            }
          });
          this.isLoadingPackages = false;
        }
      } catch (error) {
        this.isLoadingPackages = false;
        return [error];
      }
    },
    checkValue() {
      if (
        (this.entityTypeSelect === thirdPartyType.ORGANIZATION &&
          this.selectedCountryName &&
          this.selecteditem) ||
        (this.entityTypeSelect === thirdPartyType.INDIVIDUAL &&
          this.selecteditem)
      ) {
        this.getTatCost();
      }
    },
    setSelectedItem(val) {
      this.selecteditem = val.name;
      this.selectedId = val.id;
      this.getElementsByType();
      this.checkValue();
    },
    async getElementsByType() {
      this.isLoadingElements = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/client/packages/selected/products/${this.companyId}/${this.selectedId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.allElements = result.data;
          this.isLoadingElements = false;
        }
      } catch (error) {
        this.isLoadingElements = false;
        return [error];
      }
    },
    showConfirmModal(id) {
      this.thirdPartyID = id;
      this.$refs["modal-switch-tp"].show();
    },
    hideSwitchModal() {
      this.selectedThirdParty = null;
      this.$refs["modal-switch-tp"].hide();
    },
    navigateToThirdPartyProfile(thirdPartyId = this.thirdPartyID) {
      this.$router.push(
        urls.THIRD_PARTY_PROFILE_COMPANY(thirdPartyId, this.companyId)
      );
    },
    checkAssociateChange() {
      let error = [];
      this.orignalAssociatesUsers.map((items) => {
        let index = this.associatesUsers.findIndex(
          (x) =>
            x.assoName == items.assoName && x.entityType == items.entityType
        );
        if (index != -1) {
          error.push(false);
        } else {
          error.push(true);
        }
      });
      let checker = error.every((v) => v == false);
      if (
        !checker ||
        this.associatesUsers.length != this.orignalAssociatesUsers.length
      ) {
        this.isChangeInAssociates = true;
        this.isChangeAssociateUpdate = true;
      } else {
        this.isChangeInAssociates = false;
        this.isChangeAssociateUpdate = false;
      }
    },
    makeOptionObj() {
      return {
        text: "",
        value: ""
      };
    },
    selectedActionTheme(isActive) {
      return isActive ? themes.SUCCESS_WHITE : themes.SUCCESS_INVERSE_WHITE;
    },
    getSelectedIcon(isActive) {
      return {
        icon: icons.PLUS,
        shape: shapes.SQUARE,
        theme: this.selectedActionTheme(isActive),
        border: this.isThirdPartyArchived ? border.DASHED : border.SOLID,
        size: 25
      };
    }
  },
  beforeDestroy() {
    this.resetThirdPartyDetails();
    this.v$.$reset();
  }
};
</script>

<style lang="scss" scoped>
.input-select input,
input::-webkit-input-placeholder {
  font-size: 14px !important;
}

.card-header {
  padding: 6px !important;
}
.cols-8-height-styles {
  overflow-y: auto;
  height: calc(100vh - 400px);
}

.cols-4-height-styles {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.cols-8-with-voucheralert-height-styles {
  overflow-y: auto;
  height: calc(100vh - 435px);
}

.cols-4-with-voucheralert-height-styles {
  overflow-y: auto;
  height: calc(100vh - 280px);
}

.vs__search {
  margin: 2px 0 0 !important;
}

.d-flex {
  text-align: left;
}

.risk-rating-drop-down > li > .dropdown-item:focus {
  background-color: $white;
}

.risk-rating-drop-down > li > .dropdown-item:hover {
  background-color: $white;
}

.popover {
  max-width: 400px !important;
}

.errorClass {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $spanish-red;
}

.add-tp {
  &__previously-performed-actions {
    display: flex;
    align-items: center;

    &-completed {
      @include body-text-bold;
      font-size: 13px;
      padding: 4px 7px;
      line-height: 1;
      border-radius: 4px;
      background-color: $robin-egg-blue;
      color: $white;
    }
  }

  &__tcertification-text {
    &--archivedClass {
      @include body-text-bold;
      color: $chinese-silver;
    }
    &--bold {
      @include body-text-bold;
    }
  }
}

.add-third-party-modal {
  padding-left: 0;

  &__system-owner-tooltip {
    margin-left: 4px;
  }

  &__contact-language {
    margin-bottom: 32px;
  }

  &__available-actions {
    display: inline-block;
    text-align: center;
    padding: 8px 0;
  }

  &__actions {
    display: flex;
  }

  &__risk-rating {
    display: flex;
    align-items: flex-end;
  }
}
</style>

<style lang="scss">
.modal-dialog-styles {
  margin: 87px 5px 5px 5px !important;
  transform: none;
  max-width: 100% !important;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90% !important;
}
.modal-content-styles {
  max-width: 100% !important;
  margin-top: 90px;
  border: 5px solid $platinum;
}

.modal-open-styles {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  padding-left: 0px !important;
  z-index: 9999;
}

.main-wrapper-modal {
  z-index: 9999;
}

.modal-footer-styles {
  padding: 2px !important;
}

.modal-header-styles {
  padding: 10px 10px !important;
}
</style>
