<template>
  <div>
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-row>
        <b-col cols="12">
          <div class="scrollFlow">
            <b-navbar toggleable="lg" type="dark" variant="light">
              <b-navbar-brand href="#" class="text-dark">
                <strong>{{ $t("eb360.questionnaireComponent.title") }}</strong>
              </b-navbar-brand>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                  <b-nav-form>
                    <!-- Reload button -->
                    <b-button
                      class="mr-2"
                      variant="outline-info"
                      size="sm"
                      @click="reloadTable"
                    >
                      <font-awesome-icon icon="sync" />
                    </b-button>

                    <!-- Search bar -->
                    <b-form-group>
                      <b-input-group size="sm">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          :placeholder="
                            $t('eb360.manageThirdParties.searchPlaceholder')
                          "
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">{{
                            $t("clearBtnText")
                          }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-nav-form>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
            <!-- Questionnaires table data start -->
            <b-table
              responsive="sm"
              :sort-by.sync="sortBy"
              borderless
              hover
              fixed
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :items="getTpQuestionnairesAndCommunications"
              :fields="fields"
              style="word-wrap: break-word"
              id="questionare-table"
            >
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="field.width ? 'width:' + field.width : ''"
                />
              </template>
              <template v-slot:cell(contact)="data">
                <div v-if="data.item.contact">
                  <p>{{ data.item.contact }}</p>
                </div>
                <div v-else>
                  <p>
                    {{
                      primaryContactDetail
                        ? primaryContactDetail.email_address
                        : "N/A"
                    }}
                  </p>
                </div>
              </template>
              <template v-slot:cell(title)="data">
                <div>
                  <div v-if="data.item.status == 4">
                    <a @click="openUrl(data)" href="#">{{ data.item.title }}</a>
                  </div>
                  <div v-else>
                    <p>{{ data.item.title }}</p>
                  </div>
                  <div
                    v-if="
                      parseInt(data.item?.is_scored) === ddqScoring.IS_SCORED
                    "
                  >
                    <div
                      v-if="
                        data.item.status == 4 &&
                        data.item.form_data.risk_data &&
                        data.item.form_data.risk_data.data.length &&
                        data.item.form_data.risk_data.data[0].sectionTitle
                      "
                    >
                      <b-badge
                        :class="
                          getBadgeColor(data.item.form_data.risk_data.color)
                        "
                        >{{ data.item.form_data.risk_data.rating }}</b-badge
                      >
                    </div>
                    <span v-else>
                      <div v-if="data.item.status == 4">
                        <div v-if="checkRiskScoreColor(data) == 1">
                          <b-badge class="text-white high-risk-score-style">{{
                            getRiskDataScore(data)
                          }}</b-badge>
                        </div>
                        <div v-if="checkRiskScoreColor(data) == 2">
                          <b-badge class="text-white low-risk-score-style">{{
                            getRiskDataScore(data)
                          }}</b-badge>
                        </div>
                        <div v-if="checkRiskScoreColor(data) == 3">
                          <b-badge
                            class="text-white modrate-risk-score-style"
                            >{{ getRiskDataScore(data) }}</b-badge
                          >
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:cell(actions)="data">
                <div v-if="riskDataCondition(data) == 1">
                  <p
                    @click="data.toggleDetails"
                    v-if="
                      data.item.status == 4 &&
                      data.item.form_data.risk_data &&
                      data.item.form_data.risk_data.data &&
                      data.item.form_data.risk_data.data.length
                    "
                  >
                    <b-avatar
                      variant="success"
                      size="30"
                      style="
                        background-color: unset;
                        border: 1px solid #6bdc99;
                        color: #6bdc99;
                      "
                    >
                      <font-awesome-icon
                        :icon="data.detailsShowing ? 'minus' : 'plus'"
                        :title="
                          $t('eb360.questionnaireComponent.questionnaireDetail')
                        "
                      />
                    </b-avatar>
                  </p>
                </div>
              </template>
              <template v-slot:row-details="data">
                <div
                  v-if="
                    riskDataCondition(data) == 1 &&
                    data.item.form_data.risk_data &&
                    data.item.form_data.risk_data.data.length &&
                    data.item.form_data.risk_data.data[0].sectionTitle
                  "
                >
                  <div
                    v-for="(sectionData, index) in data.item.form_data.risk_data
                      .data"
                    :key="index"
                  >
                    <b-badge :class="getBadgeColor(sectionData.color)"
                      >{{ sectionData.sectionTitle }} ({{
                        sectionData.score
                      }})</b-badge
                    >
                  </div>
                </div>
                <div v-else-if="riskDataCondition(data) == 1">
                  <div
                    v-for="(detailData, index) in data.item.form_data.risk_data
                      .data"
                    :key="index"
                  >
                    <div v-if="checkDetailColor(detailData) == 1">
                      <b-badge
                        class="text-white high-risk-score-style"
                        style="text-transform: capitalize"
                        >{{ setDetailData(detailData) }}</b-badge
                      >
                    </div>
                    <div v-if="checkDetailColor(detailData) == 2">
                      <b-badge
                        class="text-white low-risk-score-style"
                        style="text-transform: capitalize"
                        >{{ setDetailData(detailData) }}</b-badge
                      >
                    </div>
                    <div v-if="checkDetailColor(detailData) == 3">
                      <b-badge
                        class="text-white modrate-risk-score-style"
                        style="text-transform: capitalize"
                        >{{ setDetailData(detailData) }}</b-badge
                      >
                    </div>
                    <div v-if="checkDetailColor(detailData) == 4">
                      <span
                        style="text-transform: capitalize; font-size: 15px"
                        >{{ setDetailData(detailData) }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>{{ $t("eb360.questionnaireComponent.noData") }}</p>
                </div>
                <div class="text-center">
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="data.toggleDetails"
                    >{{
                      $t("eb360.questionnaireComponent.hideDetails")
                    }}</b-button
                  >
                </div>
              </template>
              <template v-slot:cell(requested_at)="data">
                {{ dateFormat(data.item.requested_at) }}
              </template>
              <template v-slot:cell(completed_at)="data">
                {{
                  data.item.status == 4
                    ? dateFormat(data.item.completed_at)
                    : "N/A"
                }}
              </template>
              <template v-slot:cell(reassign)="data">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-v" />
                  </template>
                  <b-dropdown-text
                    v-if="
                      data.item.status == 1 &&
                      data.item.task_id &&
                      data.item.form_data.import &&
                      data.item.form_data.import == 1
                    "
                  >
                    <a
                      style="color: black !important; font-weight: 400"
                      :href="`/user-form-page/${data.item.task_id}/${data.item.task_user_id}`"
                    >
                      Force Complete
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-item-button
                    v-if="data.item.status == 4"
                    :class="
                      data.item.status != 4
                        ? '.text-black-50'
                        : 'text-secondary'
                    "
                    :disabled="data.item.status != 4"
                    @click="openUrl(data)"
                    target="_blank"
                    >{{
                      $t("eb360.documentsTabComponent.view")
                    }}</b-dropdown-item-button
                  >
                  <b-dropdown-text
                    v-else
                    :class="
                      data.item.status != 4
                        ? '.text-black-50'
                        : 'text-secondary'
                    "
                    :disabled="
                      !(data.item.status == 1 || data.item.status == 14) ||
                      !(isUserSuperAdminOrAdmin && data.item.status != 0)
                    "
                  >
                    <router-link
                      :style="
                        !(data.item.status == 1 || data.item.status == 14) ||
                        !(isUserSuperAdminOrAdmin && data.item.status != 0)
                          ? 'color:black !important;font-weight: 400;pointer-events: none;cursor: default;'
                          : 'color:black !important;font-weight: 400;'
                      "
                      :to="`/user-form-page/${data.item.task_id}/${data.item.task_user_id}?tp=${thirdPartyId}`"
                    >
                      {{ $t("eb360.documentsTabComponent.view") }}
                    </router-link>
                  </b-dropdown-text>
                  <b-dropdown-item-button
                    :disabled="data.item.status > 3 && data.item.status != 14"
                    :class="
                      data.item.status != 4
                        ? '.text-black-50'
                        : 'text-secondary'
                    "
                    @click="openReSendQuestionnaire(data)"
                    >{{
                      $t("eb360.questionnaireComponent.resend")
                    }}</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    :disabled="data.item.status > 3 || data.item.status == 14"
                    :class="
                      data.item.status != 4
                        ? '.text-black-50'
                        : 'text-secondary'
                    "
                    @click="sendReminder(data)"
                    >Send Reminder</b-dropdown-item-button
                  >
                  <b-dropdown-item @click="onShowEditModal(data)">{{
                    $t("eb360.policiesComponent.edit")
                  }}</b-dropdown-item>
                  <b-dropdown-item @click="onShowDeleteModal(data)">{{
                    $t("deleteBtnText")
                  }}</b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-slot:cell(status_link)="data">
                <div>
                  <p v-b-tooltip :title="data.item.notes">
                    {{ renderStatus(data.item.status) }}
                  </p>
                </div>
              </template>
            </b-table>
            <!-- Questionnaires table data end -->
            <b-row>
              <b-col cols="2">
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
              </b-col>
              <b-col>
                <b-pagination
                  class="float-right"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Resend Questionnaire overlay start -->
    <b-overlay
      :show="reSendQuestionnairePopUp"
      no-wrap
      bg-color="#343a40"
      :style="questionnaireStatus == 1 ? 'height: 21rem;' : 'height: 30rem;'"
    >
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
          style="color: #fff"
        >
          <font-awesome-icon size="lg" icon="cloud-upload-alt" />
          <div class="mb-3">
            {{ $t("eb360.questionnaireComponent.assigning") }}
          </div>
          <b-progress
            min="1"
            max="20"
            :value="counter"
            variant="success"
            height="3px"
            class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
          style="width: 50rem; color: #fff"
        >
          <div class="text-left" style="margin-top: 30px">
            <p v-if="questionnaireStatus == 0">
              {{ $t("eb360.questionnaireComponent.requesting") }}
              <b>{{ questionnaireName }}</b>
              {{ $t("eb360.questionnaireComponent.primaryContact") }}
              <br />
              {{ $t("eb360.questionnaireComponent.thisIs") }}
              <br />
              <b>
                <br />
                {{
                  $t("eb360.questionnaireComponent.primaryContactName")
                }}&nbsp;&nbsp;{{ getPrimaryContactName() }}
                <br />
                {{
                  $t("eb360.questionnaireComponent.primaryContactEmail")
                }}&nbsp;&nbsp;{{
                  primaryContactDetail
                    ? primaryContactDetail.email_address
                      ? primaryContactDetail.email_address
                      : "N/A"
                    : "N/A"
                }}
              </b>
            </p>

            <div v-else-if="questionnaireStatus == 1">
              <p>
                {{ $t("eb360.questionnaireComponent.requesting") }}
                <b>{{ questionnaireName }}</b>
                {{ $t("eb360.questionnaireComponent.primaryContact") }}
                <br />
                {{ $t("eb360.questionnaireComponent.thisIs") }}
                <br />
                <b>
                  <br />
                  {{
                    $t("eb360.questionnaireComponent.primaryContactName")
                  }}&nbsp;&nbsp;{{ getPrimaryContactName() }}
                  <br />
                  {{
                    $t("eb360.questionnaireComponent.primaryContactEmail")
                  }}&nbsp;&nbsp;{{
                    primaryContactDetail
                      ? primaryContactDetail.email_address
                        ? primaryContactDetail.email_address
                        : "N/A"
                      : "N/A"
                  }}
                </b>
              </p>
              <p>{{ $t("eb360.questionnaireComponent.pleaseNote") }}</p>
            </div>
            <div v-else>
              <p>
                {{ $t("eb360.questionnaireComponent.requesting") }}
                <b>{{ questionnaireName }}</b>
                {{ $t("eb360.questionnaireComponent.primaryContact") }}
                <br />
                {{ $t("eb360.questionnaireComponent.thisIs") }}
                <br />
                <b>
                  <br />
                  {{
                    $t("eb360.questionnaireComponent.primaryContactName")
                  }}&nbsp;&nbsp;{{ getPrimaryContactName() }}
                  <br />
                  {{
                    $t("eb360.questionnaireComponent.primaryContactEmail")
                  }}&nbsp;&nbsp;{{
                    primaryContactDetail
                      ? primaryContactDetail.email_address
                        ? primaryContactDetail.email_address
                        : "N/A"
                      : "N/A"
                  }}
                </b>
              </p>
              <p>{{ $t("eb360.questionnaireComponent.pleaseNote") }}</p>
            </div>
          </div>
          <div class="d-flex" style="justify-content: flex-end">
            <b-button
              variant="danger"
              class="mr-3"
              @click="resetReSendQuestionnairePopUp"
              >{{ $t("cancelBtnText") }}</b-button
            >
            <b-button
              variant="success"
              @click="reSendreSendQuestionnaireCall"
              >{{ $t("sendBtn") }}</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
    <!-- Resend Questionnaire overlay end -->

    <!-- Modal for Edit Questionnaire start -->
    <b-modal
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      v-model="showEditModal"
      title="Edit Due Diligence Questionnaire"
    >
      <div style="margin: 0 30px">
        <b-overlay :show="isSubmiting">
          <p><b>Contact :</b> {{ contactEmail }}</p>
          <p>
            <b>Current Status :</b>
            <b-badge class="ml-4" variant="success">{{
              this.statusText
            }}</b-badge>
          </p>
          <!-- without validation select status -->
          <b-form-group class="mt-2">
            <template #label>
              <b>Change Status To :</b>
            </template>
            <b-form-select
              id="status-feedback"
              v-model="statusSelect"
              :options="optionsStatus"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="status-feedback"
              >Please select a status.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group class="mt-2">
            <Checkbox
              id="checkbox-1"
              v-model="isRetain"
              label="Retain any answer questions on status change"
            />
          </b-form-group>
          <b-form-group
            description="Comment can be of maximum 250 characters"
            class="mt-2"
          >
            <template #label>
              <b>Notes :</b>
            </template>
            <b-form-textarea
              maxlength="250"
              v-model="v$.notes.$model"
              :state="v$.notes.$dirty ? !v$.notes.$error : null"
              id="notes-feedback"
            ></b-form-textarea>
            <b-form-invalid-feedback id="notes-feedback"
              >Notes can be of 250 characters.</b-form-invalid-feedback
            >
          </b-form-group>
          <div>
            <b>Supporting docs :</b>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-success="vsuccess"
              v-on:vdropzone-sending="sendingEvent"
              :useCustomSlot="true"
              class="m-1 p-2"
              style="border: dashed 1px"
              type="file"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{ $t("upload.dragDrop") }}
                </h3>
                <div class="subtitle">{{ $t("upload.dragDropContnt") }}</div>
              </div>
            </vue-dropzone>
          </div>
        </b-overlay>

        <b-overlay :show="isLoadingGetFile">
          <b-row>
            <b-col cols="12">
              <b-table-simple striped hover>
                <b-thead>
                  <b-tr>
                    <b-th>{{ $t("upload.fileName") }}</b-th>
                    <b-th>{{ $t("upload.fileType") }}</b-th>
                    <b-th>{{ $t("upload.fileSize") }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-for="(item, index) in files" :key="index">
                  <b-tr>
                    <b-td>{{ item.filename }}</b-td>
                    <b-td>{{ item.type }}</b-td>
                    <b-td>{{ item.size }}</b-td>
                    <b-td>
                      <b-button
                        @click="onDeleteFile(index)"
                        variant="danger"
                        size="sm"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                      <b-button
                        class="ml-2"
                        @click="onDownloadFile(item)"
                        variant="success"
                        size="sm"
                      >
                        <font-awesome-icon icon="download" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-overlay>
      </div>
      <template #modal-footer>
        <b-button
          @click="closeEditModal"
          :disabled="isSubmiting || isLoadingGetFile"
          variant="outline-danger"
          >Close</b-button
        >
        <b-button
          :disabled="isSubmiting || isLoadingGetFile"
          @click="onSubmitModal"
          variant="outline-success"
          >Update</b-button
        >
      </template>
    </b-modal>
    <!-- Modal for Edit Questionnaire end -->

    <!-- Modal for Delete Questionnaire confirmation -->
    <b-modal
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      v-model="deleteModal"
      title="Delete Questionnaire"
    >
      Are you sure you want to delete this Questionnaire as you can not revert
      this action?
      <template #modal-footer>
        <b-button
          @click="onCloseDeleteModal"
          :disabled="isSubmiting"
          variant="outline-dark"
          >Cancel</b-button
        >
        <b-button
          :disabled="isSubmiting"
          @click="onSubmitDelete"
          variant="outline-danger"
          ><span v-if="isSubmiting"
            ><b-spinner small variant="danger"></b-spinner
          ></span>
          <span v-else>{{ $t("deleteBtnText") }}</span></b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HTTP, makeAuthorizationHeader } from "@/utils";
import moment from "moment";
import "moment/locale/es";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueDropzone from "../../../Dropzone/VueDropzone.vue";
import { Helper } from "@/helpers";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import { ddqScoring, getterName } from "@/constants";
import { mapGetters, mapState } from "vuex";
import { nextTick } from "vue";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "QuestionnairesComponent",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    vueDropzone: VueDropzone,
    Checkbox
  },
  data() {
    return {
      ddqScoring,
      files: [],
      fileFields: ["filename", "type", "size", ""],
      showEditModal: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}v1/uploads/file/ddq`,
        thumbnailWidth: 150,
        maxFilesize: 25,
        addRemoveLinks: true,
        acceptedFiles: [
          "application/pdf",
          "application/msword",
          "application/vnd.ms-excel",
          "application/vnd.ms-powerpoint",
          "text/plain",
          "image/jpeg",
          "image/png"
        ].join(),
        headers: makeAuthorizationHeader()
      },
      contactEmail: null,
      statusSelect: null,
      statusText: null,
      optionsStatus: [
        { value: null, text: "Please Select Status", disabled: true },
        // { value: null, text: 'In Progress', disabled: true },//1 status
        // { value: null, text: 'Completed', disabled: true },//4 status
        { value: "0", text: "Not Started" },
        { value: "1", text: "In Progress" },
        { value: "8", text: "Cancelled " },
        { value: "9", text: "Declined / Refused" },
        { value: "10", text: "Self Evidenced" },
        { value: "14", text: "In Progress - Pause Reminders" }
      ],
      isRetain: true,
      notes: null,
      taskId: null,
      formId: null,
      isSubmiting: false,
      isLoading: false,
      isLoadingGetFile: false,
      processing: false,
      counter: 1,
      interval: null,
      reSendQuestionnairePopUp: false,
      questionnaireName: null,
      questionnaireStatus: null,
      primaryContactDetail: {},
      thirdPartyDetail: [],
      isLoadingData: false,
      items: [],
      filter: null,
      pageOptions: [5, 10, 25, 50, 100],
      sortBy: "id",
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      eb2AppUrl: process.env.VUE_APP_BASE_URL,
      fields: [
        { key: "actions", label: "", width: "50px" },
        {
          key: "title",
          label: this.$t("eb360.policiesComponent.title"),
          width: "140px",
          sortable: true
        },
        {
          key: "requested_at",
          label: this.$t("eb360.policiesComponent.requested"),
          width: "110px",
          sortable: true
        },
        {
          key: "completed_at",
          label: this.$t("eb360.policiesComponent.completedDate"),
          width: "90px",
          sortable: true
        },
        {
          key: "contact",
          label: this.$t("eb360.policiesComponent.contact"),
          width: "160px",
          sortable: true
        },
        {
          key: "version",
          label: this.$t("eb360.policiesComponent.version"),
          width: "120px",
          sortable: true
        },
        {
          key: "status_link",
          label: this.$t("eb360.policiesComponent.statusLink"),
          width: "110px",
          sortable: true
        },
        {
          key: "reassign",
          label: this.$t("eb360.associateDetailModal.action"),
          width: "70px"
        }
      ],
      wordNumber: [
        { value: 0, text: this.$t("wordNumber.zero") },
        { value: 1, text: this.$t("wordNumber.one") },
        { value: 2, text: this.$t("wordNumber.two") },
        { value: 3, text: this.$t("wordNumber.three") },
        { value: 4, text: this.$t("wordNumber.four") },
        { value: 5, text: this.$t("wordNumber.five") },
        { value: 6, text: this.$t("wordNumber.six") },
        { value: 7, text: this.$t("wordNumber.seven") },
        { value: 8, text: this.$t("wordNumber.eight") },
        { value: 9, text: this.$t("wordNumber.nine") },
        { value: 10, text: this.$t("wordNumber.ten") }
      ],
      deleteModal: false,
      deleteRowId: null
    };
  },
  validations: {
    notes: {
      required,
      maxLength: maxLength(250)
    }
  },
  computed: {
    ...mapGetters({
      isUserSuperAdminOrAdmin: getterName.USER.IS_USER_SUPER_ADMIN_OR_ADMIN,
      fullName: getterName.USER.GET_FULL_NAME
    }),
    ...mapState({
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.items.length;
    }
  },
  watch: {
    thirdPartyData: {
      deep: true,
      handler() {
        this.reloadTable();
      }
    }
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    onShowDeleteModal(row) {
      this.deleteModal = true;
      this.deleteRowId = row.item.task_id;
    },
    onCloseDeleteModal() {
      this.deleteModal = false;
      this.deleteRowId = null;
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "questionare-table");
    },
    // call delete Questionnaire API
    async onSubmitDelete() {
      let formData = {
        task_id: this.deleteRowId
      };
      try {
        this.isSubmiting = true;
        let result = await HTTP(
          "post",
          "thirdparty/delete/ddq",
          formData,
          makeAuthorizationHeader()
        );
        if (result && result.data.success == 1 && result.status == 200) {
          this.isSubmiting = false;
          this.onCloseDeleteModal();
          this.reloadTable();
          this.$bvToast.toast(result.data.message, {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
        } else {
          this.isSubmiting = false;
          this.$bvToast.toast(result.data.message, {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
      } catch (err) {
        return [err];
      }
    },

    renderStatus(checkStatus) {
      switch (parseInt(checkStatus)) {
        case 0:
          return "Not Started";
        case 1:
          return "In Progress";
        case 2:
          return "On Hold";
        case 3:
          return "Review";
        case 4:
          return "Completed";
        case 5:
          return "Not Required";
        case 8:
          return "Cancelled";
        case 9:
          return "Declined / Refused";
        case 10:
          return "Self Evidenced";
        case 14:
          return "In Progress - Pause Reminders";
        default:
          return "";
      }
    },
    async getFileData() {
      this.isLoadingGetFile = true;

      try {
        let result = await HTTP(
          "get",
          `v1/get/files/${this.taskId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          let resultFiles = result.data;
          this.files.push(...resultFiles);
          this.isLoadingGetFile = false;
        }
      } catch (error) {
        this.isLoadingGetFile = false;
        this.isSubmiting = false;
        this.isLoading = false;
      }
    },
    // delete uploaded file
    onDeleteFile(index) {
      if (this.files.length) {
        this.onDeleteApi(this.files[index], index);
      }
    },
    // download uploaded file
    onDownloadFile(data) {
      let titleForm = data.filename;
      HTTP(
        "post",
        "v1/download/file",
        data,
        makeAuthorizationHeader(),
        null,
        false,
        "blob"
      )
        .then((response) => {
          if (window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([response.data]);
            // IE11
            window.navigator.msSaveOrOpenBlob(blob, titleForm);
            Helper.makeToast(
              this.$bvToast,
              "success",
              "File has been download successfully"
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", titleForm); // or any other extension
            document.body.appendChild(link);
            link.click();
            if (response) {
              Helper.makeToast(
                this.$bvToast,
                "success",
                "File has been download successfully"
              );
            }
          }
        })
        .catch((error) => {
          Helper.makeToast(this.$bvToast, "danger", "Unable to download file.");
          return [error];
        });
    },
    async onDeleteApi(file, index) {
      this.isLoadingGetFile = true;

      if (file && file.fileId) {
        let result = await HTTP(
          "post",
          "v1/thirdparty/delete_document",
          {
            doc_delete_type: "ddq-form",
            doc_id: file.fileId,
            third_party_id: this.thirdPartyId,
            company_id: this.companyId,
            user_id: this.userId
          },
          makeAuthorizationHeader(),
          null,
          false
        );
        if (result.data) {
          this.files.splice(index, 1);
          this.isLoadingGetFile = false;
        }
      }
    },
    vsuccess(file, response) {
      let uploadedFile = {
        filename: file.name,
        size: file.size,
        type: file.type,
        actions: "&nbsp;",
        fileId: response.fileId || null,
        url: response.finalPath || null
      };
      this.files.push(uploadedFile);
      this.$refs.myVueDropzone.removeFile(file);
    },
    verror(file) {
      Helper.makeToast(
        this.$bvToast,
        "danger",
        this.$t("validations.invalidFormat"),
        this.$t("toast.title")
      );
      this.$refs.myVueDropzone.removeFile(file);
    },

    // open edit Questionnaire modal
    onShowEditModal(data) {
      this.v$.$reset();
      this.showEditModal = true;
      if (data) {
        this.contactEmail = data.item.contact;

        if (data.item.status == 0) {
          this.statusText = "Not Started";
        } else if (data.item.status == 1) {
          this.statusText = "In Progress";
        } else if (data.item.status == 2) {
          this.statusText = "On Hold";
        } else if (data.item.status == 4) {
          this.statusText = "Completed";
        } else if (data.item.status == 8) {
          this.statusText = "Cancelled";
        } else if (data.item.status == 9) {
          this.statusText = "Declined / Refused";
        } else if (data.item.status == 10) {
          this.statusText = "Self Evidenced";
        } else if (data.item.status == 14) {
          this.statusText = "In Progress - Pause Reminders";
        }

        this.notes = data.item.notes;
        this.taskId = data.item.task_id;
        this.formId = data.item.form_id;
        this.getFileData();
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("formId", this.formId);
      formData.append("fileType", file.type);

      formData.append("taskId", this.taskId);
    },
    closeEditModal() {
      this.v$.$reset();
      this.showEditModal = false;
      this.isLoadingGetFile = false;

      this.contactEmail = null;
      this.statusSelect = null;
      this.statusText = null;
      this.files = [];
      this.notes = null;
      this.isRetain = true;
      this.taskId = null;
      this.formId = null;
    },

    // call update questionnaire status API
    async onSubmitModal() {
      // uncomment to submit form with validation
      this.v$.notes.$touch();
      if (this.v$.notes.$errors?.length) {
        return false;
      } else {
        let formData = {
          companyId: this.companyId,
          taskId: this.taskId,
          taskStatus: this.statusSelect,
          isRetain: this.isRetain,
          notes: this.notes,
          thirdPartyId: this.thirdPartyId,
          memberId: this.userId,
          third_party_name: this.thirdPartyData.comp_name_eng,
          user_name: this.fullName
        };
        this.isSubmiting = true;
        this.isLoading = true;
        try {
          let result = await HTTP(
            "post",
            "thirdparty/update/questionnaire/status",
            formData,
            makeAuthorizationHeader()
          );
          if (result.status == 200) {
            Helper.makeToast(
              this.$bvToast,
              "success",
              "Your Questionare have been updated",
              "Alert"
            );

            this.showEditModal = false;
            this.isSubmiting = false;
            this.isLoading = false;
            this.contactEmail = null;
            this.statusSelect = null;
            this.statusText = null;
            this.files = [];
            this.notes = null;
            this.isRetain = true;
            this.taskId = null;
            this.formId = null;
            this.reloadTable();
          }
        } catch (error) {
          this.showEditModal = false;
          this.isSubmiting = false;
          this.isLoading = false;
        }
      }
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    riskDataCondition(data) {
      let formData = data.item.form_data ? data.item.form_data : null;
      let value = formData.risk_data ? 1 : 0;
      return value;
    },
    openReSendQuestionnaire(data) {
      this.questionnaireName = data.item.title;
      this.questionnaireStatus = data.item.status;
      let name = this.getPrimaryContactName();
      let email = this.primaryContactDetail
        ? this.primaryContactDetail.email_address
          ? this.primaryContactDetail.email_address
          : ""
        : "";
      this.thirdPartyDetail.push({
        id: this.thirdPartyId ? this.thirdPartyId : "",
        name,
        email,
        task_id: data.item.task_id,
        third_party_name: this.thirdPartyData.comp_name_eng
      });
      this.reSendQuestionnairePopUp = true;
    },
    reSendreSendQuestionnaireCall() {
      this.counter = 1;
      this.processing = true;
      this.reSendQuestionnaireApiCall();
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1;
        } else {
          this.clearInterval();
          nextTick(() => {
            this.resetReSendQuestionnairePopUp();
          });
        }
      }, 100);
    },

    // call resend ddq API
    async reSendQuestionnaireApiCall() {
      let formData = {
        thirdParties: this.thirdPartyDetail,
        companyId: this.companyId,
        memberId: this.userId
      };
      try {
        this.isLoadingData = true;
        let response = await HTTP(
          "post",
          "thirdparty/resend/ddq",
          formData,
          makeAuthorizationHeader()
        );
        if (response.data.success == 1) {
          this.showSuccesSendDdqToster();
        }
        this.isLoadingData = false;
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    showSuccesSendDdqToster() {
      let name = this.getPrimaryContactName();
      this.$bvToast.toast(`Questionnaire to ${name} reassigned successfully.`, {
        title: "Questionnaire",
        variant: "success",
        autoHideDelay: 2000,
        appendToast: false
      });
      this.reloadTable();
    },
    resetReSendQuestionnairePopUp() {
      this.questionnaireName = null;
      this.questionnaireStatus = null;
      this.thirdPartyDetail = [];
      this.reSendQuestionnairePopUp = this.processing = false;
    },
    getPrimaryContactName() {
      let firstName = this?.primaryContactDetail?.firstname || "";
      let lastName = this?.primaryContactDetail?.lastname || "";

      return `${firstName} ${lastName}`;
    },
    setDetailData(detailData) {
      let name = detailData.topic.replace(/_/g, " ");
      return name;
    },
    checkDetailColor(detailData) {
      let risk = detailData?.data?.risk?.toLowerCase();
      if (risk == "high") {
        return 1;
      } else if (risk == "low") {
        return 2;
      } else if (risk == "moderate") {
        return 3;
      } else {
        return 4;
      }
    },
    getRiskDataScore(data) {
      let formData = data.item.form_data;
      if (formData.risk_data) {
        let numberIndex = this.wordNumber.findIndex(
          (x) => x.value == formData.risk_data.rating
        );
        return `${this.wordNumber[numberIndex].text} (${formData.risk_data.rating})`;
      }
    },
    checkRiskScoreColor(data) {
      let formData = data.item.form_data;
      if (formData.risk_data) {
        let risk = formData?.risk_data?.risk?.toLowerCase();
        if (risk == "high") {
          return 1;
        } else if (risk == "low") {
          return 2;
        } else if (risk == "moderate") {
          return 3;
        }
      }
    },
    dateFormat(data) {
      moment.locale("en");
      return moment.utc(data).local().format("LLLL");
    },
    openUrl(data) {
      if (data.item.status == 3 || data.item.status == 4) {
        let urlAddress = `/ddq-onboarding/${data.item.task_id}`;
        window.open(urlAddress);
      } else if (this.isUserSuperAdminOrAdmin && data.item.status != 0) {
        let urlAddress = `/user-form-page/${data.item.task_id}/${data.item.task_user_id}`;
        window.open(urlAddress);
      }
    },
    getTpQuestionnairesAndCommunications() {
      this.primaryContactDetail = {};
      this.items = [];
      let formData = {
        thirdPartyId: this.thirdPartyId,
        companyId: this.companyId,
        page: this.currentPage,
        per_page: this.perPage,
        onboardingId: this.thirdPartyData.onboardings[0].id
      };
      this.isLoadingData = true;
      let result = HTTP(
        "post",
        "thirdparty/questionnairescommunications",
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          if (
            response.data.primary_contact &&
            response.data.status == 1 &&
            response.status == 200
          ) {
            this.primaryContactDetail = response.data.primary_contact;
          }
          if (
            response.data.response &&
            response.data.status == 1 &&
            response.status == 200
          ) {
            this.items = response.data.response.data;
            this.isLoadingData = false;
            this.$store.state.questionnairesPoliciesTrainingTabLazyLoad = false;
            this.totalRows = response.data.response.total;
          } else {
            this.items = [];
            this.isLoadingData = false;
            this.$store.state.questionnairesPoliciesTrainingTabLazyLoad = false;
          }
          return this.items;
        })
        .catch((error) => {
          this.isLoadingData = false;
          return [error];
        });
    },

    // call send reminder API
    async sendReminder(data) {
      let formData = {
        thirdPartyData: [{ id: this.thirdPartyId }],
        companyId: this.companyId,
        memberId: this.userId,
        tasks: [data.item.task_id]
      };
      try {
        this.isLoadingData = true;
        let response = await HTTP(
          "post",
          "thirdparty/send/ddq/reminder",
          formData,
          makeAuthorizationHeader()
        );
        if (response.data.success == 1) {
          this.$bvToast.toast("Reminder has been sent successfully", {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
        }
        this.isLoadingData = false;
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    getBadgeColor(color) {
      if (color == "Green") {
        return "low-risk-score-style";
      } else if (color == "Yellow") {
        return "modrate-risk-score-style";
      } else if (color == "Red") {
        return "high-risk-score-style";
      }
    }
  },
  props: {
    companyId: Number,
    isDisabled: Boolean,
    thirdPartyId: Number,
    thirdPartyData: Object
  }
};
</script>
